import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './masjid_list.scss';
import '../../scss/font.scss';
import '../../scss/animatable.scss';
import '../../scss/display.scss';
import '../../scss/margin.scss';
import '../../scss/padding.scss';
import '../../scss/size.scss';
import '../../scss/breakpoints.scss';
import '../../scss/buttons.scss';
import axios from "axios";
import Loader from "react-loader-spinner";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BASE_API_URL } from '../../context.js';
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import MasjidListView from "./masjidList_view";
import { LoginButtonComponent } from "../Static_Header_Component/static_header";
import Google from '../../assets/images/google.png';
import { Typography } from '@mui/material';
import { useCookies } from 'react-cookie';


function MyMasjidPage(props) {
    const { screenWidth, g_userId, setGUserId, g_userName, setGUserName, checkLogin, setGUserImageUrl, setGUID, setCheckLogin, g_userImageUrl, setOpenLoader, g_cookies, setGCookie  } = props;
    const [myMasjidList, setMyMasjidList] = useState([]);
    const [myFollowedMasajid, setMyFollowedMasajid] = useState([]);
    const [LoaderSpinner, setLoaderSpinner] = useState(false);
    const [isMasjidFound, setIsMasjidFound] = useState(false);
    const [responseMessage, setResponseMessage] = useState();
    const [openImageModal, setOpenImageModal] = useState();
    const [imageSrc, setImageSrc] = useState("");
    const [cookies] = useCookies(["isLogin"]);

    useEffect(() => {
        if (g_userId != null && g_userId != undefined) {
            setLoaderSpinner(true);
            getMasjidById(g_userId);
        }
        else {
            setMyMasjidList(null);
        }
    }, [g_userId]);

    useEffect(() => {
        if (checkLogin === false) {
            setMyMasjidList(null);
        }
    }, [checkLogin])

    const getMasjidById = (email) => {
        const body = {
            userId: email
        }
        axios.post(`${BASE_API_URL}/masjid/get_masajid_by_id`, body, { withCredentials: false })
            .then(res => {
                if (res.status === 200) {
                    localStorage.setItem("myMyasjidList", JSON.stringify(res.data));
                    setMyMasjidList(res.data.myMasjidsList);
                    setMyFollowedMasajid(res.data.myFollowedMasjidsList)
                    setLoaderSpinner(false);
                }
                if (res.status === 201) {
                    setResponseMessage(res.data.message);
                    setLoaderSpinner(false);
                    setIsMasjidFound(true);
                }
            });
    }

    return (
        <>
            <Container>
                <Row style={{marginTop : screenWidth > 600 ? '100px' : null}}>
                    <Col className="padding-top-20" >
                        {(openImageModal === true) ?
                            <div className="modal" style={{ padding: '0px 10px', zIndex: 2 }}>
                                <div className="padding-50">
                                    <FontAwesomeIcon onClick={() => { setOpenImageModal(false); }} icon={faTimes} width="20%" style={{ padding: '1%', fontSize: '28px', color: 'white', float: 'right' }} />
                                    <img src={imageSrc} width="100%" height="100%" />
                                </div>
                            </div> : ""
                        }
                        {
                            (LoaderSpinner === true) ?
                                <div className="horizontal-center padding-top-100">
                                    <div>
                                        <Loader type="Rings" color="#E1C794" height={80} width={80} />
                                        <p className="padding-top-30" style={{ color: "#E1C794" }}>Loading........</p>
                                    </div>
                                </div>
                                : ""
                        }
                        {(g_userId === null || g_userId === undefined) ?
                            <Row>
                                <Col className="horizontal-center padding-top-50">
                                    <LoginButtonComponent g_userId={g_userId} setGUserId={setGUserId} setGUID={setGUID} buttonText={g_userId != null && g_userId != undefined ? "UnFollow" : 'Sign in with Google'} buttonImage={Google}
                                        setGUserName={setGUserName} setCheckLogin={setCheckLogin} setGUserImageUrl={setGUserImageUrl} buttonClassName={g_userId != null && g_userId != undefined ? "masjidList-card-unfollow-button" : "signIn-button"} />
                                </Col>
                            </Row>
                            : null
                        }
                        <Row className="horizontal-center">
                            <Col lg={11} md={10} sm={12} xs={12}>
                                {(myMasjidList !== null && myMasjidList !== undefined && myMasjidList.length > 0) ?
                                    <div className="padding-bottom-30 fade-in-translate">
                                        <Typography variant="h1" sx={{ fontWeight: { md: '700', xs: '600' }, fontSize: { md: '30px', xs: '22px' } }} className="text-shadow-grey padding-bottom-10">My Masajid</Typography>
                                        {
                                            myMasjidList.map((masjid, index) => {
                                                return (
                                                    <div key={index}>
                                                        <div key={index}>
                                                            <MasjidListView setGCookie={setGCookie} setGMasjidData={props.setGMasjidData} masjidData={masjid} setOpenLoader={setOpenLoader} screenWidth={screenWidth} masjidName={masjid.masjidName} masjidAddress={masjid.City + ',' + ' ' + masjid.Country} profileImage={masjid.profileImage} imageSrc={masjid.imageSrc} userId={masjid.userId} masjidId={masjid.Id} g_userName={g_userName} g_userImageUrl={g_userImageUrl}
                                                                isMasjidAdded={masjid.isMasjidAdded} g_userId={g_userId} setCheckLogin={setCheckLogin} checkLogin={checkLogin} setGUID={setGUID} setGUserId={setGUserId} setGUserName={setGUserName} setGUserImageUrl={setGUserImageUrl} isMasjidFollow={masjid.isMasjidFollow} />
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div> : ""
                                }
                            </Col>
                        </Row>
                        <Row className="horizontal-center fade-in-translate margin-bottom-100">
                            <Col lg={11} md={10} sm={12} xs={12}>
                                {(myFollowedMasajid !== null && myFollowedMasajid !== undefined && myFollowedMasajid.length > 0 && g_userId != null) ?
                                    <div className="padding-bottom-100">
                                        <Typography variant="h1" sx={{ fontWeight: { md: '700', xs: '600' }, fontSize: { md: '30px', xs: '22px' } }} className="text-shadow-grey padding-bottom-10">Followed Masajid</Typography>
                                        {
                                            myFollowedMasajid.map((masjid, index) => {
                                                return (
                                                    <div key={index}>
                                                        <div key={index}>
                                                            <MasjidListView setGCookie={setGCookie} setGMasjidData={props.setGMasjidData} masjidData={masjid} setOpenLoader={setOpenLoader} screenWidth={screenWidth} masjidName={masjid.masjidName} masjidAddress={masjid.City + ',' + ' ' + masjid.Country} profileImage={masjid.profileImage} imageSrc={masjid.imageSrc} masjidId={masjid.Id} isMasjidFollow={masjid.isMasjidFollow} g_userName={g_userName}
                                                                g_userImageUrl={g_userImageUrl} isMasjidAdded={masjid.isMasjidAdded} g_userId={g_userId} setCheckLogin={setCheckLogin} checkLogin={checkLogin} setGUID={setGUID}
                                                                setGUserId={setGUserId} setGUserName={setGUserName} setGUserImageUrl={setGUserImageUrl} />
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div> :
                                    ((myFollowedMasajid.length <= 0 || myFollowedMasajid === null || myFollowedMasajid === undefined) && g_userId != null && LoaderSpinner === false) ?
                                        <div className="padding-bottom-100">
                                            <div><h5 className="text-center font-weight-500 font-size-25 padding-bottom-10 text-shadow-grey">You haven't followed any masjid.</h5></div>
                                        </div> : null
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
export default MyMasjidPage;