import React, { useState, useMemo, useEffect } from "react";
import '../../scss/font.scss';
import '../../scss/animatable.scss';
import '../../scss/display.scss';
import '../../scss/margin.scss';
import '../../scss/padding.scss';
import '../../scss/size.scss';
import '../../scss/breakpoints.scss';
import '../../scss/buttons.scss';
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import countryList from 'react-select-country-list'
import './create_account.scss';
import { useHistory } from "react-router";
import  {BASE_API_URL, BASE_API_URL_FOR_SESSION} from '../../context.js';

function CreateAccountPage(props) {
    const { g_userId, g_userName, g_uid, g_userImageUrl, setGUserId, setGUserName, screenWidth } = props;
    const history = useHistory();
    const options = useMemo(() => countryList().getData(), []);
    const [createAccount, setCreateAccount] = useState({
        id: "",
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        country: "",
        city: "",
        zipCode: ""
    });
    const [confirmPassword] = useState();
    const [createButton, setCreateButton] = useState(true);
    const [statusCode, setStatusCode] = useState();
    const [emailError, setEmailError] = useState();
    const [isIphone, setIsIphone] = useState(false);

    useEffect(() => {
        if(g_userId !== null && g_userId != undefined && g_userName !== null && g_userName !== undefined){
            const email = g_userId;
            if(email.includes('@')){
                setCreateAccount((preValue) => {
                    return {
                        ...preValue,
                        id: email,
                        email: email,
                        firstName: g_userName
                    }
                })
                setIsIphone(true)
            }
            else{
                setCreateAccount((preValue) => {
                    return {
                        ...preValue,
                        id: email,
                    }
                })
            }
        }
        else{
            CheckSession()
        }
        },[])

    const CheckSession = () => {
        axios.post(`${BASE_API_URL_FOR_SESSION}/checkSession`)
        .then(response => {
            if (response.data.userId !== null && response.data.userId !== undefined) {
                setGUserId(response.data.userId);
                setGUserName(response.data.userName);
                const email = response.data.userId;
                const name = response.data.userName;
                if(email.includes('@')){
                    setCreateAccount((preValue) => {
                        return {
                            ...preValue,
                            id: email,
                            email: email,
                            firstName: name
                        }
                    })
                    setIsIphone(true)
                }
                else{
                    setCreateAccount((preValue) => {
                        return {
                            ...preValue,
                            id: email,
                        }
                    })
                }
            }
            else{
                history.push('/')
            }
        })
    }

    const ConfirmPassword = (event) => {
        const value = event.target.value;
        if ((createAccount.password !== null || createAccount.password !== undefined || confirmPassword !== null || confirmPassword !== undefined) && createAccount) {
            if (value === createAccount.password) {
                setCreateButton(false);
            }
            else {
                setCreateButton(true)
            }
        }
    }

    const InputEvent = (event) => {
        const { name, value } = event.target;
        setCreateAccount((preValue) => {
            return {
                ...preValue,
                [name]: value,
            }
        });
    }

    const CreateAccountButton = () => {
        const body = {
            id : createAccount.id,
            email: createAccount.email,
            firstName: createAccount.firstName,
            lastName: createAccount.lastName,
            profilePicture: g_userImageUrl
        }
        axios.post(`${BASE_API_URL}/account/create`, body, {withCredentials : false})
        .then(res => {
                setStatusCode(res.status);
                setEmailError(res.data.email);
                if (res.status === 200) {
                    history.push('/addmymasjid');
                }
            });
    }

    return (
        <>
            <div style={{ backgroundColor: '#FAE2B3' }}>
                <h1 className="text-center font-weight-700 text-shadow-grey padding-20" style={{marginBottom:"0rem", fontSize : screenWidth > 600 ? '35px' : '22px'}}>User Information </h1>
            </div>
            <div style={{padding: '3% 4%'}}>
            <Container style={{backgroundColor:'white', borderRadius: "10px"}}>
                <Row>
                    <Col>
                    <input type="text" className="eventList-input-feild mt-3" hidden name="id" value={createAccount.id} onChange={InputEvent} />
                        <input type="text" className="eventList-input-feild mt-3" placeholder="First Name" name="firstName" value={createAccount.firstName} onChange={InputEvent} />
                        <input type="text" className="eventList-input-feild mt-3" placeholder="Last Name" name="lastName" value={createAccount.lastName} onChange={InputEvent} />
                        <input type="text" className="eventList-input-feild mt-3" placeholder="Email" name="email" value={createAccount.email}
                        readOnly={isIphone === true ? true : false} disabled={isIphone === true ? true : false} onChange={InputEvent} />
                        {
                            (emailError !== undefined && emailError !== null && statusCode === 201) ?

                                <label style={{ color: 'red', fontSize: '12px' }}>Account with this email already exist</label>
                                : null
                        }
                        <input type="password" className="eventList-input-feild mt-3" placeholder="Password" name="password" value={createAccount.password} onChange={InputEvent} />
                        <input type="password" className="eventList-input-feild mt-3" placeholder="Confirm Password" name="confirmPassword" value={confirmPassword} onChange={ConfirmPassword} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="">
                            <select className="eventList-input-feild mt-3" name="country" onChange={InputEvent}>
                                <option value={createAccount.country} >Country (Optional)</option>
                                {
                                    options.map((val, index) => {
                                        return (
                                            <option key={index} >{val.label}</option>
                                        );
                                    })
                                }
                            </select>
                            <input type="text" className="eventList-input-feild mt-3" placeholder="City (Optional)" name="city" value={createAccount.city} onChange={InputEvent} />
                            <input type="number" className="eventList-input-feild mt-3" placeholder="ZipCode (Optional)" name="zipCode" value={createAccount.zipCode} onChange={InputEvent} />
                        </div>
                        <div className="horizontal-center">
                            <button className="mt-3 btn background-color-green mb-5 font-color-white font-weight-600" type="button" disabled={createButton} onClick={CreateAccountButton} >Save Information</button>
                        </div>
                    </Col>
                </Row>
            </Container>
            </div>
        </>
    );
}
export default CreateAccountPage;