import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './masjid_list.scss';
import '../../scss/font.scss';
import '../../scss/animatable.scss';
import '../../scss/display.scss';
import '../../scss/margin.scss';
import '../../scss/padding.scss';
import '../../scss/size.scss';
import '../../scss/breakpoints.scss';
import '../../scss/buttons.scss';
import '../../scss/color.scss'
import axios from "axios";
import Loader from "react-loader-spinner";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'
import { BASE_API_URL } from '../../context.js';
import { useHistory } from 'react-router-dom';
import Radio from '@mui/material/Radio';

function NearByEvent(props) {
    const history = useHistory();
    const { latitude, longitude, screenWidth, setShowMobileBottomBar } = props;
    const [eventList, setEventList] = useState([]);
    const [isEventExist, setIsEventExist] = useState(false);
    const [loader, setloader] = useState(true);
    const [loadingMoreEvent, setLoadingMoreEvent] = useState(false);
    const [responseMessage, setResponseMessage] = useState("");
    const [responseStatus, setResponseStatus] = useState("");
    const [eventName, setEventName] = useState("");
    const [copyEventList, setCopyEventList] = useState([]);
    const [isCopyEventList, setIsCopyEventList] = useState(false);
    const [isClearFeild, setIsClearFeild] = useState(true);
    const [startEventFrom, setStartEventFrom] = useState(0);
    const [noOfEventsToShow, setNoOfEventsToShow] = useState(4);
    const [totalEvents, setTotalEvent] = useState(0);
    const [isSearchingEnable, setIsSearching] = useState(false);
    const [isUpdateForce, setIsUpdateForce] = useState(true);
    const [searchBy, setSearchBy] = useState('masjidName');
    const [masjidCheckBox, setMasjidCheckBox] = useState(true);
    const [eventCheckBox, setEventCheckBox] = useState(false);
    var eventListAfterSearch = [];

    useEffect(() => {
        if (latitude !== null && latitude !== undefined && longitude !== null && longitude !== undefined) {
            GetNearByEvents();
        }
    }, []);

    useEffect(() => {
        if (startEventFrom > 0) {
            LoadMoreEvents();
        }
    }, [isUpdateForce])

    useEffect(() => {
        if (isCopyEventList === true) {
            SearchEventName()
        }
    }, [isCopyEventList])

    useEffect(() => {
        setEventName("");
    }, [isClearFeild])

    const GetNearByEvents = () => {
        const body = {
            currentLatitude: latitude,
            currentLongitude: longitude,
            startEventFrom: startEventFrom,
            noOfEventsToShow: noOfEventsToShow,
            totalEvents: totalEvents
        }
        axios.post(`${BASE_API_URL}/event/get_events_near_by`, body, { withCredentials: false })
            .then(response => {
                if (response.status === 200) {
                    if (response.data.eventList != null && response.data.eventList != undefined) {
                        var eventTemp = response.data.eventList
                        setEventList(eventTemp)
                        setStartEventFrom(response.data.start);
                        setTotalEvent(response.data.total);
                        setloader(false);
                        setIsEventExist(true);
                    }
                    else {
                        setResponseStatus(response.status);
                        setIsEventExist(true);
                        setStartEventFrom(response.data.start);
                        setTotalEvent(response.data.total);
                        setloader(false);
                        LoadMoreEvents();
                    }
                }
                else if (response.status === 201) {
                    setResponseMessage(response.data.message);
                    setResponseStatus(response.status);
                    setIsEventExist(false);
                    setloader(false)
                }
                else if (response.status === 202) {
                    setStartEventFrom(response.data.start);
                    setTotalEvent(response.data.total);
                    setResponseStatus(response.status);
                    setIsEventExist(true);
                    setloader(false)
                }
                else if (response.status === 203) {
                    setResponseMessage(response.data.message);
                    setResponseStatus(response.status);
                    setIsEventExist(false);
                    setloader(false)
                }
            });
    }

    const LoadMoreEvents = () => {
        setLoadingMoreEvent(true);
        const body = {
            currentLatitude: latitude,
            currentLongitude: longitude,
            startEventFrom: startEventFrom + noOfEventsToShow,
            noOfEventsToShow: noOfEventsToShow,
            totalEvents: totalEvents
        }
        axios.post(`${BASE_API_URL}/event/get_events_near_by`, body, { withCredentials: false })
            .then(response => {
                if (response.status === 200) {
                    if (response.data.eventList !== undefined && response.data.eventList !== null) {
                        var eventtemp = response.data.eventList
                        setEventList(eventList => [...eventList, ...eventtemp]);
                        setStartEventFrom(response.data.start);
                        setTotalEvent(response.data.total);
                        setLoadingMoreEvent(false);
                    }
                    else {
                        setStartEventFrom(response.data.start);
                        setIsUpdateForce(!isUpdateForce)
                    }
                }
                else if (response.status === 201) {
                    setResponseMessage(response.data.message);
                    setResponseStatus(response.status);
                    setLoadingMoreEvent(false);
                    setIsEventExist(false);
                    setloader(false)
                }
                else if (response.status === 202) {
                    setStartEventFrom(response.data.start);
                    setTotalEvent(response.data.total);
                    setResponseStatus(response.status);
                    setLoadingMoreEvent(false);
                    setIsEventExist(true);
                    setloader(false)
                }
                else if (response.status === 203) {
                    setResponseMessage(response.data.message);
                    setResponseStatus(response.status);
                    setLoadingMoreEvent(false);
                    setIsEventExist(false);
                    setloader(false)
                }
            });
    }

    const GetSearchedName = (event) => {
        // const search_text = event.target.value;
        // setEventName(search_text);
    }

    const SearchEventName = (event) => {
        const searchText = event.target.value;
        setEventName(searchText);
        if (searchText.length > 2) {
            if (copyEventList === null || copyEventList === undefined || copyEventList.length <= 0) {
                setCopyEventList([...eventList])
            }
            if (searchText !== null && searchText !== undefined && searchText !== "") {
                let search_text = searchText.toLowerCase();
                copyEventList.forEach((events, index) => {
                    if (events.eventName !== undefined && events.eventName !== null) {
                        const eventName_lowerCase = events.eventName.toLowerCase();
                        if (eventName_lowerCase.includes(search_text)) {
                            eventListAfterSearch.push(events);
                        }
                    }
                    if (events.masjidName !== undefined && events.masjidName !== null) {
                        const masjidName_lowerCase = events.masjidName.toLowerCase();
                        if (masjidName_lowerCase.includes(search_text)) {
                            eventListAfterSearch.push(events);
                        }
                    }
                })
            }
            if (eventListAfterSearch !== null && eventListAfterSearch !== undefined && eventListAfterSearch.length > 0) {
                setEventList([...eventListAfterSearch]);
                setIsSearching(true);
                if (isEventExist === false && loader === false) {
                    setIsEventExist(true)
                }
            }
            // else {
            //     setResponseMessage("No Event Matches")
            //     setIsSearching(true);
            //     setIsEventExist(false);
            //     setloader(false);
            // }
        }
    }

    const ClearFilter = () => {
        setIsClearFeild(!isClearFeild)
        setIsSearching(false);
        if (copyEventList.length > 0) {
            setIsEventExist(true);
            setloader(false);
            setEventList([...copyEventList]);
        }
    }

    const keyPress = (event) => {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
            SearchEvents();
        }
    }

    const KeyDown = (event) => {
        if (event.code === "Backspace" && copyEventList.length > 0) {
            setEventList([...copyEventList]);
        }
        if (event.code === "Backspace" && (eventName === null || eventName === undefined || eventName === "")) {
            setIsClearFeild(!isClearFeild)
            setIsSearching(false);
            if (copyEventList.length > 0) {
                setIsEventExist(true);
                setloader(false);
                setEventList([...copyEventList]);
            }
        }
        if (event.keyCode === 13) {
            event.preventDefault();
            event.target.blur();
            SearchEvents();
        }
    }

    const SearchEvents = () => {
        setloader(true);
        setIsSearching(true);
        const body = {
            eventName: eventName,
            searchBy: searchBy
        }
        axios.post(`${BASE_API_URL}/event/search_event`, body, { withCredentials: false })
            .then((response) => {
                if (response.data.length > 0) {
                    setEventList(response.data);
                    setloader(false);
                    setIsSearching(true);
                    setIsEventExist(true);
                }
                else {
                    setResponseMessage("No Event Matches")
                    setIsSearching(true);
                    setIsEventExist(false);
                    setloader(false);
                }
            })
    }

    const SearchByMasjidOrEventName = (event) => {
        const name = event.target.name
        if (name === 'masjidName') {
            setEventCheckBox(false);
            setMasjidCheckBox(true);
            setSearchBy('masjidName');
        }
        else {
            setEventCheckBox(true);
            setMasjidCheckBox(false);
            setSearchBy('eventName');
        }
    }

    const InputFeildFocus = () => {
        setShowMobileBottomBar(false);
    }

    const InputFeildBlur = () => {
        setTimeout(() => {
            setShowMobileBottomBar(true);
        }, 500);
    }

    return (
        <>
            <Container className="margin-top-100">
                <Row className="horizontal-center" style={{ marginTop: screenWidth > 600 ? '100px' : null }}>
                    <Col lg={11} md={10} sm={12} xs={12}>
                        <div className="padding-top-20 padding-bottom-20 vertical-center">
                            <input className="form-control-input-with-icon" type="text" placeholder="Search Event.." onKeyDown={KeyDown} onKeyPress={keyPress} onFocus={InputFeildFocus} onBlur={InputFeildBlur} value={eventName} onChange={SearchEventName} />
                            <button className="search-input-clear-button">
                                <FontAwesomeIcon className={screenWidth > 600 ? "clear-fontawesome" : "clear-fontawesome-for-mobile"} color={eventName.length > 0 ? "black" : "white"} icon={faTimes} width="20%" onClick={ClearFilter} />
                            </button>
                            <button className="search-input-search-button">
                                <FontAwesomeIcon className={screenWidth > 600 ? "search-fontawesome" : "search-fontawesome-for-mobile"} icon={faSearch} width="20%" onClick={SearchEvents} />
                            </button>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col lg={12} md={12} sm={12} xs={12} className="horizontal-center">
                        <div className="m-2 horizontal-center">
                        <Radio sx={{paddingTop : "0px", paddingBottom : "0px", color : "#bb9b5c", '&.Mui-checked': { color: "#bb9b5c" }}} className =" pl-3 pointer" name="masjidName" onChange={SearchByMasjidOrEventName} checked={masjidCheckBox}  />
                            <label className ={screenWidth > 600 ? "font-size-16" : "font-size-14"} style={{marginLeft: screenWidth > 600 ?  "0px" : "" }}>Filter by Masjid</label>
                        </div>
                        <div className="m-2 horizontal-center">
                        <Radio sx={{paddingTop : "0px", paddingBottom : "0px", color : "#bb9b5c", '&.Mui-checked': { color: "#bb9b5c" }}}  className =" pointer" name="eventName" onChange={SearchByMasjidOrEventName}  checked={eventCheckBox}  />

                            <label className ={screenWidth > 600 ? "font-size-16" : "font-size-14"} style={{marginLeft: screenWidth > 600 ?  "0px" : "" }} >Filter by Event</label>
                        </div>
                    </Col>
                </Row>
                <Row className="horizontal-center mt-3">
                    <Col lg={11} md={10} sm={12} xs={12}>
                        {
                            (isEventExist === true && loader === false) ?
                                eventList.map((event, index) => {
                                    return (
                                        <div className="nearby-event-List-card fade-in-translate" key={index}>
                                            {(screenWidth < 768) ?
                                                <Row className="pointer" onClick={() => { history.push(`/event/${event.masjidId}`) }}>
                                                    <Col lg={6} md={6} sm={12} xs={12} className="padding-top-8 padding-left-26">
                                                        <h6 className="font-weight-600 font-size-15 mb-0 font-color-dark-black text-center pb-0">{event.masjidName}</h6>
                                                    </Col>
                                                    <Col lg={6} md={6} sm={12} xs={12} className="padding-top-8 padding-left-26">
                                                        <h6 className="font-color-green text-center mb-0 font-size-12">{event.eventName}</h6>
                                                    </Col>
                                                    <Col lg={5} md={5} sm={12} xs={12} className="">
                                                        <div className="padding-top-8 horizontal-center">
                                                            <p className="font-size-10 font-color-grey text-center ">Start - {event.startDateTime}</p>
                                                            <p className="font-size-16 font-color-grey text-center padding-right-5 padding-left-5" style={{ marginTop: '-6px' }} >|</p>
                                                            <p className="font-size-10 font-color-grey text-center ">End - {event.endDateTime}</p>
                                                        </div>
                                                    </Col>
                                                </Row> :
                                                <Row className="pointer" onClick={() => { history.push(`/event/${event.masjidId}`) }}>
                                                    <Col lg={7} md={6} sm={12} xs={12}>
                                                        <Col lg={12} md={12} sm={12} xs={12} className="padding-top-8 padding-left-26">
                                                            <h6 className="font-weight-600 font-size-17 mb-0 font-color-dark-black text-start pb-0">{event.masjidName}</h6>
                                                        </Col>
                                                        <Col lg={12} md={12} sm={12} xs={12} className="padding-top-4 padding-left-26">
                                                            <h6 className="font-color-green text-start mb-0 font-size-15">{event.eventName}</h6>
                                                        </Col>
                                                    </Col>
                                                    <Col lg={5} md={6} sm={12} xs={12} className=" horizontal-end padding-top-10 padding-right-40">
                                                        <div className="">
                                                            <p className="font-size-14 font-color-grey mb-0 text-end ">Start - {event.startDateTime}</p>
                                                            <p className="font-size-14 font-color-grey text-end ">End - {event.endDateTime}</p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            }
                                        </div>
                                    )
                                })
                                :
                                (isEventExist === false && loader === false) ?
                                    <div className="horizontal-center padding-top-50 padding-bottom-50">
                                        <div>
                                            <p className="padding-top-30 font-color-green">{responseMessage}</p>
                                        </div>
                                    </div>
                                    :
                                    <div className="horizontal-center padding-top-50 padding-bottom-50">
                                        <div>
                                            <Loader type="Rings" color="#9a8356" height={80} width={80} />
                                            <p className="padding-top-30" style={{ color: "#9a8356" }}>Loading........</p>
                                        </div>
                                    </div>
                        }
                    </Col>
                </Row>
                {(startEventFrom < totalEvents && isSearchingEnable === false && loadingMoreEvent === false) ?
                    <Row className="padding-top-30 padding-bottom-100">
                        <Col>
                            <div className="horizontal-center">
                                <button className="btn font-color-white background-color-green border-9 padding-right-20 padding-left-20" onClick={LoadMoreEvents}>Load More....</button>
                            </div>
                        </Col>
                    </Row> : ""
                }
                {(responseStatus === 202 && isSearchingEnable === false && loadingMoreEvent === false) ?
                    <Row>
                        <Col>
                            <div className="horizontal-center padding-top-10 padding-bottom-100">
                                <div>
                                    <p className="padding-top-30 font-color-green">No Event Found</p>
                                </div>
                            </div>
                        </Col>
                    </Row> : ""
                }
                {(loadingMoreEvent === true) ?
                    <div className="horizontal-center padding-top-20 padding-bottom-80">
                        <div>
                            <Loader type="Rings" color="#9a8356" height={80} width={80} />
                            <p className="padding-top-20" style={{ color: '#9a8356' }}>Loading........</p>
                        </div>
                    </div> : ""
                }
            </Container>
        </>
    );
}
export default NearByEvent;