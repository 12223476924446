
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../scss/font.scss';
import '../../scss/animatable.scss';
import '../../scss/display.scss';
import '../../scss/margin.scss';
import '../../scss/padding.scss';
import '../../scss/size.scss';
import '../../scss/breakpoints.scss';
import '../../scss/buttons.scss';
import { Container, Row, Col } from 'react-bootstrap';


function HomePage(props) {

  const { windwoHeight } = props;
  return(
      <>
          <div style={{padding: '3% 2%', minHeight: windwoHeight}}>
          <Container style={{backgroundColor:'white', borderRadius: "10px"}}>
          <Row>
            <Col>
              <h4 className="text-center padding-15">Home Page !</h4>
            </Col>
            </Row>
        </Container>
      </div>
      </>
  )
}

export default HomePage;