import React, { useEffect, useState } from "react";
import '../../scss/font.scss';
import '../../scss/animatable.scss';
import '../../scss/display.scss';
import '../../scss/margin.scss';
import '../../scss/padding.scss';
import '../../scss/size.scss';
import '../../scss/breakpoints.scss';
import '../../scss/buttons.scss';
import { Row, Col } from "react-bootstrap";
import './masjid_list.scss';
import { BASE_API_URL, BASE_URL_FOR_IMAGE, LoginFireBase } from '../../context.js';
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import MasjidAvatar from '../../assets/images/_thumbnailmasjid-avatar-mobile.jpg';
import AlertDialog from "../Dialog_Component/dialog";
import { LoginButtonComponent } from "../Static_Header_Component/static_header";
import LoginDialog from "../Log_In_Component/login_in";
import { Typography } from "@mui/material";

function MasjidListView(props) {
    const { onClickAddMasjid, setGMasjidData, masjidData, checkLogin, screenWidth, setMasjidIdForFollow, setOpenLoader, setGCookie } = props;
    const history = useHistory();
    const [openImageModal, setOpenImageModal] = useState(false);
    const [imageSrcForModal, setImageSrcForModal] = useState("");
    const [isMasjidFollow, setIsMajidFollow] = useState(props.isMasjidFollow);
    const [imgSrc, setImgSrc] = useState(props.profileImage ? `${BASE_URL_FOR_IMAGE}/tbn_${props.profileImage}` : props.imageSrc ? props.imageSrc : MasjidAvatar);
    const [openDialog, setOpenDialog] = useState(false);
    const [loginDialog, setLoginDialog] = useState(false);

    useEffect(() => {
        if(props.masjidDataForFollow !== null && props.masjidDataForFollow !== undefined){
            FollowMasjidAfterSignIn(props.masjidDataForFollow);
        }
    },[])
 
    const OpenImageModal = (masjid) => {
        if (props.profileImage !== "" && props.profileImage !== null && props.profileImage !== undefined) {
            setImageSrcForModal(`${BASE_URL_FOR_IMAGE}/${props.profileImage}`);
        }
        else if (props.imageSrc !== "" && props.imageSrc !== null && props.imageSrc !== undefined) {
            setImageSrcForModal(props.imageSrc);
        }
        else if (props.profileImage !== "" || props.profileImage !== null || props.profileImage !== undefined && props.imageSrc !== "" || props.imageSrc !== null || props.imageSrc !== undefined) {
            setImageSrcForModal('https://maps.gstatic.com/tactile/pane/result-no-thumbnail-1x.png');
        }
    }

    const ViewOrAddMasjid = () => {
        if (props.isMasjidAdded === true) {
            setGMasjidData(masjidData);
            localStorage.setItem("masjidId", props.masjidId);
            let masjidId = props.masjidId;
            history.push(`/about/${masjidId}`)
        } else {
            localStorage.setItem("State", 1);
            localStorage.setItem("MasjidNameFromMasjidList", props.masjidName);
            localStorage.setItem("LatitudeFromMasjidList", props.latitude);
            localStorage.setItem("LongitudeFromMasjidList", props.longitude);
            localStorage.setItem("ImageSrcFromMasjidList", props.imageSrc);
            history.push('/addmymasjid');
        }
    }

    const Login = async (props) => {
        setLoginDialog(true);
        setMasjidIdForFollow(props.masjidId);
    }

    const FollowOrUnFollowMasjid = async (props) => {
        setOpenLoader(true);
        if(checkLogin === true){
            if (isMasjidFollow === true) {
                var body = {
                    masjidId: props.masjidId,
                    userId: props.g_userId
                }
                axios.post(`${BASE_API_URL}/account/unfollow_masjid`, body, { withCredentials: false })
                    .then((response) => {
                        if (response.status === 200) {
                            setIsMajidFollow(false);
                            setGCookie("isMasjidAdded", true);
                        }
                    })
            }
            else {
                var body = [{
                    masjidName: props.masjidName,
                    masjidImage: props.profileImage != "" ? props.profileImage : props.imageSrc != "" ? props.imageSrc : "https://maps.gstatic.com/tactile/pane/result-no-thumbnail-1x.png",
                    masjidId: props.masjidId
                },
                {
                    userId: props.g_userId,
                    userName: props.g_userName,
                }]
                axios.post(`${BASE_API_URL}/account/follow_masjid`, body, { withCredentials: false })
                    .then((response) => {
                        if (response.status === 200) {
                            setIsMajidFollow(true);
                            setGCookie("isMasjidAdded", true);
                        }
                    })
            }
        }
        setOpenLoader(false); 
    }

    const FollowMasjidAfterSignIn = async (masjidData) => {
        if(checkLogin === true){
            if (masjidData.isMasjidFollow === false) {
                var body = [{
                    masjidName: masjidData.masjidName,
                    masjidImage: masjidData.profileImage != "" ? masjidData.profileImage : masjidData.imageSrc != "" ? masjidData.imageSrc : "https://maps.gstatic.com/tactile/pane/result-no-thumbnail-1x.png",
                    masjidId: masjidData.id
                },
                {
                    userId: props.g_userId,
                    userName: props.g_userName,
                }]
                axios.post(`${BASE_API_URL}/account/follow_masjid`, body, { withCredentials: false })
                    .then((response) => {
                        if (response.status === 200) {
                            setIsMajidFollow(true);
                            setGCookie("isMasjidAdded", true);
                        }
                    })
            }
        }
        setOpenLoader(false);  
    }
    return (
        <>
            <LoginDialog loginDialog={loginDialog} setLoginDialog={setLoginDialog} g_userId={props.g_userId} screenWidth={screenWidth} />
            <AlertDialog open={openDialog} setOpen={setOpenDialog} title="Oops!" bodyText="Something Went Wrong. Sign-in Popup has been Blocked. Please Check Your Browser Setting." buttonText="Close" />
            {(openImageModal === true) ?
                <div className="modal" style={{ zIndex: 4 }}>
                    <div>
                        <div className="horizontal-end">
                            <FontAwesomeIcon onClick={() => { setOpenImageModal(false); }} icon={faTimes} width="20px" style={{ padding: '1%', fontSize: '50px', color: 'white', }} />
                        </div>
                        <img src={imageSrcForModal} width="100%" height="" />
                    </div>
                </div> : null
            }
            {(screenWidth > 600) ?
                <Row className="masjidList-card">
                    <Col xl={7} lg={7} md={6} sm={6}  className="vertical-center padding-0" >
                        <div className="padding-top-5 padding-bottom-5">
                            <img id="openImage" src={imgSrc} onError={() => { setImgSrc(MasjidAvatar) }} alt="...." width="48" height="53" style={{ borderRadius: '14px', cursor: 'pointer', objectFit: 'cover' }} onClick={OpenImageModal} />
                        </div>
                        <div className="padding-left-20">
                            <h5 className={screenWidth > 960 ? "font-size-20 mb-1 pointer" : "font-size-15 mb-1 pointer"} onClick={ViewOrAddMasjid}  >{props.masjidName}</h5>
                            <p className="font-size-12 mb-1" style={{ color: '#838d99' }}>{props.masjidAddress}</p>
                        </div>
                    </Col>
                    <Col md={1} sm={1}/>
                    <Col xl={4} lg={4} md={5} sm={5}  className="vertical-center horizontal-end padding-0">
                        <button type="button" className={(props.isMasjidAdded) ? "masjidList-card-view-button" : "masjidList-card-add-button"} onClick={ViewOrAddMasjid}>{(props.userId === props.g_userId && props.g_userId != undefined && props.g_userId != null) ? 'Modify Masjid' : props.isMasjidAdded === true ? 'View Masjid' : 'Add Masjid'}</button>
                        {
                            (props.isMasjidAdded === true) ?
                                <button type="button" className={(isMasjidFollow && checkLogin ) ? "masjidList-card-follow-button" : "masjidList-card-unfollow-button"} onClick={() => { (checkLogin === true ? FollowOrUnFollowMasjid(props) : Login(props)) }}>{(isMasjidFollow === true && checkLogin === true) ? 'Unfollow' : 'Follow'}</button>
                                : null
                        }
                    </Col>
                </Row>
                :
                <Row className="masjidList-card-for-mobile">
                    <Col  sm={8} xs={8} className="vertical-center padding-0" >
                        <div className="padding-top-5 padding-bottom-5">
                            <img id="openImage" src={imgSrc} onError={() => { setImgSrc(MasjidAvatar) }} alt="...." width={screenWidth > 300 ? "48" : "45"} height={screenWidth > 300 ? "53" : "50"} style={{ borderRadius: '14px', cursor: 'pointer', objectFit: 'cover' }} onClick={OpenImageModal} />
                        </div>
                        <div className="padding-left-8">
                            <h5 className={screenWidth > 300 ? "font-size-15 mb-1 pointer" : "font-size-12 mb-1 pointer" } onClick={ViewOrAddMasjid} >{props.masjidName}</h5>
                            <p className={screenWidth > 300 ?  "font-size-12 mb-1" : "font-size-10 mb-1"} style={{ color: '#838d99' }}>{props.masjidAddress}</p>
                        </div>
                    </Col>
                    <Col sm={4} xs={4} className="vertical-center horizontal-end padding-0" style={{ display: 'grid' }}>
                        <button type="button" className={(props.isMasjidAdded) ? "masjidList-card-view-button-for-mobile" : "masjidList-card-add-button-for-mobile"} onClick={ViewOrAddMasjid}>{(props.userId === props.g_userId && props.g_userId != undefined && props.g_userId != null) ? 'Modify Masjid' : props.isMasjidAdded === true ? 'View Masjid' : 'Add Masjid'}</button>
                        {
                            (props.isMasjidAdded === true) ?
                                <button type="button" className={(isMasjidFollow) ? "masjidList-card-follow-button-for-mobile" : "masjidList-card-unfollow-button-for-mobile"} onClick={() => { (checkLogin === true ? FollowOrUnFollowMasjid(props) : Login(props)) }}>{(isMasjidFollow === true) ? 'Unfollow' : 'Follow'}</button>
                                : null
                        }
                    </Col>
                </Row>
            }
        </>
    );
}

export default MasjidListView;