import React, { useEffect, useState } from "react";
import data from "../dataFile";
import '../../scss/font.scss';
import '../../scss/animatable.scss';
import '../../scss/display.scss';
import '../../scss/margin.scss';
import '../../scss/padding.scss';
import '../../scss/size.scss';
import '../../scss/breakpoints.scss';
import '../../scss/buttons.scss';
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import { BASE_API_URL } from '../../context.js';
import { useParams } from "react-router-dom";
import Loader from "react-loader-spinner";
import DummyMan from '../../assets/images/avatar-man.png'
import { Typography } from "@mui/material";


function MembersPage(props) {
  const param = useParams();
  const { g_userId, screenWidth, setGUserImageUrl, g_userImageUrl, setGUserId, g_userName, setGUserName, setCheckLogin, checkLogin, g_masjidData } = props;
  const [followerList, setFollowerList] = useState([]);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    if (param.masjidId != null && param.masjidId != undefined) {
      getFollowerList();
    }
  }, [])

  const getFollowerList = () => {
    const body = {
      masjidId: param.masjidId
    }

    axios.post(`${BASE_API_URL}/account/get_masjid_followers`, body, { withCredentials: false })
      .then((response) => {
        if (response.status === 200) {
          var listValue = [];
          if(response.data.follower ){
            Object.keys(response.data.follower).sort().forEach(function(v, i) {
              console.log(v)
              listValue.push(response.data.follower[v])
           });
            if(listValue.length > 0){
              setFollowerList(listValue);
              setLoader(false);  
            }
            else{
              setLoader(false);
            }
          }
          else{
            setLoader(false);
          }
        }
      })
  }

  return (
    <>
        <Container>
          {(screenWidth > 600 && g_masjidData !== null && g_masjidData !== undefined) ?
            <Row>
              <Col lg={3} md={1} sm={1} xs={1} ></Col>
              <Col className="horizontal-center" lg={6} md={10} sm={10} xs={10}>
                <div>
                <Typography variant="h1"
                    sx={{ fontSize: { xs: "24px", md: "28", lg: "30px", fontWeight: "700" } }}
                    className="mt-4 text-center  text-shadow-grey font-color-black" >
                    {g_masjidData.masjidName}
                  </Typography>
                  {/* <h6 className="mt-4 text-center font-weight-700 font-size-35 text-shadow-grey font-color-black" >{g_masjidData.masjidName}</h6> */}
                  <p className="m-0 text-center font-weight-400 font-size-16 font-color-grey">{g_masjidData.City}, {g_masjidData.Country}</p>
                  <p className="text-center font-weight-500 font-size-14 font-color-dark-black mb-0">Contact No: {g_masjidData.Contact}</p>
                </div>
              </Col>
              <Col lg={3} md={1} sm={1} xs={1} ></Col>
            </Row> : null
          }
          <Row className="mt-4">
            <Col>
              { (followerList != null && followerList != undefined && followerList.length > 0 && loader === false) ?
                followerList.map((follower, index) => {
                  return (
                    <div key={index}>
                      <ViewMembers userName={follower.userName} userImage={follower.userImage} screenWidth={screenWidth} />
                    </div>
                  )
                }) :
                (loader === true) ? 
                <div className="horizontal-center padding-top-80 padding-bottom-80">
                <div>
                  <Loader type="Rings" color="#9a8356" height={80} width={80} />
                  <p className="padding-top-20" style={{ color: "#9a8356" }}>Loading....</p>
                </div>                
                </div> : 
                <div>
                  <h6 className="mt-4 text-center font-weight-700 font-size-25 text-shadow-grey font-color-black" >No Follower Found</h6>
                </div>
              }
            </Col>
          </Row>
        </Container>
    </>
  );
}

export default MembersPage;


function ViewMembers(props) {

  const [windowWidth, setWindowWidth] = useState();
  const [mobileWidth, setMobileWidth] = useState(true);
  const [imgSrc, setImgSrc] = useState(props.userImage);
  useEffect(() => {
    const width = window.innerWidth;
    setWindowWidth(width);
    if (windowWidth > 600) {
      setMobileWidth(false);
    }
  }, [])
  return (
    <>
      <div className="horizontal-between">
        <div className="flex m-1 mb-0">
          <img src={imgSrc ? imgSrc : DummyMan} onError={() => {setImgSrc(DummyMan);}} width={props.screenWidth < 600 ? "35px" : "45px"} style={{ borderRadius: '50%' }} />
          <h1 className={props.screenWidth < 600 ? "padding-left-5 vertical-end  font-size-14" : "padding-left-15 vertical-end  font-size-18"}>{props.userName}</h1>
        </div>

        <div className="vertical-end">
          <button type="button" className={props.screenWidth < 600 ? "btn font-size-12 masjidList-card-unfollow-button-for-mobile" : "btn font-size-14 masjidList-card-unfollow-button"} disabled style={{ color: 'lightgoldenrodyellow' }} >Follower</button>
        </div>
      </div>
      <hr style={{ color: 'darkgreen' }} />
    </>
  );
}