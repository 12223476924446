import React, { useState, useEffect } from 'react';
import BackDropComponent from './BackDrop_Component/backdrop';

export default function ScreenSize(props) {
const { setScreenWidth, setIsUpdate, isUpdate } = props;
  const [openLoader, setOpenLoader] = useState(false);
  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  })

  const detectSize = (e) => {
    setScreenWidth(window.innerWidth);
    setIsUpdate(!isUpdate);
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    })
  }

  const detectOreintation = (e) => {
    setOpenLoader(true);
    setScreenWidth(window.innerWidth);
    setIsUpdate(!isUpdate);
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    })
    setTimeout(() => {
      setOpenLoader(false);
    }, 1500);
  }


  useEffect(() => {
      if (navigator.userAgent.match('CriOS')) {
        window.addEventListener('orientationchange', detectOreintation)
        return () => {
          window.removeEventListener('orientationchange', detectOreintation)
        }
      }
      
  }, [windowDimenion])

  useEffect(() => {
    if (!navigator.userAgent.match('CriOS')) {
      window.addEventListener('resize', detectSize)
      return () => {
        window.removeEventListener('resize', detectSize)
      }
    }
  },[windowDimenion])

  return (
    <div className=''>
      {/* <p>Width : <strong>{windowDimenion.winWidth}</strong></p>
      <p>Height: <strong>{windowDimenion.winHeight}</strong></p> */}
      <BackDropComponent open={openLoader} />
    </div>
  )
}