import React, { useEffect, useState } from "react";
import { Container, Col, Row, Alert } from "react-bootstrap";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './event.css'
import './event_page.scss'
import '../../scss/font.scss';
import '../../scss/animatable.scss';
import '../../scss/display.scss';
import '../../scss/margin.scss';
import '../../scss/padding.scss';
import '../../scss/size.scss';
import '../../scss/breakpoints.scss';
import '../../scss/buttons.scss';
import { useHistory, useParams } from "react-router";
import axios from "axios";
import EventDetails from "./event_details_tab";
import CreateEvent from "./create_event_tab";
import { BASE_API_URL } from '../../context.js';
import LZString from 'lz-string'
import { Typography } from "@mui/material";

function EventsPage(props) {

  const { screenWidth, setScreenWidth, g_userId, g_masjidData, checkLogin, g_userData } = props;
  const params = useParams();
  const history = useHistory();
  const [masjidData, setMasjidData] = useState();
  const [isUserOrAdmin, setIsUSerOrAdmin] = useState();
  const [eventTab, setEventTab] = useState(0);
  const [userData, setUserData] = useState();
  const [eventId, setEventId] = useState();
  const [eventDetail, setEventDetail] = useState();
  let masjidId = params.masjidId;

  useEffect(() => {
    if (g_masjidData !== null && g_masjidData !== undefined) {
      if (checkLogin === true) {
        if (g_masjidData.userId === g_userId) {
          setIsUSerOrAdmin(true);
        }
        else {
          setIsUSerOrAdmin(false);
        }
      }
      else {
        setIsUSerOrAdmin(false);
      }
    }
    else {
      setIsUSerOrAdmin(false);
    }
  }, [g_masjidData, checkLogin]);

  useEffect(() => {
    if (g_masjidData !== null && g_masjidData !== undefined) {
      setMasjidData(g_masjidData);
      // GetUserById(g_masjidData.userId)
    }
  }, [g_masjidData])

  const GetUserById = (userId) => {
    const body = {
      emailId: userId
    };
    axios.post(`${BASE_API_URL}/account/get_user_by_id`, body, { withCredentials: false })
      .then(response => {
        if (response.data !== null && response.data !== undefined) {
          setUserData(response.data);
        }
      })
  }

  const UpdateTab = () => {
    if (eventTab === 0) {
      setEventTab(1);
    }
    else {
      setEventTab(0);
      setEventId(null);
      setEventDetail(null);
    }
  }

  return (
    <>
      <div>
        <Container>
          {(screenWidth > 600 && masjidData !== null && masjidData !== undefined) ?
            <Row>
              <Col lg={3} md={1} sm={1} xs={1} ></Col>
              <Col className="horizontal-center" lg={6} md={10} sm={10} xs={10}>
                <div>
                  <Typography variant="h1"
                    sx={{ fontSize: { xs: "24px", md: "28", lg: "30px", fontWeight: "700" } }}
                    className="mt-4 text-center  text-shadow-grey font-color-black" >
                    {masjidData.masjidName}
                  </Typography>
                  <p className="m-0 text-center font-weight-400 font-size-16 font-color-grey">{masjidData.City}, {masjidData.Country}</p>
                  <p className="text-center font-weight-500 font-size-14 font-color-dark-black mb-0">Contact No: {masjidData.Contact}</p>
                </div>
              </Col>
              <Col lg={3} md={1} sm={1} xs={1} ></Col>
            </Row> : null
          }
          {
            (eventTab === 0)
              ?
              <div style={{ padding: "3% 3%", overflowY: 'hidden' }}>
                <CreateEvent onSelect={UpdateTab} masjidId={masjidId} g_userId={g_userId} g_userData={g_userData} g_masjidData={g_masjidData} setEventDetail={setEventDetail} setEventId={setEventId} isUserOrAdmin={isUserOrAdmin} screenWidth={screenWidth} />
              </div>
              :
              <div style={{ padding: "3% 3%", overflowY: 'hidden' }}>
                <EventDetails onSelect={UpdateTab} g_userId={g_userId} g_masjidData={g_masjidData} g_userData={g_userData} masjidId={masjidId} userData={userData} eventId={eventId} eventDetail={eventDetail} isUserOrAdmin={isUserOrAdmin} screenWidth={screenWidth} setScreenWidth={setScreenWidth} />
              </div>
          }
        </Container>
      </div>
    </>
  );
}
export default EventsPage;