import React, { useEffect, useState } from "react";
import { Container, Col, Row, Alert } from "react-bootstrap";
import '../../scss/font.scss';
import '../../scss/animatable.scss';
import '../../scss/display.scss';
import '../../scss/margin.scss';
import '../../scss/padding.scss';
import '../../scss/size.scss';
import '../../scss/breakpoints.scss';
import '../../scss/buttons.scss';
import './about_us.scss'
import axios from "axios";
import { useParams, useHistory } from 'react-router';
import Collapse from 'react-bootstrap/Collapse';
import Loader from "react-loader-spinner";
import { BASE_API_URL } from '../../context.js';
import EditImage from '../../assets/images/edit.png';
import ShareImage from '../../assets/images/Share40@3x.png'
import DummyMan from '../../assets/images/avatar-man.png'
import CrossImage from '../../assets/images/cross.png'
import {
  FacebookShareButton,
  WhatsappShareButton,
  EmailShareButton,
  LinkedinShareButton,
  TwitterShareButton
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon
} from "react-share";
import BackDropComponent from "../BackDrop_Component/backdrop";
import { Typography } from "@mui/material";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function AboutUsPage(props) {
  const { screenWidth, g_userId, g_masjidData, checkLogin, g_userData, g_userName, g_userImageUrl, updateUserData, setUpdateUserData, setGCookie } = props;
  const param = useParams();
  const history = useHistory();
  const [isUserOrAdmin, setIsUSerOrAdmin] = useState(false);
  const [masjidData, setMasjidData] = useState(g_masjidData);
  const [isMasjidDataExist, setIsMasjidDataExist] = useState(false);
  const [isPrayerTimeExist, setIsPrayerTimeExist] = useState(false);
  const [fajrPrayer, setFajrPrayer] = useState("");
  const [dhurPrayer, setDhurPrayer] = useState("");
  const [asrPrayer, setAsrPrayer] = useState("");
  const [magribPrayer, setMagribPrayer] = useState("");
  const [ishaPrayer, setIshaPrayer] = useState("");
  const [jummaPrayer, setJummaPrayer] = useState("");
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [openAlert, setOpenAlert] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [showShareButtons, setShowShareButtons] = useState(false);
  const [responseData, setResponseData] = useState();
  var [followerList, setFollowerList] = useState([]);
  const [isMasjidFollowed, setIsMasjidFollowed] = useState();
  const [prayerTimeUpdatedDate, setPrayerTimeUpdatedDate] = useState();
  const [openLoader, setOpenLoader] = useState(false);
  const [prayerTimeReadOnly, setPrayerTimeReadOnly] = useState({
    fajr: "",
    dhur: "",
    asr: "",
    magrib: "",
    isha: "",
    jumma: ""
  })
  let prayerTimeObject = {
    fajr: "",
    dhur: "",
    asr: "",
    magrib: "",
    isha: "",
    jumma: ""
  }
  const masjidId = param.masjidId;

  useEffect(() => {
    if (param.masjidId !== null && param.masjidId !== undefined) {
      getFollowerList();
    }
  }, []);

  useEffect(() => {
    if (g_userData !== null && g_userData !== undefined) {
      if (g_userData.favouriteMasjid) {
        let userIds = Object.keys(g_userData.favouriteMasjid);
        if (userIds.includes(param.masjidId)) {
          setIsMasjidFollowed(true);
        }
        else {
          setIsMasjidFollowed(false);
        }
      }
    }
  }, [g_userData])

  useEffect(() => {
    if (checkLogin === true) {
      if (g_masjidData !== null && g_masjidData !== undefined) {
        if (g_masjidData.userId === g_userId) {
          setIsUSerOrAdmin(true);
        }
      }
    }
    else {
      setIsUSerOrAdmin(false);
    }
  }, [checkLogin, g_masjidData]);

  useEffect(() => {
    if (g_masjidData !== null && g_masjidData !== undefined) {
      setMasjidData(g_masjidData);
      if (checkLogin === true) {
        if (g_masjidData.userId === g_userId) {
          setIsUSerOrAdmin(true);
        }
      }
      else {
        setIsUSerOrAdmin(false);
      }
      getMasjidPrayerTimes();
    }
    else {
      setIsMasjidDataExist(!isMasjidDataExist);
    }
  }, [g_masjidData]);


  const getFollowerList = () => {
    const body = {
      masjidId: param.masjidId
    }

    axios.post(`${BASE_API_URL}/account/get_masjid_followers`, body, { withCredentials: false })
      .then((response) => {
        if (response.data.follower !== undefined && response.data.follower !== null) {
          var listValue = Object.values(response.data.follower);
          setFollowerList(listValue);
        }
      })
  }

  const getMasjidPrayerTimes = () => {
    if (masjidId !== null && masjidId !== undefined) {
      const body = {
        masjidId: masjidId
      }
      axios.post(`${BASE_API_URL}/prayer/get_prayer_times_by_id`, body, { withCredentials: false })
        .then(response => {
          if (response.status === 200) {
            const updateTime = response.data.UpdateTime._seconds;
            let updateDate = new Date(updateTime * 1000).toDateString();
            setPrayerTimeUpdatedDate(updateDate);
            setResponseData(response.data);
            SetResponseDataToFeild(response.data);
          }
        })
    }
    else {
      setIsPrayerTimeExist(!isPrayerTimeExist);
    }
  }

  // convert time object to time string for time picker
  const ConvertTimeObjectIntoTimeString = (TimeObject) => {
    let time = "";
    let hour = "";
    let minute = "";
    if (TimeObject !== null && TimeObject !== undefined) {
      if (TimeObject.amOrPm === "PM") {
        hour = TimeObject.hour + 12;
        minute = TimeObject.minute
      }
      else {
        hour = TimeObject.hour;
        minute = TimeObject.minute;
      }
    }
    if (hour < 10) {
      hour = '0' + hour;
    }
    if (minute < 10) {
      minute = '0' + minute;
    }
    time = hour + ':' + minute
    return time;
  }

  const CreateReadOnlyTimeString = (TimeObject) => {
    let hours = TimeObject.hour;
    let minutes = TimeObject.minute;
    if (TimeObject.hour < 10) {
      hours = '0' + TimeObject.hour;
    }
    if (TimeObject.minute < 10) {
      minutes = '0' + TimeObject.minute;
    }
    let timeString = hours + ':' + minutes + ' ' + TimeObject.amOrPm;
    return timeString;
  }

  const SetResponseDataToFeild = (responseData) => {
    let prayerTimeString = "";
    if (responseData.fajrTime !== null && responseData.fajrTime !== undefined && responseData.fajrTime !== "") {
      prayerTimeString = ConvertTimeObjectIntoTimeString(responseData.fajrTime);
      setFajrPrayer(prayerTimeString);
      const fajrReadOnly = CreateReadOnlyTimeString(responseData.fajrTime)
      setPrayerTimeReadOnly((preValue) => {
        return {
          ...preValue,
          fajr: fajrReadOnly
        }
      });
    }
    if (responseData.dhurTime !== null && responseData.dhurTime !== undefined && responseData.dhurTime !== "") {
      prayerTimeString = ConvertTimeObjectIntoTimeString(responseData.dhurTime);
      setDhurPrayer(prayerTimeString);
      const dhurReadOnly = CreateReadOnlyTimeString(responseData.dhurTime)
      setPrayerTimeReadOnly((preValue) => {
        return {
          ...preValue,
          dhur: dhurReadOnly
        }
      });
    }
    if (responseData.asrTime !== null && responseData.asrTime !== undefined && responseData.asrTime !== "") {
      prayerTimeString = ConvertTimeObjectIntoTimeString(responseData.asrTime);
      setAsrPrayer(prayerTimeString);
      const asrReadOnly = CreateReadOnlyTimeString(responseData.asrTime)
      setPrayerTimeReadOnly((preValue) => {
        return {
          ...preValue,
          asr: asrReadOnly
        }
      });
    }
    if (responseData.magribTime !== null && responseData.magribTime !== undefined && responseData.magribTime !== "") {
      prayerTimeString = ConvertTimeObjectIntoTimeString(responseData.magribTime);
      setMagribPrayer(prayerTimeString);
      const magribReadOnly = CreateReadOnlyTimeString(responseData.magribTime)
      prayerTimeObject.magrib = magribReadOnly;
      setPrayerTimeReadOnly((preValue) => {
        return {
          ...preValue,
          magrib: magribReadOnly
        }
      });
    }
    if (responseData.ishaTime !== null && responseData.ishaTime !== undefined && responseData.ishaTime !== "") {
      prayerTimeString = ConvertTimeObjectIntoTimeString(responseData.ishaTime);
      setIshaPrayer(prayerTimeString);
      const ishaReadOnly = CreateReadOnlyTimeString(responseData.ishaTime)
      setPrayerTimeReadOnly((preValue) => {
        return {
          ...preValue,
          isha: ishaReadOnly
        }
      });
    }
    if (responseData.jummaTime !== null && responseData.jummaTime !== undefined && responseData.jummaTime !== "") {
      prayerTimeString = ConvertTimeObjectIntoTimeString(responseData.jummaTime);
      setJummaPrayer(prayerTimeString);
      const jummaReadOnly = CreateReadOnlyTimeString(responseData.jummaTime)
      setPrayerTimeReadOnly((preValue) => {
        return {
          ...preValue,
          jumma: jummaReadOnly
        }
      });
    }
  }

  const ConvertTimeStringIntoTimeObject = (time) => {
    var timeObject = {
      hour: "",
      minute: "",
      amOrPm: "",
      timeZone: ""
    };
    if (time !== null && time !== undefined && time !== "") {
      const convertIntoHourandMinute = time.split(":");
      if (convertIntoHourandMinute[0] > 12) {
        const hour = convertIntoHourandMinute[0] - 12;
        timeObject = {
          hour: hour,
          minute: Number(convertIntoHourandMinute[1]),
          amOrPm: 'PM',
          timeZone: ""
        }
        return timeObject;
      }
      else {
        timeObject = {
          hour: Number(convertIntoHourandMinute[0]),
          minute: Number(convertIntoHourandMinute[1]),
          amOrPm: 'AM',
          timeZone: ""
        }
        return timeObject;
      }
    }
  }

  const SavePrayerTimes = () => {
    setOpenLoader(true);
    let fajrTimeObject = "";
    let dhurTimeObject = "";
    let asrTimeObject = "";
    let magribTimeObject = "";
    let ishaTimeObject = "";
    let jummaTimeObject = "";
    const currentTimeZone = new Date().getTimezoneOffset();
    const convertTimeZoneToUTCZone = currentTimeZone / 60 * -1;
    if (fajrPrayer !== null && fajrPrayer !== undefined && fajrPrayer !== "") {
      fajrTimeObject = ConvertTimeStringIntoTimeObject(fajrPrayer);
      fajrTimeObject.timeZone = convertTimeZoneToUTCZone;
    }
    if (dhurPrayer !== null && dhurPrayer !== undefined && dhurPrayer !== "") {
      dhurTimeObject = ConvertTimeStringIntoTimeObject(dhurPrayer);
      dhurTimeObject.timeZone = convertTimeZoneToUTCZone;
    }
    if (asrPrayer !== null && asrPrayer !== undefined && asrPrayer !== "") {
      asrTimeObject = ConvertTimeStringIntoTimeObject(asrPrayer);
      asrTimeObject.timeZone = convertTimeZoneToUTCZone;
    }
    if (magribPrayer !== null && magribPrayer !== undefined && magribPrayer !== "") {
      magribTimeObject = ConvertTimeStringIntoTimeObject(magribPrayer);
      magribTimeObject.timeZone = convertTimeZoneToUTCZone;
    }
    if (ishaPrayer !== null && ishaPrayer !== undefined && ishaPrayer !== "") {
      ishaTimeObject = ConvertTimeStringIntoTimeObject(ishaPrayer);
      ishaTimeObject.timeZone = convertTimeZoneToUTCZone;
    }
    if (jummaPrayer !== null && jummaPrayer !== undefined && jummaPrayer !== "") {
      jummaTimeObject = ConvertTimeStringIntoTimeObject(jummaPrayer);
      jummaTimeObject.timeZone = convertTimeZoneToUTCZone;
    }
    const body = {
      id: masjidId,
      fajrTime: fajrTimeObject,
      dhurTime: dhurTimeObject,
      asrTime: asrTimeObject,
      magribTime: magribTimeObject,
      ishaTime: ishaTimeObject,
      jummaTime: jummaTimeObject,
      userId: g_userId
    }
    axios.post(`${BASE_API_URL}/prayer/set_prayer_times`, body, { withCredentials: false })
      .then(res => {
        setResponseStatus(res.status);
        setResponseMessage(res.data.message);
        if (res.status === 200) {
          setOpenAlert(true);
          getMasjidPrayerTimes();
        }
      })
    setTimeout(() => {
      setOpenAlert(false);
      setIsReadOnly(true);
      setOpenLoader(false);
    }, 3000);
  }

  const EditPrayerTiming = () => {
    setIsReadOnly(false);
  }

  const onChangeTime = (event, prayerName) => {
    if (prayerName === "Fajr") {
      setFajrPrayer(event.target.value);
    }
    else if (prayerName === "Dhur") {
      setDhurPrayer(event.target.value);
    }
    else if (prayerName === "Asr") {
      setAsrPrayer(event.target.value);
    }
    else if (prayerName === "Magrib") {
      setMagribPrayer(event.target.value);
    }
    else if (prayerName === "Isha") {
      setIshaPrayer(event.target.value);
    }
    else if (prayerName === "Jumma") {
      setJummaPrayer(event.target.value);
    }
  }

  const RemovePrayerTimeChanges = () => {
    setPrayerTimeReadOnly((preValue) => {
      return { ...preValue }
    });
    if (responseData !== null && responseData !== undefined) {
      if (responseData.fajrTime === "") {
        setFajrPrayer("");
      }
      if (responseData.dhurTime === "") {
        setDhurPrayer("");
      }
      if (responseData.asrTime === "") {
        setAsrPrayer("");
      }
      if (responseData.magribTime === "") {
        setMagribPrayer("");
      }
      if (responseData.ishaTime === "") {
        setIshaPrayer("");
      }
      if (responseData.jummaTime === "") {
        setJummaPrayer("");
      }
    }
    else {
      setFajrPrayer(prayerTimeReadOnly.fajr);
      setDhurPrayer(prayerTimeReadOnly.dhur);
      setAsrPrayer(prayerTimeReadOnly.asr);
      setMagribPrayer(prayerTimeReadOnly.magrib);
      setIshaPrayer(prayerTimeReadOnly.isha);
      setJummaPrayer(prayerTimeReadOnly.jumma);
    }
    setIsReadOnly(true);
  }

  const FollowOrUnFollowMasjid = () => {
    let body = {};
    if (isMasjidFollowed === true) {
      body = {
        masjidId: masjidId,
        userId: g_userId,
      }
      axios.post(`${BASE_API_URL}/account/unfollow_masjid`, body, { withCredentials: false })
        .then((response) => {
          if (response.status === 200) {
            setIsMasjidFollowed(false);
            setGCookie("isMasjidAdded", true);
            setTimeout(() => {
              setUpdateUserData(!updateUserData);
            }, 1000);
          }
        })
    }
    else {
      body = [
        {
          masjidName: g_masjidData.masjidName,
          masjidImage: g_masjidData.profileImage !== "" ? g_masjidData.profileImage : g_masjidData.imageSrc !== "" ? g_masjidData.imageSrc : "https://maps.gstatic.com/tactile/pane/result-no-thumbnail-1x.png",
          masjidId: masjidId
        },
        {
          userId: g_userId,
          userName: g_userName,
          userImage: g_userImageUrl,
        }
      ]
      axios.post(`${BASE_API_URL}/account/follow_masjid`, body, { withCredentials: false })
        .then((response) => {
          if (response.status === 200) {
            setIsMasjidFollowed(true);
            setGCookie("isMasjidAdded", true);
            setTimeout(() => {
              setUpdateUserData(!updateUserData);
            }, 1000);
          }
        })
    }
  }

  const ShareButtonCall = () => {
    const standalone = window.navigator.standalone;
    const userAgent = window.navigator.userAgent.toLowerCase();
    const safari = /safari/.test(userAgent);
    const ios = /iphone|ipod|ipad/.test(userAgent);
    const android = /android/i.test(userAgent);
    const androidWB = /wv/.test(userAgent);
    if (ios) {
      if (!standalone && safari) {
        // for ios browser
        navigator.share({
          url: window.location.href,
          text: "Kindly Follow Masjid"
        })
      } else if (standalone && !safari) {
        // for ios standalone
        navigator.share({
          url: window.location.href,
          text: "Kindly Follow Masjid"
        })
      } else if (!standalone && !safari) {
        // for ios webview
        window.location.href = `webview://?masjidId=${masjidId}`;
      }
    }
    else if (android) {
      if (androidWB) {
        // for android webview
        window.location.href = `webview://?masjidId=${masjidId}`;
      }
      else if (!androidWB) {
        // for android browser
        navigator.share({
          url: window.location.href,
          text: "Kindly Follow Masjid"
        })
      }
    }
    else {
      setShowShareButtons(!showShareButtons);
    }
  }

  return (
    <>
      {
        (masjidData !== null && masjidData !== undefined) ?
          <div>
            <BackDropComponent open={openLoader} />
            <Container>
              {(screenWidth > 600) ?
                <Row>
                  <Col lg={3} md={1} sm={1} xs={1} ></Col>
                  <Col className="horizontal-center" lg={6} md={10} sm={10} xs={10}>
                    <div>
                      <Typography variant="h1"
                        sx={{ fontSize: { xs: "24px", md: "28", lg: "30px", fontWeight: "700" } }}
                        className="mt-4 text-center  text-shadow-grey font-color-black" >
                        {masjidData.masjidName}
                      </Typography>
                      <p className="m-0 text-center font-weight-400 font-size-16 font-color-grey">{masjidData.City}, {masjidData.Country}</p>
                      <p className="text-center font-weight-500 font-size-14 font-color-dark-black mb-0">Contact No: {masjidData.Contact}</p>
                    </div>
                  </Col>
                  <Col lg={3} md={1} sm={1} xs={1} ></Col>
                </Row> : null}
              {(g_userId !== null && g_userId !== undefined && (isMasjidFollowed === true || isMasjidFollowed === false)) ?
                <Row>
                  <Col className="horizontal-center mt-2">
                    <button className={isMasjidFollowed === true ? "masjidList-card-follow-button" : "masjidList-card-unfollow-button"} onClick={FollowOrUnFollowMasjid}>{isMasjidFollowed === true ? "UnFollow" : "Follow"}</button>
                  </Col>
                </Row> : null
              }
              <Row>
                <Col lg={3} md={1}></Col>
                <Col lg={6} md={10} sm={12} xs={12}>
                  <div className="div-border padding-10 mt-3 mb-3 ">
                    <div className="padding-left-10">
                      <h1 className="font-weight-600 font-size-16 text-shadow-grey font-color-black">Followers</h1>
                    </div>
                    <div className="horizontal-between">
                      {(followerList !== null && followerList !== undefined && followerList.length > 0) ?

                        <div className="flex vertical-end">
                          {
                            followerList.map((member, index) => {
                              return (
                                <div key={index}>
                                  {(index < 4) ?

                                    <ViewMembers src={member.userImage} userName={member.userName} screenWidth={screenWidth} />

                                    : " "}
                                </div>
                              );
                            })
                          }
                          <div className="padding-left-5 m-1 mb-0">
                            <img src={DummyMan} width={screenWidth < 600 ? "30px" : "40px"} style={{ borderRadius: '50%' }} alt="No ImgaeFound" />
                            {/* <LazyLoadImage effect="blur" src={DummyMan} width={screenWidth < 600 ? "30px" : "40px"} style={{ borderRadius: '50%' }} alt="No ImgaeFound" /> */}
                            <h1 className={screenWidth < 600 ? "vertical-end  font-size-10 pointer mt-1" : "pointer vertical-end  font-size-14 mt-1"} onClick={() => { history.push(`/member/${masjidId}`) }}>See All</h1>
                          </div>
                        </div>
                        : null}
                      <div style={{ display: 'grid', alignItems: 'end' }}>
                        <div className="horizontal-center">
                          <img src={ShareImage} alt="Share" width="35" className="mb-1 pointer" onClick={ShareButtonCall} />
                        </div>
                        <h4 className={screenWidth > 600 ? "text-center font-size-14" : "text-center font-size-10"}>Invite User</h4>
                        <div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="padding-bottom-40">
                <Col lg={3} md={1}></Col>
                <Col lg={6} md={10} sm={12} xs={12}>
                  <div className={isReadOnly === true ? "div-border" : "div-border padding-right-10"}>
                    <Row>
                      <Col className="padding-bottom-20 padding-left-35 mt-3" lg={10} md={10} sm={10} xs={10} >
                        <h6 className="font-weight-600 font-size-16 text-shadow-grey font-color-black" >Prayer Jamaat Timing</h6>
                        {(prayerTimeUpdatedDate !== null && prayerTimeUpdatedDate !== undefined) ?
                          <h6 className="font-weight-600 font-size-14 font-color-black" >Last Updated - {prayerTimeUpdatedDate}</h6>
                          : null
                        }
                      </Col>
                      {(isUserOrAdmin === true) ?
                        <Col className="padding-bottom-20 mt-3 horizontal-end padding-right-40" lg={2} md={2} sm={2} xs={2} >
                          <img src={EditImage} alt="Edit" width="20" height="20" className="pointer" onClick={EditPrayerTiming} />
                        </Col> : ""}
                    </Row>
                    <hr className="opacity-30 mt-0" />
                    <Row className="vertical-center">
                      <Col className="padding-bottom-15 padding-left-35  mt-1" lg={6} md={6} sm={6} xs={6} >
                        <div>
                          <h6 className="mt-1 mb-1" >Fajr</h6>
                        </div>
                      </Col>
                      <Col className="padding-bottom-15  mt-1 horizontal-end" lg={6} md={6} sm={6} xs={6} >
                        {(fajrPrayer !== "" || isReadOnly === false) ?
                          <input type={isReadOnly === true ? "text" : "time"} className={isReadOnly === true ? "about-us-prayer-input mt-1 " : "about-us-prayer-input-enable mt-1"} name="Fajr" value={isReadOnly === true ? prayerTimeReadOnly.fajr : fajrPrayer} onChange={(event) => { onChangeTime(event, 'Fajr') }} readOnly={isReadOnly} />
                          :
                          <input type="text" value="Not Set" className="about-us-prayer-input mt-1" style={{}} readOnly />
                        }
                      </Col>
                    </Row>
                    <div className="horizontal-center">
                      <hr className="opacity-15 mt-0 width-50-p" />
                    </div>
                    <Row className="vertical-center" >
                      <Col className="padding-bottom-15 padding-left-35  mt-1" lg={6} md={6} sm={6} xs={6} >
                        <div>
                          <h6 className="mt-1 mb-1" >Dhur</h6>
                        </div>
                      </Col>
                      <Col className="padding-bottom-15  mt-1 horizontal-end" lg={6} md={6} sm={6} xs={6} >
                        {(dhurPrayer !== "" || isReadOnly === false) ?
                          <input type={isReadOnly === true ? "text" : "time"} className={isReadOnly === true ? "about-us-prayer-input mt-1 " : "about-us-prayer-input-enable mt-1"} name="Dhur" value={isReadOnly === true ? prayerTimeReadOnly.dhur : dhurPrayer} onChange={(event) => { onChangeTime(event, 'Dhur') }} readOnly={isReadOnly} />
                          :
                          <input type="text" value="Not Set" className="about-us-prayer-input mt-1" style={{}} readOnly />
                        }
                      </Col>
                    </Row>
                    <div className="horizontal-center">
                      <hr className="opacity-15 mt-0 width-50-p" />
                    </div>
                    <Row className="vertical-center" >
                      <Col className="padding-bottom-15 padding-left-35 mt-1" lg={6} md={6} sm={6} xs={6}>
                        <div>
                          <h6 className="mt-1 mb-1" >Asr</h6>
                        </div>
                      </Col>
                      <Col className="padding-bottom-15  mt-1 horizontal-end" lg={6} md={6} sm={6} xs={6}  >
                        {(asrPrayer !== "" || isReadOnly === false) ?
                          <input type={isReadOnly === true ? "text" : "time"} className={isReadOnly === true ? "about-us-prayer-input mt-1 " : "about-us-prayer-input-enable mt-1"} name="Asr" value={isReadOnly === true ? prayerTimeReadOnly.asr : asrPrayer} onChange={(event) => { onChangeTime(event, 'Asr') }} readOnly={isReadOnly} />
                          :
                          <input type="text" value="Not Set" className="about-us-prayer-input mt-1" style={{}} readOnly />
                        }
                      </Col>
                    </Row>
                    <div className="horizontal-center">
                      <hr className="opacity-15 mt-0 width-50-p" />
                    </div>
                    <Row className="vertical-center" >
                      <Col className="padding-bottom-15 padding-left-35 mt-1" lg={6} md={6} sm={6} xs={6}>
                        <div>
                          <h6 className="mt-1 mb-1" >Magrib</h6>
                        </div>
                      </Col>
                      <Col className="padding-bottom-15  mt-1 horizontal-end" lg={6} md={6} sm={6} xs={6}  >
                        {(magribPrayer !== "" || isReadOnly === false) ?
                          <input type={isReadOnly === true ? "text" : "time"} className={isReadOnly === true ? "about-us-prayer-input mt-1 " : "about-us-prayer-input-enable mt-1"} name="Magrib" value={isReadOnly === true ? prayerTimeReadOnly.magrib : magribPrayer} onChange={(event) => { onChangeTime(event, 'Magrib') }} readOnly={isReadOnly} />
                          :
                          <input type="text" value="Not Set" className="about-us-prayer-input mt-1" style={{}} readOnly />
                        }
                      </Col>
                    </Row>
                    <div className="horizontal-center">
                      <hr className="opacity-15 mt-0 width-50-p" />
                    </div>
                    <Row className="vertical-center" >
                      <Col className="padding-bottom-15 padding-left-35 mt-1" lg={6} md={6} sm={6} xs={6} >
                        <div>
                          <h6 className="mt-1 mb-1" >Isha</h6>
                        </div>
                      </Col>
                      <Col className="padding-bottom-15  mt-1 horizontal-end" lg={6} md={6} sm={6} xs={6}  >
                        {(ishaPrayer !== "" || isReadOnly === false) ?
                          <input type={isReadOnly === true ? "text" : "time"} className={isReadOnly === true ? "about-us-prayer-input mt-1 " : "about-us-prayer-input-enable mt-1"} name="Isha" value={isReadOnly === true ? prayerTimeReadOnly.isha : ishaPrayer} onChange={(event) => { onChangeTime(event, 'Isha') }} readOnly={isReadOnly} />
                          :
                          <input type="text" value="Not Set" className="about-us-prayer-input mt-1" style={{}} readOnly />
                        }
                      </Col>
                    </Row>
                    <div className="horizontal-center">
                      <hr className="opacity-15 mt-0 width-50-p" />
                    </div>
                    <Row className="vertical-center" >
                      <Col className="padding-bottom-15 padding-left-35 mt-1" lg={6} md={6} sm={6} xs={6}>
                        <div>
                          <h6 className="mt-1 mb-1" >Jumma</h6>
                        </div>
                      </Col>
                      <Col className="padding-bottom-15  mt-1 horizontal-end" lg={6} md={6} sm={6} xs={6}  >
                        {(jummaPrayer !== "" || isReadOnly === false) ?
                          <input type={isReadOnly === true ? "text" : "time"} className={isReadOnly === true ? "about-us-prayer-input mt-1 " : "about-us-prayer-input-enable mt-1"} name="Jumma" value={isReadOnly === true ? prayerTimeReadOnly.jumma : jummaPrayer} onChange={(event) => { onChangeTime(event, 'Jumma') }} readOnly={isReadOnly} />
                          :
                          <input type="text" value="Not Set" className="about-us-prayer-input mt-1" style={{}} readOnly />
                        }
                      </Col>
                    </Row>
                    <Row className="m-0">
                      <Col>
                        <Collapse in={openAlert}>
                          <div id="example-collapse-text" className="mt-3">
                            <Alert variant={responseStatus === 200 ? "success" : "danger"} onClose={() => setOpenAlert(false)} dismissible>
                              <h6 style={{ marginBottom: '0.1rem' }}>{responseMessage}</h6>
                            </Alert>
                          </div>
                        </Collapse>
                      </Col>
                    </Row>
                    {
                      (isReadOnly === false) ?
                        <Row className="padding-bottom-15">
                          <Col className="horizontal-between padding-right-40 padding-left-40">
                            <button className="btn btn-dark padding-right-10"
                              onClick={RemovePrayerTimeChanges}>Cancel</button>
                            <button className="btn background-color-green font-color-white" onClick={SavePrayerTimes}>Save</button>
                          </Col>
                        </Row>
                        : ""
                    }
                  </div>
                </Col>
                <Col lg={3} md={2}></Col>
              </Row>
              {(showShareButtons === true) ?
                <div className="modal">
                  <div className="modal-content">
                    <div className="horizontal-end">
                      <img className="div-border border-6 pointer" alt="Image Not Found" onClick={() => { setShowShareButtons(false) }} src={CrossImage} width="40" />
                    </div>
                    <div>
                      <h1 className="font-size-16 padding-10 text-center">Share Masjid With Your Freinds</h1>
                      <input className="eventList-input-feild mt-1 text-center" value={window.location.href} disabled readOnly />
                    </div>
                    <div className="horizontal-center mt-3 mb-3">
                      <FacebookShareButton className="m-1" url={window.location.href}>
                        <FacebookIcon className="about-us-icon-background" size={45} round={true} />
                      </FacebookShareButton>
                      <LinkedinShareButton className="m-1" url={window.location.href}>
                        <LinkedinIcon className="about-us-icon-background" size={45} round={true} />
                      </LinkedinShareButton>
                      <EmailShareButton className="m-1" url={window.location.href}>
                        <EmailIcon className="about-us-icon-background" size={45} round={true} />
                      </EmailShareButton>
                      <WhatsappShareButton className="m-1" url={window.location.href}>
                        <WhatsappIcon className="about-us-icon-background" size={45} round={true} />
                      </WhatsappShareButton>
                      <TwitterShareButton className="m-1" url={window.location.href}>
                        <TwitterIcon className="about-us-icon-background" size={45} round={true} />
                      </TwitterShareButton>
                    </div>
                  </div>
                </div> : null
              }
            </Container>
          </div>
          :
          <div style={{ padding: '3% 2%' }}>
            <Container className="padding-0" style={{ backgroundColor: "white", borderRadius: '10px' }}>
              <div className="horizontal-center padding-top-80 padding-bottom-80">
                <div>
                  <Loader type="Rings" color="#9a8356" height={80} width={80} />
                  <p className="padding-top-20" style={{ color: "#9a8356" }}>Loading....</p>
                </div>
              </div>
            </Container>
          </div>
      }
    </>
  );
}
export default AboutUsPage;

function ViewMembers(props) {

  const [imgSrc, setImgSrc] = useState(props.src);

  return (
    <>
      <div className="padding-left-5 m-1 mb-0" title={props.userName}>
        <img src={imgSrc ? imgSrc : DummyMan} onError={() => setImgSrc(DummyMan)} width={props.screenWidth < 600 ? "30px" : "40px"} style={{ borderRadius: '50%' }} alt="" />
        {/* <LazyLoadImage effect="blur" src={imgSrc ? imgSrc : DummyMan} onError={() => setImgSrc(DummyMan)} width={props.screenWidth < 600 ? "30px" : "40px"} style={{ borderRadius: '50%' }}  alt="No ImgaeFound" /> */}
        <h1 className={props.screenWidth < 600 ? "vertical-end font-size-10 space-no-wrap mt-1" : "mt-1 vertical-end font-size-14 space-no-wrap"} >{props.userName.substring(0, 7)}</h1>
      </div>
    </>
  );
}