import React, { useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import { GoogleMap, LoadScript, Marker, Circle  } from '@react-google-maps/api';
import MoveBackward from '../assets/images/backward.png';

const GoogleMapPage = (props) => {

  const { windwoHeight, screenWidth } = props;
  let params = useParams();
  let history = useHistory();
  const DefaultZoom = 17;
  const DefaultLocation = {
    lat: Number(params.masjidLatitude),
    lng: Number(params.masjidLongitude)
  };
  const [center, setCenter] = useState({
    lat: Number(params.masjidLatitude),
    lng: Number(params.masjidLongitude)
  })
  const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);
  const [location, setLocation] = useState(defaultLocation);
  const mapContainerStyle = {
    height: "400px",
    width: "100%",
    borderRadius: "8px",
  }

  const onDragEnd = marker => {
    setLocation({
      lat: marker.latLng.lat(),
      lng: marker.latLng.lng()
    });
    setCenter({
      lat: marker.latLng.lat(),
      lng: marker.latLng.lng()
    });
  }

  const SaveSelectLocation = () => {
    const latitude = location.lat;
    const longitude = location.lng;
    const masjidName = params.masjidName
    localStorage.setItem("MasjidNameFromGoogleMap", masjidName);
    localStorage.setItem("LatitudeFromGoogleMap", latitude);
    localStorage.setItem("LongitudeFromGoogleMap", longitude);
    localStorage.setItem("State", 2);
    history.push('/addmymasjid');
  }

  const options = {
    strokeColor: '#fae2b3',
    strokeOpacity: 1,
    strokeWeight: 3,
    fillColor: '#fae2b3',
    fillOpacity: 0.5,
    clickable: false,
    draggable: true,
    editable: true,
    visible: true,
    radius: 60,
    zIndex: 0
  }

  const onLoad = circle => {
    console.log('Circle onLoad circle: ', circle)
  }
  
  const onUnmount = circle => {
    console.log('Circle onUnmount circle: ', circle)
  }

  const onClickOnMap = events => {
    setLocation({
      lat: events.latLng.lat(),
      lng: events.latLng.lng()
    });
    setCenter({
      lat: events.latLng.lat(),
      lng: events.latLng.lng()
    });
  }

  return (
    <div style={{minHeight: windwoHeight}}>
      <Row className="m-0">
        <Col style={{ backgroundColor: '#FAE2B3' }}>
          <h1 className="text-center font-weight-700 text-shadow-grey padding-15" style={{ fontSize : screenWidth > 600 ? '30px' : '22px'}}><img className="pointer" src={MoveBackward} width="35" height="35" onClick={() => { history.push('/addmymasjid'); }} style={{ float: 'left' }} />{params.masjidName}</h1>
        </Col>
      </Row>
      <div style={{ padding: '2%' }}>
        <Container style={{ backgroundColor: 'white', borderRadius: '10px', padding: '3% 2%' }} >
          <div className="add-masjid-page-map">
            <LoadScript
              googleMapsApiKey="AIzaSyC7lPXaC3PCIuvaCGLeig-qM8XBoW_q9sc"
            >
              <GoogleMap
                id="marker-example"
                mapContainerStyle={mapContainerStyle}
                zoom={DefaultZoom}
                center={center}
                onClick={onClickOnMap}
              >
                <Marker
                  position={location}
                  draggable={true}
                  onDragEnd={onDragEnd}
                />
                <Circle
                onLoad={onLoad}
                onUnmount={onUnmount}
                center={center}
                options={options}
              />
              </GoogleMap>
            </LoadScript>
          </div>
          <div>
            <h6 className="mt-4">Latitute:</h6>
            <input className="eventList-input-feild" type='text' value={location.lat} disabled />
          </div>
          <div>
            <h6 className="mt-3">Longitute:</h6>
            <input className="eventList-input-feild" type='text' value={location.lng} disabled />
          </div>
          <div className="horizontal-center mt-4">
            <button className="btn btn-secondary m-2" style={{ width: '110px' }} onClick={() => { history.push('/addmymasjid'); }}>Back</button>
            <button className="btn background-color-green font-color-white m-2" onClick={SaveSelectLocation}>Save Location</button>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default GoogleMapPage;

