import React, { useEffect, useState } from "react";
import './event.css'
import './event_page.scss'
import '../../scss/font.scss';
import '../../scss/animatable.scss';
import '../../scss/display.scss';
import '../../scss/margin.scss';
import '../../scss/padding.scss';
import '../../scss/size.scss';
import '../../scss/breakpoints.scss';
import '../../scss/buttons.scss';
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import Loader from "react-loader-spinner";
import MoveForward from '../../assets/images/forward.png';
import { BASE_API_URL } from '../../context.js';
import AddButtonImage from '../../assets/images/add_button.png';


function CreateEvent(props) {
    const { onSelect, masjidId, setEventId, setEventDetail, isUserOrAdmin, screenWidth, g_userData } = props;
    const [windowWidth, setWindowWidth] = useState();
    const [mobileWidth, setMobileWidth] = useState(true);
    const [eventList, setEventList] = useState([]);
    const [isEventExist, setIsEventExist] = useState();
    const [loader, setloader] = useState(true);

    useEffect(() => {
        const width = window.innerWidth;
        setWindowWidth(width);
        if (width > 600) {
            setMobileWidth(false);
        }
        else {
            setMobileWidth(true);
        }
    }, [])

    useEffect(() => {
        if (masjidId !== null && masjidId !== undefined) {
            GetEventList();
        }
    }, [masjidId])

    const GetEventList = () => {
        const body = {
            masjidId: masjidId
        }
        axios.post(`${BASE_API_URL}/event/get_masjid_events`, body, {withCredentials : false})
            .then(response => {
                setTimeout(() => {
                    if (response.status === 200) {
                        setEventList(response.data);
                        setIsEventExist(true);
                        setloader(false)
                    }
                    if (response.status === 201) {
                        setIsEventExist(false);
                        setloader(false)
                    }
                }, [1500]);
            })
    }

    return (
        <>
            <Container style={{ cursor: 'pointer', backgroundColor: 'white', borderRadius: "5px" }} >
                {(isUserOrAdmin === true) ?
                    <Row>
                        <Col className="horizontal-end padding-right-40" lg={12} md={12} sm={12} xs={12}>
                            <img title="Add New Masjid" className={screenWidth > 600 ? "eventList-add-event-button" : "eventList-add-event-button-for-mobile"} src={AddButtonImage} width="60" height="60" onClick={onSelect} />
                        </Col>
                    </Row>

                    : ""}
                <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <div className="fade-in-translate padding-8">
                            <h6 className="font-size-22" style={{ marginBottom: '0rem' }}>Events</h6>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className="padding-bottom-50">
                        {
                            (loader === true) ?
                                <div className="horizontal-center padding-top-50 padding-bottom-50">
                                    <div>
                                        <Loader type="Rings" color="#9a8356" height={80} width={80} />
                                        <p className="padding-top-30" style={{ color: "#9a8356" }}>Loading........</p>
                                    </div>
                                </div> : ""
                        }

                        {
                            (isEventExist === true && loader === false) ?
                                eventList.map((event, index) => {
                                    return (
                                        <div className=" fade-in-translate" key={index} onClick={() => { setEventId(event.id); setEventDetail(event); onSelect(); }}>
                                            {(screenWidth <= 770) ?
                                                <Row className="nearby-event-List-card vertical-center">
                                                    <Col md={10} sm={11} xs={11}>
                                                        <Col md={12} sm={12} xs={12} className="padding-top-8 padding-left-26">
                                                            <h6 className="font-color-green text-center mb-0 font-size-12">{event.eventName}</h6>
                                                        </Col>
                                                        <Col md={12} sm={12} xs={12} className="">
                                                            <div className="padding-top-8 horizontal-center">
                                                                <p className="font-size-10 font-color-grey text-center ">Start - {event.startDateTime}</p>
                                                                <p className="font-size-16 font-color-grey text-center padding-right-5 padding-left-5" style={{ marginTop: '-6px' }} >|</p>
                                                                <p className="font-size-10 font-color-grey text-center ">End - {event.endDateTime}</p>
                                                            </div>
                                                        </Col>
                                                    </Col>
                                                    <Col className="horizontal-end" md={2} sm={1} xs={1}>
                                                        <div>
                                                            <img src={MoveForward} width="25" height="25" onClick={() => { setEventId(event.id); setEventDetail(event); onSelect(); }} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                :
                                                <Row className="nearby-event-List-card vertical-center">
                                                    <Col lg={11} md={11} className="horizontal-between vertical-center">
                                                        <Col lg={4} md={4}  className="padding-top-4 padding-left-26">
                                                            <h6 className="font-color-green text-start mb-0 font-size-15">{event.eventName}</h6>
                                                        </Col>
                                                        <Col lg={8} md={8} className="padding-top-8 horizontal-end" >
                                                            <div className="padding-top-8 horizontal-center">
                                                                <p className="font-size-15 font-color-grey text-center ">Start - {event.startDateTime}</p>
                                                                <p className="font-size-16 font-color-grey text-center padding-right-10 padding-left-10" style={{ marginTop: '-3px' }} >|</p>
                                                                <p className="font-size-15 font-color-grey text-center ">End - {event.endDateTime}</p>
                                                            </div>
                                                        </Col>
                                                    </Col>
                                                    <Col className="horizontal-end" lg={1} md={1} >
                                                        <div>
                                                            <img src={MoveForward} width="30" height="35" onClick={() => { setEventId(event.id); setEventDetail(event); onSelect(); }} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            }
                                        </div>
                                    )
                                })
                                : ""
                        }
                        {
                            (isEventExist === false && loader === false) ?
                                <div className="horizontal-center padding-top-50 padding-bottom-50">
                                    <div>
                                        <h6>No Event Created !</h6>
                                    </div>
                                </div> : ""
                        }
                    </Col>
                </Row>
            </Container>
        </>
    );
}
export default CreateEvent;