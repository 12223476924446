import React, { useState, useEffect } from 'react';
import './App.css';
import HomePage from './Components/Home_Component/home_page.js';
import EventsPage from './Components/Event_Component/event_page.js';
import AboutUsPage from './Components/About_Us_Component/about_us.js';
import MembersPage from './Components/Member_Component/member_page.js';
import { Route, Switch } from 'react-router-dom';
import MasjidLists from './Components/MasjidList_Component/masjid_list';
import AddMyMasjidPage from './Components/Add_My_Masjid_Component/add_my_masjid';
import GoogleMapPage from './Components/GoogleMap';
import Header from './Components/Header_Component/header';
import CreateAccountPage from './Components/Create_Account_Component/create_account';
import axios from 'axios';
import { BASE_API_URL_FOR_SESSION, CreateAccountForMasjid } from './context.js';
import AlertDialog from './Components/Dialog_Component/dialog';
import { useCookies } from 'react-cookie';


function App() {

  const [screenWidth, setScreenWidth] = useState();
  const [windwoHeight, setWindowHeight] = useState();
  const [callImageUseEffect, setCallImageUseEffect] = useState();
  const [g_userId, setGUserId] = useState();
  const [g_userName, setGUserName] = useState();
  const [g_uid, setGUID] = useState();
  const [g_userImageUrl, setGUserImageUrl] = useState();
  const [g_masjidData, setGMasjidData] = useState();
  const [g_userData, setGUserData] = useState();
  const [g_latitude, setGLatitude] = useState();
  const [g_longitude, setGLongitude] = useState();
  const [g_cookies, setGCookie] = useCookies();
  const [checkLogin, setCheckLogin] = useState();
  const [updateUserData, setUpdateUserData] = useState(true);
  const [isUpdate, setIsUpdate] = useState(true);
  const [callFunction, setCall] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(true);

  const props = {
    screenWidth: screenWidth,
    setScreenWidth: setScreenWidth,
    windwoHeight: windwoHeight,
    setWindowHeight: setWindowHeight,
    callImageUseEffect: callImageUseEffect,
    setCallImageUseEffect: setCallImageUseEffect,
    g_userId: g_userId,
    setGUserId: setGUserId,
    g_userName: g_userName,
    setGUserName: setGUserName,
    g_uid: g_uid,
    setGUID: setGUID,
    g_userData: g_userData,
    setGUserData: setGUserData,
    g_userImageUrl: g_userImageUrl,
    setGUserImageUrl: setGUserImageUrl,
    g_latitude: g_latitude,
    setGLatitude: setGLatitude,
    g_longitude: g_longitude,
    setGLongitude: setGLongitude,
    updateUserData: updateUserData,
    setUpdateUserData: setUpdateUserData,
    isUpdate: isUpdate,
    setIsUpdate: setIsUpdate,
    g_masjidData: g_masjidData,
    setGMasjidData: setGMasjidData,
    checkLogin: checkLogin,
    setCheckLogin: setCheckLogin,
    pageLoaded: pageLoaded,
    setPageLoaded: setPageLoaded,
    g_cookies: g_cookies,
    setGCookie: setGCookie
  }

  useEffect(() => {

  }, [isUpdate]);


  useEffect(() => {
    setTimeout(() => {
      let cookieString = document.cookie
      let cookieArray = cookieString.split('; ')
      for (let i = 0; i < cookieArray.length; i++) {
        if (cookieArray[i].includes('isLogin')) {
          let loginCookie = cookieArray[i].split("=");
          if (loginCookie[1] === "false" && (g_userId === null || g_userId === undefined)) {
            localStorage.removeItem("ListofAllMasjidByUserId");
            setCheckLogin(false);
            setGUserId(null);
            setGUID(null);
            setGUserName(null);
            setGUserImageUrl(null);
            setIsUpdate(!isUpdate);
            break;
          }
          else if (loginCookie[1] === "true" && (g_userId === undefined || g_userId === null)) {
            CheckSession();
            break;
          }
          else if (loginCookie[1] === "false" && g_userId !== undefined && g_userId !== null) {
            localStorage.removeItem("ListofAllMasjidByUserId");
            let domainName = localStorage.getItem("Domain");
            if(domainName === "Masjid"){
              localStorage.removeItem("Domain");
              break;
            }
            else{
              setOpenDialog(true);
              setGUserId(null);
              setGUID(null);
              setGUserName(null);
              setGUserImageUrl(null);
              setCheckLogin(false);
              setIsUpdate(!isUpdate);
              break;
            }
          }
        }
      }
      setCall(!callFunction)
    }, 5000);
  }, [callFunction])

  useEffect(() => {
    const width = window.innerWidth;
    let height = window.innerHeight;
    height = height - 390;
    setWindowHeight(height);
    setScreenWidth(width);
  }, [setScreenWidth]);

  const CheckSession = () => {
    if (g_userId === null || g_userId === undefined) {
      axios.post(`${BASE_API_URL_FOR_SESSION}/checkSession`)
        .then(response => {
          if (response.data.userId !== null && response.data.userId !== undefined) {
            setGUserId(response.data.userId);
            setGUserName(response.data.userName);
            setGUID(response.data.uid)
            setCheckLogin(true);
            setIsUpdate(!isUpdate)
            let name = response.data.userName;
            name = name.split(' ');
            const body = {
              id: response.data.userId,
              firstName: name[0] ? name[0] : "Anonymous",
              lastName: name[1] ? name[1] : "Anonymous",
              email: response.data.userId,
              uid: response.data.uid,
              profilePicture: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUh3k9PBWmPFALGyMwCNsaukw4H-y-B2LxHA&usqp=CAU"
            }
            CreateAccountForMasjid(body)
          }
        })
    }
  }

  return (
    <>
      <AlertDialog {...props} open={openDialog} setOpen={setOpenDialog} title="User Session Expired" bodyText="You have been logged out. Please login in again." buttonText="Login" />
      <Switch>
        <Route exact
          path="/masjidhome/:masjidId" >
          <Header screenWidth={screenWidth} setScreenWidth={setScreenWidth} setIsUpdate={setIsUpdate} isUpdate={isUpdate} g_userId={g_userId} g_userData={g_userData} g_userImageUrl={g_userImageUrl} setGUserImageUrl={setGUserImageUrl} setGUserData={setGUserData} setGUserId={setGUserId} g_userName={g_userName} setGUserName={setGUserName} g_masjidData={g_masjidData} setGMasjidData={setGMasjidData}
            checkLogin={checkLogin} setCheckLogin={setCheckLogin} />
          <HomePage windwoHeight={windwoHeight} />
        </Route>
        <Route exact
          path="/event/:masjidId">
          <Header screenWidth={screenWidth} setScreenWidth={setScreenWidth} setIsUpdate={setIsUpdate} isUpdate={isUpdate} g_userId={g_userId} g_userData={g_userData} g_userImageUrl={g_userImageUrl} setGUserImageUrl={setGUserImageUrl} setGUserData={setGUserData} setGUserId={setGUserId} g_userName={g_userName} setGUserName={setGUserName} g_masjidData={g_masjidData} setGMasjidData={setGMasjidData}
            checkLogin={checkLogin} setCheckLogin={setCheckLogin} />
          <EventsPage windwoHeight={windwoHeight} screenWidth={screenWidth} setScreenWidth={setScreenWidth} g_masjidData={g_masjidData} setGMasjidData={setGMasjidData} g_userData={g_userData}
            g_userId={g_userId} setGUserId={setGUserId} g_userName={g_userName} setGUserName={setGUserName} checkLogin={checkLogin} setCheckLogin={setCheckLogin} />
        </Route>
        <Route exact
          path="/about/:masjidId" >
          <Header {...props} setGCookie={setGCookie} g_cookies={g_cookies} screenWidth={screenWidth} setScreenWidth={setScreenWidth} setIsUpdate={setIsUpdate} isUpdate={isUpdate} updateUserData={updateUserData} setUpdateUserData={setUpdateUserData} g_userId={g_userId} g_userData={g_userData} g_userImageUrl={g_userImageUrl} setGUserImageUrl={setGUserImageUrl} setGUserData={setGUserData} setGUserId={setGUserId} g_userName={g_userName} setGUserName={setGUserName} g_masjidData={g_masjidData} setGMasjidData={setGMasjidData}
            checkLogin={checkLogin} setCheckLogin={setCheckLogin} />
          <AboutUsPage setGCookie={setGCookie} g_cookies={g_cookies} setIsUpdate={setIsUpdate} isUpdate={isUpdate} windwoHeight={windwoHeight} updateUserData={updateUserData} setUpdateUserData={setUpdateUserData} screenWidth={screenWidth} setScreenWidth={setScreenWidth} g_masjidData={g_masjidData} setGMasjidData={setGMasjidData} g_userImageUrl={g_userImageUrl}
            g_userId={g_userId} g_userData={g_userData} setGUserId={setGUserId} g_userName={g_userName} setGUserName={setGUserName} checkLogin={checkLogin} setCheckLogin={setCheckLogin} />
        </Route>
        <Route exact
          path="/member/:masjidId" >
          <Header screenWidth={screenWidth} setScreenWidth={setScreenWidth} setIsUpdate={setIsUpdate} isUpdate={isUpdate} updateUserData={updateUserData} setUpdateUserData={setUpdateUserData} g_userId={g_userId} g_userData={g_userData} g_userImageUrl={g_userImageUrl} setGUserImageUrl={setGUserImageUrl} setGUserData={setGUserData} setGUserId={setGUserId} g_userName={g_userName} setGUserName={setGUserName} g_masjidData={g_masjidData} setGMasjidData={setGMasjidData}
            checkLogin={checkLogin} setCheckLogin={setCheckLogin} />
          <MembersPage windwoHeight={windwoHeight} updateUserData={updateUserData} setUpdateUserData={setUpdateUserData} setCheckLogin={setCheckLogin} checkLogin={checkLogin}
            g_masjidData={g_masjidData} screenWidth={screenWidth} g_userId={g_userId} setGUserId={setGUserId} g_userName={g_userName} setGUserName={setGUserName} setGUserImageUrl={setGUserImageUrl} g_userImageUrl={g_userImageUrl} />
        </Route>
        <Route exact
          path="/">
          <MasjidLists {...props} setPageLoaded={setPageLoaded} pageLoaded={pageLoaded} setGCookie={setGCookie} g_cookies={g_cookies} setIsUpdate={setIsUpdate} isUpdate={isUpdate} screenWidth={screenWidth} setScreenWidth={setScreenWidth} windwoHeight={windwoHeight} setCallImageUseEffect={setCallImageUseEffect} callImageUseEffect={callImageUseEffect} setCheckLogin={setCheckLogin} checkLogin={checkLogin} g_uid={g_uid}
             g_latitude={g_latitude} setGLatitude={setGLatitude} g_longitude={g_longitude} setGLongitude={setGLongitude} g_userId={g_userId} setGUserId={setGUserId} g_userName={g_userName} setGUserName={setGUserName} setGUID={setGUID} setGUserImageUrl={setGUserImageUrl} g_userImageUrl={g_userImageUrl} />
        </Route>
        <Route exact
          path="/home/:user_email?/:user_name?/:uid?/:user_image?/:latitude?/:longitude?">
          <MasjidLists setPageLoaded={setPageLoaded} pageLoaded={pageLoaded} setGMasjidData={setGMasjidData} setGCookie={setGCookie} g_cookies={g_cookies} setIsUpdate={setIsUpdate} isUpdate={isUpdate} screenWidth={screenWidth} setScreenWidth={setScreenWidth} windwoHeight={windwoHeight} setCallImageUseEffect={setCallImageUseEffect} callImageUseEffect={callImageUseEffect} setCheckLogin={setCheckLogin} checkLogin={checkLogin} g_uid={g_uid}
             g_latitude={g_latitude} setGLatitude={setGLatitude} g_longitude={g_longitude} setGLongitude={setGLongitude} g_userId={g_userId} setGUserId={setGUserId} g_userName={g_userName} setGUserName={setGUserName} setGUID={setGUID} setGUserImageUrl={setGUserImageUrl} g_userImageUrl={g_userImageUrl} />
        </Route>
        <Route exact
          path="/addmymasjid">
          <AddMyMasjidPage setGUserImageUrl={setGUserImageUrl} screenWidth={screenWidth} setScreenWidth={setScreenWidth} g_userId={g_userId} setGUserId={setGUserId} g_userName={g_userName} setGUserName={setGUserName}
            checkLogin={checkLogin} setCheckLogin={setCheckLogin} g_uid={g_uid} setGUID={setGUID} g_userImageUrl={g_userImageUrl} setGCookie={setGCookie} g_cookies={g_cookies} />
        </Route>
        <Route exact
          path="/google_map/:masjidName/:masjidLatitude/:masjidLongitude">
          <GoogleMapPage screenWidth={screenWidth} setScreenWidth={setScreenWidth} />
        </Route>
        <Route exact
          path="/create_account">
          <CreateAccountPage g_userId={g_userId} screenWidth={screenWidth} setGUserId={setGUserId} g_userName={g_userName} g_uid={g_uid} setGUserName={setGUserName} g_userImageUrl={g_userImageUrl} />
        </Route>
      </Switch>
    </>
  )
}

export default App;
