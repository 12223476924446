import React, { useEffect, useState } from "react";
import '../../scss/font.scss';
import '../../scss/animatable.scss';
import '../../scss/display.scss';
import '../../scss/margin.scss';
import '../../scss/padding.scss';
import '../../scss/size.scss';
import '../../scss/breakpoints.scss';
import '../../scss/buttons.scss';
import AddMasjidPage from "../Add_Masjid_Page_Component/add_masjid_page";
import axios from "axios";
import { BASE_API_URL_FOR_SESSION } from '../../context';

function AddMyMasjidPage(props) {
    const { windwoHeight, screenWidth, g_userId, setGUserId, g_userName, setGUserName, checkLogin, setCheckLogin, g_uid, setGUID, setGUserImageUrl, g_userImageUrl, g_cookies, setGCookie } = props;

    useEffect(() => {
        if((g_userId === null || g_userId === undefined) && (g_uid === null || g_uid === undefined))
        {
            CheckSession();
        }
    }, []);

    const CheckSession = () => {
        axios.post(`${BASE_API_URL_FOR_SESSION}/checkSession`)
        .then(response => {
            if (response.data.userId !== null && response.data.userId !== undefined) {
                setGUserId(response.data.userId);
                setGUserName(response.data.userName);
                setGUID(response.data.uid);
                setCheckLogin(true);
            }
        })
    }

    return (
        <>
            <div>
                <AddMasjidPage windwoHeight={windwoHeight} screenWidth={screenWidth} g_userId={g_userId} setGCookie={setGCookie} g_cookies={g_cookies}
                 setGUserId={setGUserId} g_userName={g_userName} setGUserName={setGUserName} setGUserImageUrl={setGUserImageUrl} g_userImageUrl={g_userImageUrl}
                checkLogin={checkLogin} setCheckLogin={setCheckLogin} g_uid={g_uid} setGUID={setGUID}  />
            </div>
        </>
    );
}

export default AddMyMasjidPage;