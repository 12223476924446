import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";


const DatePicker = (props) => {

    const { isReadOnly, setEndDate, setStartDate, startDate,  endDate, setEndTime, endTime, setStartTime, startTime } = props;
    const [s_date, setSDate] = useState("");
    const [s_time, setSTime] = useState("");
    const [e_date, setEDate] = useState("");
    const [e_time, setETime] = useState("");
    const [isDateChange, setIsDateChange] = useState(false);
    const [isTimeChange, setIsTimeChange] = useState(false);

    useEffect(() => {
        if(isDateChange === false){
            if (startDate !== null && startDate !== undefined && startDate != "") {
                changeDateFormat(startDate, 'startDate');
            }
            else {
                let dateString = new Date();
                changeDateFormat(dateString, 'startDate');
            }
        }
    }, [startDate])

    useEffect(() => {
        if(isDateChange === false){
            if (endDate !== null && endDate !== undefined && endDate != "") {
                changeDateFormat(endDate, 'endDate');
            }
            else {
                let dateString = new Date();
                changeDateFormat(dateString, 'endDate');
            }
        }
    }, [endDate])

    useEffect(() => {
        if(isTimeChange === false){
            let timeString = new Date();
            if (startTime !== null && startTime !== undefined && startTime != "") {
                changeTimeFormat(startTime, 'startTime');
            }
            else {
                changeTimeFormat(timeString, 'startTime');
            }
        }
    }, [startTime])

    useEffect(() => {
        if(isTimeChange === false){
            let timeString = new Date();
            if (endTime !== null && endTime !== undefined && endTime != "") {
                changeTimeFormat(endTime, 'endTime');
            }
            else {
                changeTimeFormat(timeString, 'endTime');
            }
        }
    }, [endTime])

    const changeDateFormat = (dateString, name) => {
        if (dateString !== undefined && dateString !== null) {
            let day = dateString.getDate();
            let month = parseInt(dateString.getMonth() + 1);
            if (day < 10) {
                day = '0' + day;
            }
            if (month < 10) {
                month = '0' + month;
            }

            let date = dateString.getFullYear() + "-" + month + "-" + day;
            let dateWithSlash = dateString.getFullYear() + "/" + month + "/" + day;
            if(name === 'startDate'){
                setSDate(date);
                setStartDate(dateWithSlash);
            }
            else if(name === 'endDate'){
                setIsDateChange(true);
                setEDate(date);
                setEndDate(dateWithSlash)
            }
        }
    }

    const changeTimeFormat = (timeString, name) => {
        if (timeString !== undefined && timeString !== null) {
            let hour = timeString.getHours();
            let minute = timeString.getMinutes();
            if (hour < 10) {
                hour = '0' + hour;
            }
            if (minute < 10) {
                minute = '0' + minute;
            }
            if(name === 'startTime'){
                setSTime(hour + ':' + minute);
                setStartTime(hour + ':' + minute);
            }
            else if(name === 'endTime'){
                setIsTimeChange(true);
                setETime(hour + ':' + minute);
                setEndTime(hour + ':' + minute);
            }
        }
    }

    const getDate = (event, name) => {
        setIsDateChange(true);
        let dateValue = event.target.value;
        const dateWithSlash = dateValue.replaceAll('-', '/');
        if(name === 'start'){
            setSDate(dateValue);
            setEDate(dateValue);
            setStartDate(dateWithSlash);
            setEndDate(dateWithSlash);
        }
        else{
            setEDate(dateValue);
            setEndDate(dateWithSlash);
        }
    }

    const getTime = (event, name) => {
        setIsTimeChange(true);
        let timeValue = event.target.value;
        if(name === 'start'){
            setSTime(timeValue);
            setStartTime(timeValue);
            timeValue = timeValue.split(':');
            let hour = Number(timeValue[0]);
            let minute = Number(timeValue[1]);
            minute = minute + 30;
            if(minute === 60){
                minute = '00';
                hour = hour + 1;
            }
            if(minute > 60){
                minute = minute - 60;
                hour = hour + 1;
            }
            if(minute < 10){
                minute = '0' + minute
            }
            if(hour < 10){
                hour = '0' + hour;
            }
            if(hour === 24){
                hour = '00' 
            }
            timeValue = hour + ":" + minute;
            setETime(timeValue);
            setEndTime(timeValue);
        }
        else{
            setETime(timeValue);
            setEndTime(timeValue)
        }
    }

    return (
        <>
            <Row>
                <Col xs={12} sm={12} md={6} lg={3} >
                    <label className="mt-2">Start Date</label>
                    <input className="eventList-input-feild mt-1" type="date" value={s_date} onChange={(event) => {getDate(event, 'start')}} readOnly={isReadOnly} disabled={isReadOnly} />
                </Col>
                <Col xs={12} sm={12} md={6} lg={3} >
                    <label className="mt-2">Start Time</label>
                    <input className="eventList-input-feild mt-1" type="time" value={s_time} onChange={(event) => {getTime(event, 'start')}} readOnly={isReadOnly} disabled={isReadOnly} />
                </Col>
                <Col xs={12} sm={12} md={6} lg={3} >
                    <label className="mt-2">End Date</label>
                    <input className="eventList-input-feild mt-1" type="date" value={e_date} min={s_date} onChange={(event) => {getDate(event, 'end')}} readOnly={isReadOnly} disabled={isReadOnly} />
                </Col>
                <Col xs={12} sm={12} md={6} lg={3} >
                    <label className="mt-2">End Time</label>
                    <input className="eventList-input-feild mt-1" type="time" value={e_time}  onChange={(event) => {getTime(event, 'end')}} readOnly={isReadOnly} disabled={isReadOnly} />
                </Col>
            </Row>
        </>
    )
}

export default DatePicker;
