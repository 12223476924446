import React, { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Typography } from '@mui/material';
import './login_in.css';
import MasjidLogo from "../../assets/images/masjidCommunityIcon.png";
import CloseIcon from "../../assets/images/cross.png";

export default function LoginDialog(props) {

    const { loginDialog, setLoginDialog, g_userId, screenWidth } = props;

    useEffect(() => {
        if (g_userId !== null && g_userId !== undefined && g_userId !== "" && loginDialog === true) {
            setLoginDialog(false);
        }
    }, [g_userId]);

    const ClosePopUp = () => {
        setLoginDialog(false);
    }

    return (
        <div>
            <Dialog
                open={loginDialog}
                onClose={ClosePopUp}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                sx={{ overflowY: "visible" }}
                style={{ overflowY: "visible" }}
                fullWidth={true}>
                <DialogContent
                    style={{ overflowY: "visible" }}
                    sx={{ overflowY: "visible" }} >
                    <div className="horizontal-center">
                        <img src={MasjidLogo} width={screenWidth > 600 ? "80" : "60"} height={screenWidth > 600 ? "85" : "65"} style={{
                            borderRadius: "24px", boxShadow: "0px 4px 12px rgba(100, 199, 237, 0.63)",
                            position: "absolute", top: screenWidth > 600 ? "-40px" : "-28px", background: "linear-gradient(180deg, #9DE4FF 0%, #84DDFF 30.21%, #7ED6F7 61.98%, #69CEF2 100%)"
                        }} />
                    </div>
                    <div className="horizontal-end">
                        <img src={CloseIcon} width="30" height="30" className="pointer" onClick={ClosePopUp} />
                    </div>
                    <div className="horizontal-center padding-bottom-22 padding-top-5">
                        <Typography variant="h1" sx={{ fontSize: { md: "15px", xs: "13px" }, fontWeight: "600", textAlign: "center" }} > Masjid Community </Typography>
                    </div>
                    <div className="horizontal-center padding-bottom-22">
                        <Typography variant="h1" sx={{ fontSize: { md: "22px", xs: "18px" }, fontWeight: "700", textAlign: "center" }} > Login to Masjid Community </Typography>
                    </div>
                    <iframe
                        // src="http://localhost:3002/login"
                        src="https://read.quranmajeed.com/login"
                        sandbox="allow-same-origin allow-scripts allow-popups allow-forms" width="100%" height="auto" scrolling="no" style={{ overflow: "hidden" }} />
                </DialogContent>
            </Dialog>
        </div>
    );
}
