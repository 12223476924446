import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Col, Row, Container, Alert, Collapse } from "react-bootstrap";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { BASE_API_URL, CreateAccountForMasjid, GetUserById, LoginWithGoogle, BASE_API_URL_FOR_SESSION } from '../../context.js';
import MoveBackward from '../../assets/images/backward.png';
import PhoneInput from 'react-phone-input-2';
import '../../scss/font.scss';
import '../../scss/animatable.scss';
import '../../scss/display.scss';
import '../../scss/margin.scss';
import '../../scss/padding.scss';
import '../../scss/size.scss';
import '../../scss/breakpoints.scss';
import '../../scss/buttons.scss';
import './add_masjid_page.scss';
import './contact.css';
import BackDropComponent from "../BackDrop_Component/backdrop.js";
import AlertDialog from "../Dialog_Component/dialog";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from "@mui/material";
import LoginDialog from "../Log_In_Component/login_in.js";

function AddMasjidPage(props) {

    const { windwoHeight, screenWidth, g_userId, setGUserId, setGUserName, checkLogin, setCheckLogin, setGUID, setGUserImageUrl, g_userName, g_userImageUrl, g_uid, g_cookies, setGCookie } = props;
    let history = useHistory();
    const [updateStates, setUpdateState] = useState(true);
    const [state, setState] = useState();
    const [updateUseStates, setUpdateUseStates] = useState(true);
    const [masjidList, setMasjidList] = useState([]);
    const [currentLocationlatitude, setCurrentLocationLatitude] = useState();
    const [currentLocationlongitude, setCurrentLocationLongitude] = useState();
    const [isAccountExist, setIsAccountExist] = useState();
    const [openModal, setOpenModal] = useState(false);
    const [userInfoModal, setUserInfoModal] = useState(false);
    const [isAddButtonClicked, setIsAddButtonClicked] = useState(false);
    const [openLoader, setOpenLoader] = useState(false);
    const [checkStatusOnReload, setCheckStatus] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const [loginDialog, setLoginDialog] = useState(false);

    // add Masjid Form ...

    const [masjidName, setMasjidName] = useState("");
    const [masjidlatitude, setMasjidlatitude] = useState("");
    const [masjidlongitude, setMasjidlongitude] = useState("");
    const [masjidCity, setMasjidCity] = useState("");
    const [masjidCountry, setMasjidCountry] = useState("");
    const [masjidDescription, setMasjidDescription] = useState("");
    const [masjidContactNumber, setMasjidContactNumber] = useState("");
    const [masjidImageSrc, setMasjidImageSrc] = useState("");
    const [disableLocationButton, setLocationButton] = useState(false);
    const [isMasjidNameNotNull, setIsMasjidName] = useState();
    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [responseMessage, setresponseMessage] = useState();
    const [responseStatus, setresponseStatus] = useState();

    // for masjid Details from Masjid List Page

    const [masjidNameFromMasjidList, setMasjidNameFromMasjidList] = useState("");
    const [latitudeFromMasjidList, setLatitudeFromMasjidList] = useState("");
    const [longitudeFromMasjidList, setLongitudeFromMasjidList] = useState("");
    const [imageSrcFromMasjidList, setImageSrcFromMasjidList] = useState("");

    // for Masjid Details from Google Map Page

    const [masjidNameFromGoogleMap, setMasjidNameFromGoogleMap] = useState("");
    const [latitudeFromGoogleMap, setLatitudeFromGoogleMap] = useState("");
    const [longitudeFromGoogleMap, setLongitudeFromGoogleMap] = useState("");
    const [userInfoAlert, setUserInfoAlert] = useState(false);
    const [createAccount, setCreateAccount] = useState({
        firstName: "",
        lastName: "",
        email: "",
        profilePicture: "",
    });

    if (openModal === true || userInfoModal === true) {
        window.onclick = function (event) {
            var modalDiv = document.getElementById('modal-div');
            var modal = document.getElementById('modal');
            if (event.target === modalDiv || event.target === modal) {
                setOpenModal(false);
                setUserInfoModal(false)
            }
        }
    }

    useEffect(() => {

    }, [updateStates])

    useEffect(() => {
        if (g_userId !== null && g_userId !== undefined && g_userName !== null && g_userName && g_uid !== null && g_uid !== undefined) {
            GetAccountDetail(g_userId);
        }
    }, [g_userId, g_userName, g_uid])

    useEffect(() => {
        if(g_userId !== null && g_userId !== undefined && openModal === true){
            setOpenModal(false);
        }
    },[g_userId])

    useEffect(() => {
        if (isAccountExist === true) {
            if (isAddButtonClicked === true) {
                AddMasjid();
            }
        }
    }, [isAccountExist]);

    useEffect(() => {
        const pageState = localStorage.getItem("State");
        const currentlatitude = localStorage.getItem('LatitudeFromCurrentLocation');
        const currentlongitude = localStorage.getItem('LongitudeFromCurrentLocation');
        const masjidNameFromMasjidListPage = localStorage.getItem("MasjidNameFromMasjidList");
        const latitudeFromMasjidListPage = localStorage.getItem("LatitudeFromMasjidList");
        const longitudeFromMasjidListPage = localStorage.getItem("LongitudeFromMasjidList");
        const imageSrcFromMasjidListPage = localStorage.getItem("ImageSrcFromMasjidList");
        const masjidNameFromGoogleMap = localStorage.getItem("MasjidNameFromGoogleMap");
        const latitudeFromGoogleMap = localStorage.getItem("LatitudeFromGoogleMap");
        const longitudeFromGoogleMap = localStorage.getItem("LongitudeFromGoogleMap");
        setState(pageState);
        setCurrentLocationLatitude(currentlatitude);
        setCurrentLocationLongitude(currentlongitude);
        setMasjidNameFromMasjidList(masjidNameFromMasjidListPage);
        setLatitudeFromMasjidList(latitudeFromMasjidListPage);
        setLongitudeFromMasjidList(longitudeFromMasjidListPage);
        setImageSrcFromMasjidList(imageSrcFromMasjidListPage)
        setMasjidNameFromGoogleMap(masjidNameFromGoogleMap);
        setLatitudeFromGoogleMap(latitudeFromGoogleMap);
        setLongitudeFromGoogleMap(longitudeFromGoogleMap);
        setUpdateUseStates(!updateUseStates);
    }, []);

    useEffect(() => {
        if (checkStatusOnReload === true) {
            if (state === "0") {
                setMasjidName("");
                setMasjidlatitude(currentLocationlatitude);
                setMasjidlongitude(currentLocationlongitude);
            }
            else if (state === "1") {
                setMasjidName(masjidNameFromMasjidList);
                setMasjidlatitude(latitudeFromMasjidList);
                setMasjidlongitude(longitudeFromMasjidList);
                setMasjidImageSrc(imageSrcFromMasjidList);
            }
            else if (state === "2") {
                setMasjidName(masjidNameFromGoogleMap);
                setMasjidlatitude(latitudeFromGoogleMap);
                setMasjidlongitude(longitudeFromGoogleMap);
                setMasjidImageSrc(imageSrcFromMasjidList);
            }
        }
    }, [state]);

    const GetAccountDetail = (g_userId) => {
        const body = {
            emailId: g_userId
        }
        axios.post(`${BASE_API_URL}/account/get_user_by_id`, body, { withCredentials: false })
            .then(response => {
                if (response.status === 200) {
                    setIsAccountExist(true);
                }
                else {
                    setIsAccountExist(false);
                    if (g_userId !== null && g_userId !== undefined && g_userName !== null && g_userName && g_uid !== null && g_uid !== undefined) {
                        const name = g_userName.split(' ');
                        const firstName = name[0];
                        const lastName = name[1];
                        const body = {
                            id: g_userId,
                            firstName: firstName,
                            lastName: lastName,
                            email: g_userId,
                            uid: g_uid,
                            profilePicture: g_userImageUrl !== null && g_userImageUrl !== undefined ? g_userImageUrl : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUh3k9PBWmPFALGyMwCNsaukw4H-y-B2LxHA&usqp=CAU"
                        }
                        CreateAccount(body);
                    }
                }
            })

    }

    const GetMasjid = (event, feildName) => {
        setOpenLoader(true);
        const body = {
            masjidName: masjidName,
            latitude: Number(currentLocationlatitude),
            longitude: Number(currentLocationlongitude)
        }
        axios.post(`${BASE_API_URL}/masjid/scrap_masajid`, body, { withCredentials: false })
            .then(res => {
                if(res.status == 200){
                        setMasjidList(res.data);
                        setOpenLoader(false);
                }
            })
            .catch((err) => {
                setOpenLoader(false);
            })
    }

    const SelectMasjidFromListOfScrapData = (masjid, index) => {
        setCheckStatus(false);
        setState("1");
        const name = masjid.masjidName;
        const latitude = masjid.latitude;
        const longitude = masjid.longitude;
        const imagesrc = masjid.imageSrc;
        setMasjidName(masjid.masjidName);
        setMasjidlatitude(masjid.latitude);
        setMasjidlongitude(masjid.longitude);
        localStorage.setItem("State", 1);
        localStorage.setItem("MasjidNameFromMasjidList", name);
        localStorage.setItem("LatitudeFromMasjidList", latitude);
        localStorage.setItem("LongitudeFromMasjidList", longitude);
        localStorage.setItem("ImageSrcFromMasjidList", imagesrc);
        setMasjidList([]);
        setUpdateState(!updateStates);
    }

    const InputEvent = (event, feildName) => {
        let value = '';
        if (feildName !== 'masjidContactNumber') {
            value = event.target.value;
        }
        else {
            value = event;
        }
        if (feildName === 'masjidname') {
            setMasjidName(value);
            if (value !== "") {
                setIsMasjidName(false);
                setLocationButton(false);
            }
            else {
                setIsMasjidName(true);
                setLocationButton(true);
            }
        }
        else if (feildName === 'masjidlatitude') {
            setMasjidlatitude(value);
        }
        else if (feildName === 'masjidlongitude') {
            setMasjidlongitude(value);
        }
        else if (feildName === 'masjidCity') {
            setMasjidCity(value);
        }
        else if (feildName === 'masjidCountry') {
            setMasjidCountry(value);
        }
        else if (feildName === 'masjidDescription') {
            setMasjidDescription(value);
        }
        else if (feildName === 'masjidContactNumber') {
            setMasjidContactNumber(value);
        }
    }

    const AddMasjid = () => {
        if (masjidName !== "" && masjidlatitude !== "" && masjidlongitude !== "" && masjidCity !== "" && masjidCountry !== "" && masjidContactNumber !== "") {
            setIsAddButtonClicked(true);
            if (checkLogin === true && g_userId !== null && g_userId !== undefined) {
                if (isAccountExist === true) {
                    setOpenLoader(true);
                    const body = {
                        Name: masjidName,
                        Latitude: masjidlatitude,
                        Longitude: masjidlongitude,
                        City: masjidCity,
                        Country: masjidCountry,
                        Description: masjidDescription,
                        Contact: masjidContactNumber,
                        UserId: g_userId,
                        ImageSrc: masjidImageSrc
                    }
                    axios.post(`${BASE_API_URL}/masjid/add_masjid`, body, { withCredentials: false })
                        .then(response => {
                            setOpenLoader(false);
                            setresponseMessage(response.data.message);
                            setresponseStatus(response.status);
                            setDisabled(true);
                            setOpen(true);
                            setGCookie("isMasjidAdded", true);
                            localStorage.removeItem("State");
                            localStorage.removeItem("ImageSrcFromMasjidList");
                            localStorage.removeItem("LatitudeFromMasjidList");
                            localStorage.removeItem("LongitudeFromMasjidList");
                            localStorage.removeItem("MasjidNameFromMasjidList");
                        })
                }
                else {
                    setUserInfoModal(true)
                }
            }
            else {
                setOpenModal(true)
            }
        }
        else {
            setresponseStatus(400);
            setOpenLoader(false);
            setresponseMessage('Please Fill All Feilds');
            setOpen(true);
        }
        setTimeout(() => {
            setOpen(false);
        }, 4000);
    }

    const AddMasjidForIOS = () => {
        setUserInfoModal(true);
    }

    const SetMasjidLocation = () => {
        if (masjidName !== undefined && masjidName !== null && masjidName !== "") {
            history.push(`/google_map/${masjidName}/${masjidlatitude}/${masjidlongitude}`)
        }
        else {
            setIsMasjidName(true);
            setLocationButton(true);
        }
    }

    const ClearFilter = () => {
        if (masjidName !== null && masjidName !== undefined && masjidName !== "") {
            setMasjidName("");
            setOpenLoader(false);
            setMasjidList([]);
        }
    }

    const Login = async () => {
        setOpenLoader(true);
        const result = await LoginWithGoogle()
        if (result) {
            if (result.user.email && result.user.uid && result.user.displayName) {
                let email = result.user.email;
                let name = result.user.displayName;
                let uid = result.user.uid;
                let photoUrl = result.user.photoURL;
                let firstName = result._tokenResponse.firstName;
                let lastName = result._tokenResponse.lastName;
                setCheckLogin(true);
                setGUserId(email);
                setGUID(uid);
                setGUserName(name);
                setGUserImageUrl(photoUrl);
                const body = {
                    id: email,
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    profilePicture: photoUrl,
                uid: uid
            }
            CreateAccount(body)
            CreateLoginSessionAndContinue(email, name, uid);
            setOpenModal(false);
            }
            else {
                setOpenLoader(false);
                setOpenDialog(true)
            }
        }
        else {
            setOpenLoader(false);
            setOpenDialog(true)
        }
    }

    const CreateLoginSessionAndContinue = (email, name, uid) => {
        axios.post(`${BASE_API_URL_FOR_SESSION}/storeSession/${email}/${name}/${uid}`)
            .then(response => {
                if (response.data.userId !== null && response.data.userId !== undefined) {
                    console.log('Session Created')
                }
            })
    }

    const CreateAccount = (body) => {
        axios.post(`${BASE_API_URL}/account/create`, body, { withCredentials: false })
            .then(response => {
                if (response.status === 200) {
                    setIsAccountExist(true);
                }
            })
    }

    const onChangeUserInfoForm = (event) => {
        const { name, value } = event.target;
        setCreateAccount((preValue) => {
            return {
                ...preValue,
                [name]: value,
            }
        });
    }

    const SubmitUserInfo = async () => {
        if (createAccount.firstName !== "" && createAccount.lastName && createAccount.email !== "") {
            const body = {
                id: createAccount.email,
                firstName: createAccount.firstName,
                lastName: createAccount.lastName,
                email: createAccount.email,
                uid: g_uid,
                profilePicture: createAccount.photoURL !== null && createAccount.photoURL !== undefined ? createAccount.photoURL : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUh3k9PBWmPFALGyMwCNsaukw4H-y-B2LxHA&usqp=CAU"
            }
            const response = await CreateAccountForMasjid(body);
            if (response.status === 200 || response.status === 201) {
                const body = {
                    emailId: response.email
                }
                const userData = await GetUserById(body);
                if (userData.status === 200) {
                    setGUserId(userData.data.email);
                    setGUID(userData.data.uid);
                    setGUserName(userData.data.firstName + ' ' + userData.data.lastName);
                    setGUserImageUrl(userData.data.profilePicture);
                    setCheckLogin(true);
                    setIsAddButtonClicked(true);
                    setIsAccountExist(true);
                    setresponseStatus(200);
                    setresponseMessage('Your Info Submitted Successfully');
                    setUserInfoAlert(true);
                    setUserInfoModal(false);
                    setUpdateState(!updateStates);
                }
            }
        }
        else {
            setresponseStatus(400);
            setresponseMessage('Please Fill All Feilds');
            setUserInfoAlert(true);
        }
        setTimeout(() => {
            setUserInfoAlert(false)
        }, 3000);
    }

    const keyPress = (event) => {
        if ((event.code === "Enter" || event.code === "NumpadEnter") && masjidName !== null && masjidName !== undefined && masjidName !== "") {
            GetMasjid();
        }
    }

    const KeyDown = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            event.target.blur();
            GetMasjid();
        }
    }

    const DissmissMobileKeyboard = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            event.target.blur();
        }
    }

    return (
        <div style={{ minHeight: windwoHeight }}>
            <AlertDialog open={openDialog} setOpen={setOpenDialog} title="Oops!" bodyText="Something Went Wrong. Sign-in Popup has been Blocked. Please Check Your Browser Setting." buttonText="Close" />
            <LoginDialog loginDialog={loginDialog} setLoginDialog={setLoginDialog} g_userId={g_userId} screenWidth={screenWidth} />
            <Row className="m-0">
                <Col style={{ backgroundColor: '#FAE2B3' }}>
                    <h1 className="text-center font-weight-700 text-shadow-grey padding-15" style={{ marginBottom: '0rem', fontSize: screenWidth > 600 ? '30px' : '22px' }}><img className="pointer" src={MoveBackward} width="35" height="35" onClick={() => { history.push(`/`) }} style={{ float: 'left' }} />Add Masjid Page</h1>
                </Col>
            </Row>
            {
                (userInfoModal === true) ?
                    <div className="modal" id="modal">
                        <div className="modal-content">
                            <div className="padding-15 horizontal-center ">
                                <p className="text-center" style={{ marginBottom: '0rem' }}><FontAwesomeIcon icon={faExclamationCircle} width="20%" style={{ marginRight: '6px', color: 'orange', marginTop: '5px' }} />You have to provide user information.</p>
                            </div>
                            <div className="padding-bottom-20">
                                <div className="horizontal-center">
                                    <input type="text" className="eventList-input-feild mt-3" placeholder="First Name" name="firstName" value={createAccount.firstName} onChange={onChangeUserInfoForm} style={{ width: "90%" }} />
                                </div>
                                <div className="horizontal-center">
                                    <input type="text" className="eventList-input-feild mt-3" placeholder="Last Name" name="lastName" value={createAccount.lastName} onChange={onChangeUserInfoForm} style={{ width: "90%" }} />
                                </div>
                                <div className="horizontal-center">
                                    <input type="text" className="eventList-input-feild mt-3" placeholder="Email" name="email" value={createAccount.email} onChange={onChangeUserInfoForm} style={{ width: "90%" }} />
                                    {/* <input type="hidden" className="eventList-input-feild mt-3" name="uid" value={g_uid} style={{width : "90%"}}  /> */}
                                </div>
                                <div className="horizontal-center mt-2">
                                    <button onClick={SubmitUserInfo} className="m-2 btn btn-success" > Submit</button>
                                    <button className="btn btn-danger m-2" onClick={() => { setUserInfoModal(false); }} >Cancel</button>
                                </div>
                                <div className="horizontal-center">
                                    <Collapse in={userInfoAlert}>
                                        <div id="example-collapse-text" className="mt-3" style={{ width: '80%' }}>
                                            <Alert variant={responseStatus === 200 ? "success" : "danger"} onClose={() => setUserInfoAlert(false)} dismissible>
                                                <h6 style={{ marginBottom: '0.1rem' }}>{responseMessage}</h6>
                                            </Alert>
                                        </div>
                                    </Collapse>
                                </div>
                            </div>
                        </div>
                    </div> : ""
            }

            <div>
                <Container style={{ backgroundColor: "white", borderRadius: '10px' }}>
                    <Row className="horizontal-center">
                        <Col lg={12} md={12} sm={12} xs={12}>
                            <div className="padding-top-20 padding-bottom-20 flex">
                                <input className="form-control-input-with-icon" type="text" placeholder="Enter Your Masjid Name" name="masjidname" value={masjidName} onKeyPress={keyPress} onKeyDown={KeyDown} onChange={(event) => { InputEvent(event, 'masjidname') }} />
                                <button className="search-input-clear-button" onClick={ClearFilter} >
                                    <FontAwesomeIcon className={screenWidth > 600 ? "clear-fontawesome" : "clear-fontawesome-for-mobile"} color={masjidName.length > 0 ? "black" : "white"} icon={faTimes} width="40%" />
                                </button>
                                <button className="search-input-search-button" onClick={(event) => { GetMasjid(event, 'masjidname') }} >
                                    <FontAwesomeIcon className={screenWidth > 600 ? "search-fontawesome" : "search-fontawesome-for-mobile"} icon={faSearch} width="40%" />
                                </button>
                            </div>
                            {
                                (isMasjidNameNotNull === true) ?
                                    <label style={{ color: 'red' }}>Please Enter Masjid Name to set Location</label>
                                    : null
                            }
                        </Col>
                    </Row>
                    {
                        (masjidList !== null && masjidList !== undefined) ?
                            <Row className={masjidList.length > 0 ? "add-masjid-page-scroll" : "add-masjid-page-no-scroll"}>
                                <Col lg={12} md={12} sm={12} xs={12} style={{ background: "white" }}>
                                    {
                                        masjidList.map((val, index) => {
                                            return (
                                                <div key={index}>
                                                    <div className="horizontal-between pointer" onClick={() => { SelectMasjidFromListOfScrapData(val, index) }}>
                                                        <div>
                                                            <h6>{val.masjidName}</h6>
                                                            <p>{val.address}</p>
                                                        </div>
                                                        <div className="vertical-start">
                                                            <button className="btn background-color-green font-color-white" onClick={() => { SelectMasjidFromListOfScrapData(val, index) }} >Select</button>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                </div>
                                            )
                                        })
                                    }
                                </Col>
                            </Row>
                            : ""
                    }
                    <Row className="horizontal-center">
                        <Col lg={12} md={12} sm={12} xs={12}>
                            {
                                (latitudeFromGoogleMap !== null && latitudeFromGoogleMap !== undefined && state === 2) ?
                                    <div>
                                        <iframe className="mt-3 add-masjid-page-map" title="FromGoogleMap" src={`https://maps.google.com/maps?q=${latitudeFromGoogleMap},${longitudeFromGoogleMap}&hl=en&z=19&amp;&source=embed&output=svembed`} width="100%" height="450" ></iframe>
                                    </div>
                                    :
                                    <div id="iframeDiv">
                                        <iframe className="mt-3 add-masjid-page-map" title="FromMasjidList" src={`https://maps.google.com/maps?q=${masjidlatitude},${masjidlongitude}&hl=en&z=19&amp;&source=embed&output=svembed`} width="100%" height="450" ></iframe>
                                    </div>
                            }
                        </Col>
                        <Col>
                            {
                                (state !== "1") ?
                                    <div className="horizontal-center">
                                        <button className="btn background-color-green font-color-white mt-2" onClick={SetMasjidLocation} disabled={disableLocationButton} >Set Your Masjid Location</button>
                                    </div>
                                    : null
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6} sm={6}>
                            <input type="number" readOnly={state === "1" || state === "2" ? true : false} onKeyDown={DissmissMobileKeyboard} className="eventList-input-feild mt-3" placeholder="Latitude" value={masjidlatitude} onChange={(event) => { InputEvent(event, 'masjidlatitude') }} name="masjidlatitude" />
                        </Col>
                        <Col xs={6} sm={6}>
                            <input type="number" readOnly={state === "1" || state === "2" ? true : false} onKeyDown={DissmissMobileKeyboard} className="eventList-input-feild mt-3" placeholder="Longitude" value={masjidlongitude} onChange={(event) => { InputEvent(event, 'masjidlongitude') }} name="masjidlongitude" />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6} sm={6}>
                            <input type="text" className="eventList-input-feild mt-3" placeholder="City" value={masjidCity} onKeyDown={DissmissMobileKeyboard} onChange={(event) => { InputEvent(event, 'masjidCity') }} name="masjidCity" />
                        </Col>
                        <Col xs={6} sm={6}>
                            <input type="text" className="eventList-input-feild mt-3" placeholder="Country" value={masjidCountry} onKeyDown={DissmissMobileKeyboard} onChange={(event) => { InputEvent(event, 'masjidCountry') }} name="masjidCountry" />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <textarea type="text" className="eventList-input-feild mt-3" placeholder="Description" value={masjidDescription} onKeyDown={DissmissMobileKeyboard} onChange={(event) => { InputEvent(event, 'masjidDescription') }} name="masjidDescription" ></textarea>
                            <PhoneInput
                                value={masjidContactNumber}
                                className="eventList-input-feild mt-3"
                                placeholder="Contact Number"
                                onChange={(event) => { InputEvent(event, 'masjidContactNumber') }}
                            />
                        </Col>
                    </Row>
                    <Collapse in={open}>
                        <div id="example-collapse-text" className="mt-3">
                            <Alert variant={responseStatus === 200 ? "success" : "danger"} onClose={() => setOpen(false)} dismissible>
                                <h6 style={{ marginBottom: '0.1rem' }}>{responseMessage}</h6>
                            </Alert>
                        </div>
                    </Collapse>
                    <Row>
                        <Col className="horizontal-end mb-3">
                            <button className="btn btn-secondary mt-3  horizontal-center" style={{ width: '115px' }} onClick={() => { history.push(`/`) }}>Back</button>
                        </Col>
                        <Col className="horizontal-start mb-3">
                            <button className={disabled ? "btn btn-secondary mt-3  horizontal-center" : "mt-3 btn background-color-green font-color-white  horizontal-center"}
                                onClick={() => { (g_uid !== null && g_uid !== undefined && (g_userId === null || g_userId === undefined)) ? AddMasjidForIOS() : AddMasjid() }}
                                disabled={disabled} aria-controls="example-collapse-text" style={{ width: '115px' }}  >Add Masjid</button>
                        </Col>
                    </Row>

                    <Dialog
                        open={openModal}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                        <DialogTitle id="alert-dialog-title">
                        </DialogTitle>
                        <DialogContent sx={{ paddingBottom : '0px'}}>
                            <DialogContentText id="alert-dialog-description">
                                <div className="padding-5">
                                    <p className="text-center"><FontAwesomeIcon icon={faExclamationCircle} width="20%" style={{ marginRight: '6px', color: 'orange', marginTop: '5px' }} />You need to log in to add your masjid. Do You want to log in ? If Yes, click log in button.</p>
                                </div>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{ justifyContent: 'center !important', paddingBottom : '20px' }}>
                            <Typography component="div" sx={{ display : {xs : "grid", sm : "flex"}, alignItems : "center", justifyContent : "center"}}>
                                <Button onClick={() => {setLoginDialog(true)}} color="info" variant="contained" sx={{ margin : "1px 8px"}}>
                                    Log In
                                </Button>
                                <Button className="" color="error" variant="contained" sx={{ margin : "1px 8px"}} onClick={() => { setOpenModal(false); }} >
                                    Close
                                </Button>
                            </Typography>
                        </DialogActions>
                    </Dialog>
                    <BackDropComponent open={openLoader} />
                </Container>
            </div>
        </div>
    );
}

export default AddMasjidPage;