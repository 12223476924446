import React, { useEffect, useState } from "react";
import HeaderImage from '../../assets/images/staticHeader.svg';
import axios from 'axios';
import { BASE_API_URL, LoginFireBase, LogoutFireBase } from '../../context.js';
import './static_header.scss'
import '../../scss/font.scss';
import '../../scss/animatable.scss';
import '../../scss/display.scss';
import '../../scss/margin.scss';
import '../../scss/padding.scss';
import '../../scss/size.scss';
import '../../scss/breakpoints.scss';
import '../../scss/buttons.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import BackDropComponent from "../BackDrop_Component/backdrop";
import AlertDialog from "../Dialog_Component/dialog";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import LoginDialog from "../Log_In_Component/login_in";

function StaticHeader(props) {
  const { g_userId, setGUserId, setGUserName, setCheckLogin, setGUserImageUrl, setGUID, g_userName, screenWidth, g_userImageUrl, showLoginButton, setIsUpdate, isUpdate } = props;
  return (
    <>
      <div className="static-image">
        <div style={{ backgroundImage: 'linear-gradient(to bottom, rgba(252, 249, 244, 0) 0%, #fefefe 100%)' }}>
          <div className="background_linear_gradiant">
            <h2 className={screenWidth > 960 ? "text-center padding-top-30 font-weight-700 font-size-35 text-shadow-grey" : "text-center padding-top-40 font-weight-700 text-shadow-grey font-size-20"}>Welcome To Masjid Community !</h2>
            {(showLoginButton != false) ?
              <LoginButtonComponent screenWidth={screenWidth} setIsUpdate={setIsUpdate} isUpdate={isUpdate} g_userId={g_userId} setGUserId={setGUserId} setGUID={setGUID} buttonText={g_userId != null && g_userId != undefined ? "Log Out" : "Log In"}
                setGUserName={setGUserName} setCheckLogin={setCheckLogin} setGUserImageUrl={setGUserImageUrl} buttonClassName={g_userId != null && g_userId != undefined ? "static-header-logout" : "static-header-logout"} /> : null
            }
            <div className="horizontal-center">
              <input type="range" min="0" max="100" value="100" className={screenWidth > 600 ? "slider" : "slider-mobile"} readOnly />
            </div>
            <div className="horizontal-center vertical-center ">
              <img src={HeaderImage} width={screenWidth > 600 ? "50%" : "100%"} />
            </div>
          </div>
          {(props.lat_lng === false) ?
            <div className="horizontal-center">
              <p style={{ background: '#ffc107', textAlign: 'center', padding: '10px' }} ><FontAwesomeIcon icon={faExclamationTriangle} width="20%" style={{ marginRight: '6px', color: 'red', marginTop: '5px', fontSize: '20px' }} />You Need To Enable Your Device Location and Your Browser Location For Your Nearby Masjid and Events.</p>
            </div> : null
          }
        </div>
      </div>
    </>
  );
}

export default StaticHeader;

function LoginButtonComponent(props) {
  const { g_userId, setGUserId, setGUserName, setCheckLogin, setGUserImageUrl, setGUID, setIsUpdate, isUpdate, screenWidth } = props;
  const [openLoader, setOpenLoader] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [loginDialog, setLoginDialog] = useState(false);

  useEffect(() => {
    if (g_userId !== null && g_userId !== undefined && g_userId !== "") {
      setLoginDialog(false);
    }
  }, [g_userId]);


  const LoginButton = async () => {
    setLoginDialog(true);
    // setOpenLoader(true);
    // window.open("http://localhost:3002/login", "_blank");
    // setOpenLoader(true);
    // const result = await LoginFireBase();
    // if (result.userId && result.uid && result.userName) {
    //   setCheckLogin(true);
    //   setGUserId(result.userId);
    //   setGUserName(result.userName);
    //   setGUID(result.uid);
    //   setOpenLoader(false);
    // }
    // else{
    //   setOpenLoader(false);
    //   setOpenDialog(true);
    // }
  }



  const CreateAccount = (body) => {
    axios.post(`${BASE_API_URL}/account/create`, body, { withCredentials: false })
      .then(response => {
        if (response.status === 200) {
          console.log('Account Created');
        }
      })
  }

  const LogOut = async () => {
    setOpenLoader(true);
    const result = await LogoutFireBase();
    if (result === true) {
      localStorage.setItem("Domain", "Masjid");
      setGUserId(null);
      setGUserName(null);
      setGUID(null);
      setGUserImageUrl(null);
      setCheckLogin(false);
      setOpenLoader(false);
      setIsUpdate(!isUpdate);
    }
  }

  return (
    <>
      <div>
        <BackDropComponent open={openLoader} />
        <AlertDialog open={openDialog} setOpen={setOpenDialog} title="Oops!" bodyText="Something Went Wrong. Sign-in Popup has been Blocked. Please Check Your Browser Setting." buttonText="Close" />
        <button className={props.buttonClassName} onClick={g_userId != null && g_userId != undefined ? LogOut : LoginButton}>
          {(props.buttonImage != null && props.buttonImage != undefined) ?
            <img src={props.buttonImage} style={{ marginRight: "10px" }} width="20" height="20" /> : null
          }
          {props.buttonText}</button>
          <LoginDialog loginDialog={loginDialog} setLoginDialog={setLoginDialog} g_userId={g_userId} screenWidth={screenWidth} />
      </div>
      {/* <div>
        <Dialog
          open={openIframe}
          onClose={ClosePopUp}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="sm"
          fullWidth={true}>
          <DialogContent>
            <iframe
              src="http://localhost:3002/login"
              // src="https://read.quranmajeed.com/login"
              sandbox="allow-same-origin allow-scripts allow-popups allow-forms" width="100%" height="350px" scrolling="no" style={{ overflow: "hidden" }} />
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center' }}>
            <a href="https://quranmajeed.com/" target="_blank" style={{ color: "#54575a", textDecoration: "none" }}>
              <p style={{ fontSize: "12px" }}>Quran Majeed</p>
            </a>
          </DialogActions>
        </Dialog>
      </div> */}
    </>
  );
}

export { LoginButtonComponent };
