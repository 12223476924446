import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import './masjid_list.scss';
import '../../scss/font.scss';
import '../../scss/animatable.scss';
import '../../scss/display.scss';
import '../../scss/margin.scss';
import '../../scss/padding.scss';
import '../../scss/size.scss';
import '../../scss/breakpoints.scss';
import '../../scss/buttons.scss';
import axios from "axios";
import Loader from "react-loader-spinner";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { BASE_API_URL } from '../../context.js';
import MasjidListView from "./masjidList_view";
import Google from '../../assets/images/google.png'
import AddButtonBlackImage from '../../assets/images/add-button-black.png';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

function ExploreTab(props) {
    const { screenWidth, g_userId, showScrollTopButton, latitude, longitude, lat_lng, showMobileBottomBar, setShowMobileBottomBar, setOpenLoader,
        setGUserImageUrl, g_userImageUrl, setGUserId, g_userName, setGUserName, setCheckLogin, checkLogin, setGUID, g_cookies, setGCookie, setPageLoaded, pageLoaded } = props;
    let history = useHistory();
    const [masjidList, setMasjidList] = useState([]);
    const [masjidName, setMasjidName] = useState("masjid");
    const [searchMasjidName, setSearchMasjidName] = useState("");
    const [checklat_long, setchecklat_long] = useState();
    const [LoaderSpinner, setLoaderSpinner] = useState(true);
    const [calluseEffectToSetLat_long, setCallUseEffect] = useState(true);
    const [isMasajidExist, setIsMasajidExist] = useState(true);
    const [openImageModal, setOpenImageModal] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const [copyMasjidList, setCopyMasjidList] = useState([]);
    const [masjidListForSearching, setMasjidListForSearching] = useState([]);
    const [masjidIdForFollow, setMasjidIdForFollow] = useState(false);
    const [googleScrapingList, setGoogleScrapingList] = useState();
    const [masjidListByUserId, setMasjidListByUserId] = useState([]);
    const [searchMasjidList, setSearchMasjidList] = useState([]);
    const [callScrapingApi, setCallScrapingApi] = useState(true);

    useEffect(() => {
        if (g_userId === null || g_userId === undefined) {
            const listofAllMasjid = JSON.parse(localStorage.getItem("ListofAllMasjid"));
            if (listofAllMasjid !== null && listofAllMasjid !== undefined) {
                setMasjidList(listofAllMasjid);
            }
            else {
                GetAllMasajidFromBD();
            }
        }
    }, [])

    useEffect(() => {
        if (pageLoaded === true) {
            localStorage.removeItem("ListofAllMasjid");
            localStorage.removeItem("ListofAllMasjidByUserId");
            setPageLoaded(!pageLoaded)
        }
        if (lat_lng === true) {
            if (latitude !== null && latitude !== undefined && longitude !== null && longitude !== undefined) {
                localStorage.setItem('LatitudeFromCurrentLocation', latitude);
                localStorage.setItem('LongitudeFromCurrentLocation', longitude);
                setchecklat_long(true);
            }
            else {
                setchecklat_long(false);
                setCallUseEffect(!calluseEffectToSetLat_long)
            }
        }
        else {
            setLoaderSpinner(false)
        }
    }, [calluseEffectToSetLat_long])

    useEffect(() => {
        if (g_userId !== null && g_userId !== undefined && latitude !== null && latitude !== undefined && longitude !== null && longitude !== undefined) {
            const isMasjidAdded = g_cookies.isMasjidAdded;
            const masjidListByUserId = JSON.parse(localStorage.getItem("ListofAllMasjidByUserId"));
            if (isMasjidAdded === "false" && masjidListByUserId !== null && masjidListByUserId !== undefined) {
                setMasjidListByUserId(masjidListByUserId);
            }
            else {
                GetFollowMasjidList();
            }
        }
        if (latitude !== null && latitude !== undefined && longitude !== null && longitude !== undefined && callScrapingApi === true) {
            GetMasjidList();
            setCallScrapingApi(false)
        }
    }, [g_userId, latitude, longitude])

    useEffect(() => {
        if(searchMasjidName === "" || searchMasjidName.length === 0){
            setIsSearching(false);
        }
    },[searchMasjidName])

    // call when page render after getting longitude and latitude
    const GetMasjidList = () => {
        setLoaderSpinner(true);
        const body = {
            masjidName: masjidName,
            latitude: latitude,
            longitude: longitude
        }
        axios.post(`${BASE_API_URL}/masjid/get_allmasajid`, body, { withCredentials: false })
            .then(res => {
                if (res.status === 200) {
                    setGoogleScrapingList(res.data);
                    setLoaderSpinner(false);
                }
            })
            .catch(err => {
                if (err) {
                    console.log('Found Error In Get All Masjid API' + err);
                }
            })
    }

    const GetAllMasajidFromBD = () => {
        setLoaderSpinner(true);
        axios.get(`${BASE_API_URL}/masjid/get_db_masajid`, { withCredentials: false })
            .then(res => {
                if (res.status === 200 && res.data != null && res.data != undefined && res.data.length > 0) {
                    setMasjidList(res.data);
                    localStorage.setItem("ListofAllMasjid", JSON.stringify(res.data));
                    setGCookie("isMasjidAdded", false);
                    if(googleScrapingList !== null && googleScrapingList !== undefined){
                        setLoaderSpinner(false);
                    }
                }
            })
            .catch(err => {
                if (err) {
                    setLoaderSpinner(false);
                    console.log('Found Error In Get All Masjid API' + err);
                }
            })
    }

    const GetFollowMasjidList = () => {
        if(masjidListByUserId !== null && masjidListByUserId !== undefined){
            setMasjidListByUserId(null);
        }
        setLoaderSpinner(true);
        var body = {
            userId: g_userId,
            latitude: latitude,
            longitude: longitude
        }
        axios.post(`${BASE_API_URL}/account/get_follow_masjid`, body, { withCredentials: false })
            .then((response) => {
                if (response.status === 200 && response.data != null && response.data != undefined && response.data.length > 0) {
                    setMasjidListByUserId(response.data);
                    localStorage.setItem("ListofAllMasjidByUserId", JSON.stringify(response.data));
                    setGCookie("isMasjidAdded", false);
                    if(googleScrapingList !== null && googleScrapingList !== undefined){
                        setLoaderSpinner(false);
                    }
                }
                else if (masjidList === null || masjidList === undefined) {
                    GetAllMasajidFromBD()
                }
            })
    }

    const SearchMasjid = () => {
        setLoaderSpinner(true);
        const body = {
            masjidName: masjidName,
            latitude: latitude,
            longitude: longitude,
            userId: g_userId
        }
        axios.post(`${BASE_API_URL}/masjid/search_masjid`, body, { withCredentials: false })
            .then(res => {
                if (res.status === 200) {
                    if (res.data.length > 0) {
                        setSearchMasjidList(res.data);
                        setLoaderSpinner(false);
                    }
                    else {
                        setLoaderSpinner(false);
                    }
                }
            })
            .catch((error) => {
                setLoaderSpinner(false);
                setIsSearching(false);
            })
    };

    // call when user type on input feild

    const getMasjidName = (event) => {
        setIsSearching(true);
        setMasjidListForSearching([]);
        const value = event.target.value;
        setSearchMasjidName(value)
        setMasjidName(value);
        if (g_userId !== null && g_userId !== undefined && masjidListByUserId !== null && masjidListByUserId !== undefined) {
            setCopyMasjidList([...masjidListByUserId])
        }
        else if (masjidList !== null && masjidList !== undefined) {
            setCopyMasjidList([...masjidList])
        }
        copyMasjidList.forEach((masjid, index) => {
            const masjidName = masjid.masjidName.toLowerCase();
            if (masjidName.includes(value)) {
                masjidListForSearching.push(masjid);
            }
        })
        setSearchMasjidList([...masjidListForSearching]);
    }

    const keyPress = (event) => {
        if ((event.code === "Enter" || event.code === "NumpadEnter") && searchMasjidName != null && searchMasjidName != undefined && searchMasjidName != "") {
            SearchMasjid();
        }
    }

    const KeyDown = (event) => {
        if (event.code === "Backspace") {
            if(g_userId === null || g_userId === undefined){
                setMasjidList([...copyMasjidList]);
            }
            if(g_userId !== null && g_userId !== undefined){
                setMasjidListByUserId([...copyMasjidList]);
            }
        }
        if (event.keyCode === 13) {
            event.preventDefault();
            event.target.blur();
            SearchMasjid();
        }
    }

    const ClearFilter = () => {
        setIsSearching(false);
        const masjidListByUserId = JSON.parse(localStorage.getItem("ListofAllMasjidByUserId"));
        const listofAllMasjid = JSON.parse(localStorage.getItem("ListofAllMasjid"));
        if (searchMasjidName !== null && searchMasjidName !== undefined && searchMasjidName !== "" && copyMasjidList !== null && copyMasjidList !== undefined) {
            if (g_userId !== null && g_userId !== undefined) {
                if (masjidListByUserId) {
                    setMasjidListByUserId(masjidListByUserId);
                }
            }
            else {
                setMasjidList(listofAllMasjid);
            }
            setSearchMasjidName("");
        }
    }

    const InputFeildFocus = () => {
        setShowMobileBottomBar(false);
    }

    const InputFeildBlur = () => {
        setTimeout(() => {
            setShowMobileBottomBar(true);
        }, 500);
    }

    const OpenAddMasjidPage = () => {
        localStorage.setItem("State", 0);
        history.push("/addmymasjid/")
    }

    if (openImageModal === true) {
        window.onclick = function (event) {
            var modalDiv = document.getElementById('modal-div');
            if (event.target === modalDiv) {
                setOpenImageModal(false)
            }
        }
    }

    return (
        <>

            {(openImageModal === true) ?
                <div className="modal" id="modal-div">
                    <div className="modal-content">
                        <div className="padding-15 horizontal-center ">
                            <p className="text-center" style={{ marginBottom: '0rem' }}>You need sign in with google to add your masjid. <br /> Do You want to Sign In ? If Yes, click Sign in button.</p>
                        </div>
                        <div className={screenWidth > 600 ? "padding-bottom-20 horizontal-center" : "padding-bottom-20 flex"}>
                            <button style={{ backgroundColor: "white", border: "1px solid #0000002b", width: "300px" }} className="m-2" ><img src={Google} className="margin-right-10" width="20" height="20" /> Sign up with Google</button>
                            <button className="btn btn-danger m-2" onClick={() => { setOpenImageModal(false); }} >Cancel</button>
                        </div>
                    </div>
                </div> : ""
            }
            {(showMobileBottomBar === true) ?

                <Row>
                    <Col className="padding-right-40" lg={12} md={12} sm={12} xs={12}>
                        <img title="Add New Masjid" className={screenWidth > 600 ? "masjidList-add-masjid-button" : "masjidList-add-masjid-button-for-mobile"} src={AddButtonBlackImage} onClick={OpenAddMasjidPage} width={screenWidth > 960 ? "45" : "35"} height={screenWidth > 960 ? "45" : "35"} />
                        {(showScrollTopButton === true) ?
                            <div onClick={() => { window.scrollTo(0, 0, "smooth") }} className={screenWidth > 600 ? "masjidList-scroll-masjid-button" : "masjidList-scroll-masjid-button-for-mobile"}>
                                <ArrowUpwardIcon sx={{ fontSize: { xs: "35px", md: "40px" }, color: "white", padding: "5px" }} />
                            </div>
                            : null
                        }
                    </Col>
                </Row>
                : null
            }
            <Container>
                <Row className="horizontal-center" style={{ marginTop: screenWidth > 600 ? '100px' : null }}>
                    <Col lg={11} md={10} sm={12} xs={12}>
                        <div className="padding-top-20 padding-bottom-20 flex">
                            <input className="form-control-input-with-icon" type="text" placeholder="Search Masjid.." value={searchMasjidName} onKeyPress={keyPress} onKeyDown={KeyDown} onFocus={InputFeildFocus} onBlur={InputFeildBlur} onChange={getMasjidName} />

                            <button className="search-input-clear-button" >
                                <FontAwesomeIcon className={screenWidth > 600 ? "clear-fontawesome" : "clear-fontawesome-for-mobile"} color={searchMasjidName.length > 0 ? "black" : "white"} icon={faTimes} width="40%" onClick={ClearFilter} />
                            </button>

                            <button className="search-input-search-button" >
                                <FontAwesomeIcon className={screenWidth > 600 ? "search-fontawesome" : "search-fontawesome-for-mobile"} icon={faSearch} width="40%" onClick={SearchMasjid} />
                            </button>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className="horizontal-center">
                    <Col lg={11} md={10} sm={12} xs={12}>
                        <div className="padding-bottom-100 fade-in-translate" >
                            {
                                (masjidList !== null && masjidList !== undefined && isMasajidExist === true && (g_userId === null || g_userId === undefined) && isSearching === false) ?
                                    masjidList.map((masjid, index) => {
                                        return (
                                            <div key={index}>
                                                <MasjidListView setGCookie={setGCookie} setGMasjidData={props.setGMasjidData} masjidData={masjid} setOpenLoader={setOpenLoader} masjidIdForFollow={masjidIdForFollow} setMasjidIdForFollow={setMasjidIdForFollow} masjidName={masjid.masjidName} masjidAddress={masjid.address} profileImage={masjid.profileImage}
                                                    imageSrc={masjid.imageSrc} userId={masjid.userId} g_userId={g_userId} masjidId={masjid.id} isMasjidAdded={masjid.isMasjidAdded} g_userName={g_userName} setGUserId={setGUserId} masjidDataForFollow={masjidIdForFollow === masjid.id ? masjid : undefined}
                                                    screenWidth={screenWidth} latitude={masjid.latitude} setCheckLogin={setCheckLogin} setGUserImageUrl={setGUserImageUrl} g_userImageUrl={g_userImageUrl} setGUID={setGUID}
                                                    longitude={masjid.longitude} fromExplore={'Explore'} setGUserName={setGUserName} onClickAddMasjid={OpenAddMasjidPage} checkLogin={checkLogin}
                                                    isMasjidFollow={masjidIdForFollow === masjid.id && masjid.isMasjidFollow === false ? !masjid.isMasjidFollow : masjid.isMasjidFollow} />
                                            </div>
                                        );
                                    })
                                    : null
                            }
                            {
                                (masjidListByUserId !== null && masjidListByUserId !== undefined && isMasajidExist === true && isSearching === false) ?
                                    masjidListByUserId.map((masjid, index) => {
                                        return (
                                            <div key={index}>
                                                <MasjidListView setGCookie={setGCookie} setGMasjidData={props.setGMasjidData} masjidData={masjid} setOpenLoader={setOpenLoader} masjidIdForFollow={masjidIdForFollow} setMasjidIdForFollow={setMasjidIdForFollow} masjidName={masjid.masjidName} masjidAddress={masjid.address} profileImage={masjid.profileImage}
                                                    imageSrc={masjid.imageSrc} userId={masjid.userId} g_userId={g_userId} masjidId={masjid.id} isMasjidAdded={masjid.isMasjidAdded} g_userName={g_userName} setGUserId={setGUserId} masjidDataForFollow={masjidIdForFollow === masjid.id ? masjid : undefined}
                                                    screenWidth={screenWidth} latitude={masjid.latitude} setCheckLogin={setCheckLogin} setGUserImageUrl={setGUserImageUrl} g_userImageUrl={g_userImageUrl} setGUID={setGUID}
                                                    longitude={masjid.longitude} fromExplore={'Explore'} setGUserName={setGUserName} onClickAddMasjid={OpenAddMasjidPage} checkLogin={checkLogin}
                                                    isMasjidFollow={masjidIdForFollow === masjid.id && masjid.isMasjidFollow === false ? !masjid.isMasjidFollow : masjid.isMasjidFollow} />
                                            </div>
                                        );
                                    })
                                    : null
                            }
                            {
                                (googleScrapingList !== null && googleScrapingList !== undefined && isSearching === false) ?
                                    googleScrapingList.map((masjid, index) => {
                                        return (
                                            <div key={index}>
                                                <MasjidListView setGCookie={setGCookie} setGMasjidData={props.setGMasjidData} masjidData={masjid} setOpenLoader={setOpenLoader} masjidIdForFollow={masjidIdForFollow} setMasjidIdForFollow={setMasjidIdForFollow} masjidName={masjid.masjidName} masjidAddress={masjid.address} profileImage={masjid.profileImage}
                                                    imageSrc={masjid.imageSrc} userId={masjid.userId} g_userId={g_userId} masjidId={masjid.id} isMasjidAdded={masjid.isMasjidAdded} g_userName={g_userName} setGUserId={setGUserId} masjidDataForFollow={masjidIdForFollow === masjid.id ? masjid : undefined}
                                                    screenWidth={screenWidth} latitude={masjid.latitude} setCheckLogin={setCheckLogin} setGUserImageUrl={setGUserImageUrl} g_userImageUrl={g_userImageUrl} setGUID={setGUID}
                                                    longitude={masjid.longitude} fromExplore={'Explore'} setGUserName={setGUserName} onClickAddMasjid={OpenAddMasjidPage} checkLogin={checkLogin}
                                                    isMasjidFollow={masjidIdForFollow === masjid.id && masjid.isMasjidFollow === false ? !masjid.isMasjidFollow : masjid.isMasjidFollow} />
                                            </div>
                                        );
                                    })
                                    : null
                            }
                            {
                                (isSearching === true && searchMasjidList !== null && searchMasjidList !== undefined) ?
                                    searchMasjidList.map((masjid, index) => {
                                        return (
                                            <div key={index}>
                                                <MasjidListView setGCookie={setGCookie} setGMasjidData={props.setGMasjidData} masjidData={masjid} setOpenLoader={setOpenLoader} masjidIdForFollow={masjidIdForFollow} setMasjidIdForFollow={setMasjidIdForFollow} masjidName={masjid.masjidName} masjidAddress={masjid.address} profileImage={masjid.profileImage}
                                                    imageSrc={masjid.imageSrc} userId={masjid.userId} g_userId={g_userId} masjidId={masjid.id} isMasjidAdded={masjid.isMasjidAdded} g_userName={g_userName} setGUserId={setGUserId} masjidDataForFollow={masjidIdForFollow === masjid.id ? masjid : undefined}
                                                    screenWidth={screenWidth} latitude={masjid.latitude} setCheckLogin={setCheckLogin} setGUserImageUrl={setGUserImageUrl} g_userImageUrl={g_userImageUrl} setGUID={setGUID}
                                                    longitude={masjid.longitude} fromExplore={'Explore'} setGUserName={setGUserName} onClickAddMasjid={OpenAddMasjidPage} checkLogin={checkLogin}
                                                    isMasjidFollow={masjidIdForFollow === masjid.id && masjid.isMasjidFollow === false ? !masjid.isMasjidFollow : masjid.isMasjidFollow} />
                                            </div>
                                        );
                                    })
                                    : null
                            }
                            {
                                (isMasajidExist === false && LoaderSpinner === false) ?
                                    <div className="modal">
                                        <div className="modal-content">
                                            <div className="padding-15 horizontal-center ">
                                                <p className="text-center" style={{ marginBottom: '0rem' }}>Some Thing Went Wrong. Kindly Reload Page !</p>
                                            </div>
                                            <div className=" padding-bottom-20 horizontal-center">
                                                <button className="btn btn-success" onClick={() => { window.location.reload() }} >Reload</button>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                            }
                        </div>
                    </Col>
                </Row>
                {
                    (LoaderSpinner === true) ?
                        <div className="horizontal-center padding-top-50 padding-bottom-100">
                            <div>
                                <Loader type="Rings" color="#9a8356" height={80} width={80} />
                                <p className="padding-top-30" style={{ color: "#9a8356" }}>Loading........</p>
                            </div>
                        </div>
                        : null
                }
            </Container>
        </>
    );
}

export default ExploreTab;