import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { LoginFireBase } from '../../context';

export default function AlertDialog(props) {
    const { open, setOpen, bodyText, title, buttonText, setCheckLogin, setGUID, setGUserId, setGUserName } = props;

    const handleClose = async () => {
        if (buttonText === "Close") {
            setOpen(false);
        }
        if (buttonText === "Login") {
            const result = await LoginFireBase();
            if (result.userId && result.uid && result.userName) {
                setCheckLogin(true);
                setGUserId(result.userId);
                setGUserName(result.userName);
                setGUID(result.uid);
                setOpen(false);
            }
        }
    };

    const ClosePopUp = () => {
        // if(buttonText === "Close"){
        setOpen(false);
        // }
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={ClosePopUp}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {bodyText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleClose} >
                        {buttonText}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
