import React, { useState, useEffect } from "react";
import { Container, Row, Col, InputGroup } from "react-bootstrap";
import { NavLink, useParams, useLocation, useHistory } from 'react-router-dom';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './header.css';
import './header.scss';
import '../../scss/font.scss';
import '../../scss/animatable.scss';
import '../../scss/display.scss';
import '../../scss/margin.scss';
import '../../scss/padding.scss';
import '../../scss/size.scss';
import '../../scss/breakpoints.scss';
import '../../scss/buttons.scss';
import axios from "axios";
import EditImgeButton from "../Edit_Image_Button_Component/edit_image_button";
import { BASE_API_URL, BASE_URL_FOR_IMAGE, BASE_API_URL_FOR_SESSION, firebaseConfig } from '../../context.js';
import BackWard from '../../assets/images/backward-Copy.png'
import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import MasjidAvatarWeb from '../../assets/images/_enmasjid-avatar-web.jpg';
import MasjidAvatarMobile from '../../assets/images/masjid-avatar-mobile.jpg';
import { Typography } from "@mui/material";
import BackDropComponent from "../BackDrop_Component/backdrop";
import ScreenSize from "../screen_resize";
import AlertDialog from "../Dialog_Component/dialog";
import LoginDialog from "../Log_In_Component/login_in";

function Header(props) {
  const { screenWidth, setScreenWidth, g_userId, setGUserId, g_masjidData, setGMasjidData, setGUserName, checkLogin, setCheckLogin, setGUserData,
     g_cookies, setGCookie, g_userData, setIsUpdate, isUpdate, updateUserData } = props;
  const param = useParams();
  const location = useLocation();
  const history = useHistory();
  const [showEditButton, setShowEditButton] = useState(false);
  const [profileImage, setProfileImage] = useState("");
  const [imageFromGoogle, setImageofGoogle] = useState("");
  const [updateProfileImage, setUpdateProfileImage] = useState();
  const [isAdmin, setIsAdmin] = useState(false);
  const [masjidName, setMasjidName] = useState();
  const [masjidaddress, setMasjidAddress] = useState();
  const [masjidContact, setMasjidContact] = useState();
  const [deviceType, setDeviceType] = useState();
  const [openLoader, setOpenLoader] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [loginDialog, setLoginDialog] = useState(false);
  let masjidId = param.masjidId;

  useEffect(() => {
    const device_type = localStorage.getItem("device");
    setDeviceType(device_type);
    if (g_masjidData && g_masjidData.profileImage !== "" && g_masjidData.profileImage !== null && g_masjidData.profileImage !== undefined) {
      setProfileImage('en_'+ g_masjidData.profileImage);
    }
    else if (g_masjidData && g_masjidData.imageSrc !== "" && g_masjidData.imageSrc !== null && g_masjidData.imageSrc !== undefined) {
      setImageofGoogle(g_masjidData.imageSrc);
    }
    if(g_masjidData && g_masjidData.userId === g_userId){
      setIsAdmin(true);
    }
    GetMasjidData(g_userId);
    CheckSession();
  }, []);

  useEffect(() => {
    if (g_userId != null && g_userId != undefined) {
      GetUserById(g_userId);
    }
  }, [g_userId, updateUserData])

  useEffect(() => {
    window.scrollTo(0, 0)
    if (location !== null && location !== undefined) {
      if (location.pathname.includes("about")) {
        setShowEditButton(true);
      }
      else {
        setShowEditButton(false);
      }
    }
  }, [location]);

  useEffect(() => {
    if (updateProfileImage !== null && updateProfileImage !== undefined && updateProfileImage !== "") {
      setProfileImage('en_' + updateProfileImage);
    }
  }, [updateProfileImage]);

  const GetUserById = (g_userId) => {
    const body = {
      emailId: g_userId
    };
    axios.post(`${BASE_API_URL}/account/get_user_by_id`, body, { withCredentials: false })
      .then(response => {
        if (response.data !== null && response.data !== undefined) {
          setGUserData(null);
          setGUserData(response.data);
        }
      })
  }

  const CheckSession = () => {
    if (g_userId === null || g_userId === undefined) {
      axios.post(`${BASE_API_URL_FOR_SESSION}/checkSession`)
        .then(response => {
          if (response.data.userId !== null && response.data.userId !== undefined) {
            setGUserId(response.data.userId);
            setGUserName(response.data.userName);
            setCheckLogin(true);
            GetMasjidData(response.data.userId);
          }
          else {
            setCheckLogin(false);
            GetMasjidData(g_userId);
          }
        })
    }
    else {
      setCheckLogin(true);
      // GetMasjidData(g_userId);
    }
  }

  const Login = () => {
    setLoginDialog(true)
  }

  const CreateAccount = (body) => {
    axios.post(`${BASE_API_URL}/account/create`, body, { withCredentials: false })
      .then(response => {
        if(response.status === 200 || response.status === 201){
          setOpenLoader(false);
        }
      })
  }


  const CreateLoginSessionAndContinue = (email, name, uid) => {
    axios.post(`${BASE_API_URL_FOR_SESSION}/storeSession/${email}/${name}/${uid}`)
      .then(response => {
        if (response.data.userId !== null && response.data.userId !== undefined) {
          console.log('Session Created')
        }
      })
  }

  const Logout = () => {
    setOpenLoader(true);
    axios.post(`${BASE_API_URL_FOR_SESSION}/destroySession`)
      .then(response => {
        if (response.data === true) {
          localStorage.setItem("Domain", "Masjid");
          setGUserId(null);
          setGUserName(null);
          setCheckLogin(false);
          setOpenLoader(false);
          localStorage.removeItem("ListofAllMasjidByUserId");
          localStorage.removeItem("ListofAllMasjid");
        }
      })
  }

  const GetMasjidData = (userId) => {
    if (masjidId !== null && masjidId !== undefined) {
      const body = {
        masjidId: masjidId
      }
      axios.post(`${BASE_API_URL}/masjid/get_masjid_by_id`, body, { withCredentials: false })
        .then(response => {
          setGMasjidData(response.data);
          setMasjidName(response.data.masjidName)
          setMasjidAddress(response.data.City + ' ' + response.data.Country);
          setMasjidContact(response.data.Contact);
          if (response.data.profileImage !== "" && response.data.profileImage !== null && response.data.profileImage !== undefined) {
            setProfileImage('en_'+ response.data.profileImage);
          }
          else if (response.data.imageSrc !== "" && response.data.imageSrc !== null && response.data.imageSrc !== undefined) {
            setImageofGoogle(response.data.imageSrc);
          }
          if (response.data.userId === userId) {
            setIsAdmin(true);
          }
        })
        setOpenLoader(false)
    }
  }

  return (
    <>
    <ScreenSize screenWidth={screenWidth} setScreenWidth={setScreenWidth} setIsUpdate={setIsUpdate} isUpdate={isUpdate} />
    <BackDropComponent open={openLoader} />
    <AlertDialog open={openDialog} setOpen={setOpenDialog} title="Oops!" bodyText="Something Went Wrong. Sign-in Popup has been Blocked. Please Check Your Browser Setting." buttonText="Close" />
    <LoginDialog loginDialog={loginDialog} setLoginDialog={setLoginDialog} g_userId={g_userId} screenWidth={screenWidth} />
      <div className="pointer" style={{ display: 'flex', width: '100%', backgroundColor: 'rgba(52, 52, 52, 0.7)', alignItems: 'center', position: 'fixed', zIndex: '2', left: screenWidth < 600 ? '0px' : '0px', top: '0px', float: 'right', color: 'white', }}>
        <div style={{ margin: '12px', display: 'flex', alignItems: 'center', justifyContent: (deviceType === "mobile") ? 'center' : 'space-between', borderRadius: '24px', width: '100%' }}>
          {(deviceType === "mobile") ?
          <>
            <img src={BackWard} width="30px" height="30px" onClick={() => { setGMasjidData(null); setGUserData(null); history.push(`/`) }} style={{position: 'absolute', left : '10px'}} />
            <Typography variant="h4" sx={{fontWeight : 600, fontSize : '18px'}} >Masjid List Tab</Typography>
          </>
             :
            <>
              <img src={BackWard} width="30px" height="30px" onClick={() => { setGMasjidData(null); setGUserData(null); history.push(`/`) }} />
              <button className="header-logout-button btn" onClick={checkLogin === true ? Logout : Login}>{(checkLogin === true) ? 'Log Out' : 'Log In'}</button>
            </>
          }
        </div>
      </div>
      <Row style={{ marginLeft: '0px', marginRight: '0px' }}>
        <Col style={{ paddingLeft: '0px', paddingRight: '0px' }} >
          <div className="header-image" 
          style={{ backgroundImage: profileImage ? `url(${BASE_URL_FOR_IMAGE}/${profileImage})` : imageFromGoogle ? `url(${imageFromGoogle})` :  `url(${screenWidth > 600 ? MasjidAvatarWeb : MasjidAvatarMobile})` }} >
            {(g_masjidData !== null && g_masjidData !== undefined && screenWidth <= 600) ?
              <div className="vertical-end" style={{ minHeight: '395px' }}>
                <Row style={{ marginLeft: '0px', marginRight: '0px', width: '100%', backgroundImage: 'linear-gradient(rgba(252, 249, 244, 0) 0%, rgba(255, 255, 255, 0.8) 0%)' }}>
                  <Col className="horizontal-center" sm={12} xs={12}>
                    <div>
                      <h6 className="mt-1 mb-1 text-center font-weight-700 font-size-20 text-shadow-grey font-color-black" >{g_masjidData.masjidName}</h6>
                      <p className="mb-0 text-center font-weight-700 font-size-16 font-color-grey">{g_masjidData.City + ' ' + g_masjidData.Country}</p>
                      <p className="text-center font-weight-700 font-size-16 font-color-dark-black mb-1">Contact No: {g_masjidData.Contact}</p>
                    </div>
                  </Col>
                </Row>
              </div> : null}
          </div>
          {
            (showEditButton === true && isAdmin === true && checkLogin === true) ?
              <div>
                <EditImgeButton setGCookie={setGCookie} g_cookies={g_cookies} masjidId={masjidId} updateProfileImage={updateProfileImage} setUpdateProfileImage={setUpdateProfileImage} screenWidth={screenWidth} />
              </div> : ""
          }
        </Col>
      </Row>
      <div className="navbar-background">
        <Container>
          <Row className="horizontal-center" >
            <Col lg={3}  />
            <Col lg={2} md={3} sm={4} xs={4} className="horizontal-center" >
              <NavLink exact className={screenWidth > 600 ? "Nav_link font-size-18 font-weight-600" : "Nav_link font-size-14 font-weight-bold "} activeClassName="activeRoute" to={`/about/${param.masjidId}`}>About</NavLink>
            </Col>
            <Col lg={2} md={3} sm={4} xs={4} className="horizontal-center" >
              <NavLink exact className={screenWidth > 600 ? "Nav_link font-size-18 font-weight-600" : "Nav_link font-size-14 font-weight-bold "} activeClassName="activeRoute" to={`/event/${param.masjidId}`}>Events</NavLink>
            </Col>
            <Col lg={2} md={3} sm={4} xs={4} className="horizontal-center" >
              <NavLink exact className={screenWidth > 600 ? "Nav_link font-size-18 font-weight-600" : "Nav_link font-size-14 font-weight-bold "} activeClassName="activeRoute" to={`/member/${param.masjidId}`}>Followers</NavLink>
            </Col>
            <Col lg={3}  />
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Header;