import axios from "axios";
import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider, signOut } from "firebase/auth";

// for server  backend

export const BASE_API_URL = '';
export const BASE_URL_FOR_IMAGE = "https://masjid.quranmajeed.com/upload";
export const BASE_API_URL_FOR_SESSION = 'https://quranmajeed.com';

// for local Backend

// export const BASE_API_URL = "http://10.0.1.137:8080";
// export const BASE_URL_FOR_IMAGE = "http://10.0.1.137:8080/get";
// export const BASE_API_URL_FOR_SESSION = "http://localhost:8000";

export const firebaseConfig = {
    // masjid platform firebase configuration
    // apiKey: "AIzaSyB7oA3H2oESlzSO1HjaunYY3EMKsnr3sxk",
    // authDomain: "masjid-platform-f03f7.firebaseapp.com",
    // databaseURL: "https://masjid-platform-f03f7-default-rtdb.firebaseio.com",
    // projectId: "masjid-platform-f03f7",
    // storageBucket: "masjid-platform-f03f7.appspot.com",
    // messagingSenderId: "111915037474",
    // appId: "1:111915037474:web:9fdf9fa28e01de7ead2b7b",
    // measurementId: "G-LEHYCDHXP4"
   
    // quran majeed firebase configuration
    apiKey: "AIzaSyDV2MqUBcADGGSSML3og41qvtb6o9PSHhc",
    authDomain: "quran-majeed-761c6.firebaseapp.com",
    databaseURL: "https://quran-majeed-761c6.firebaseio.com",
    projectId: "quran-majeed-761c6",
    storageBucket: "quran-majeed-761c6.appspot.com",
    messagingSenderId: "780653622612",
    appId: "1:780653622612:web:7cf9e2889752584e1304c1",
    measurementId: "G-WC1FWBBLMS"
};


export const LoginFireBase = async  () => {
    return new Promise(promise => {
        const app = initializeApp(firebaseConfig);
        const auth =  getAuth(app);
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider)
            .then(result => {
                let email = result.user.email;
                let name = result.user.displayName;
                let uid = result.user.uid;
                let first_lastName = name.split(' ');
                let profilePicture = result.user.photoURL;
                if (email !== null && email !== undefined && name !== null && name !== undefined) {
                    const body = {
                        id  : email,
                        email : email,
                        uid : uid,
                        firstName: first_lastName[0],
                        lastName: first_lastName[1],
                        profilePicture: profilePicture
                    }
                    CreateAccountForMasjid(body);
                    const sessionData = CreateLoginSessionAndContinue(email, name, uid);
                    promise(sessionData);
                }
            })
            .catch(error => {
                promise(error)
            })
    })
}

export const LoginWithGoogle = async  () => {
    return new Promise(promise => {
        const app = initializeApp(firebaseConfig);
        const auth =  getAuth(app);
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider)
            .then(result => {
                let email = result.user.email;
                let name = result.user.displayName;
                let uid = result.user.uid;
                if (email !== null && email !== undefined && name !== null && name !== undefined) {
                    CreateLoginSessionAndContinue(email, name, uid);
                    promise(result);
                }
            })
            .catch(error => {
                promise(error)
            })
    })
}

const CreateLoginSessionAndContinue = (email, name, uid) => {
    return new Promise(promise => {
        axios.post(`${BASE_API_URL_FOR_SESSION}/storeSession/${email}/${name}/${uid}`)
        .then(response => {
            if (response.data.userId !== null && response.data.userId !== undefined) {
                localStorage.setItem("isLogin", true);
                promise(response.data);
            }
        })
    })
}

export const LogoutFireBase = () => {
    return new Promise(promise => {
        const app = initializeApp(firebaseConfig);
        const auth =  getAuth();
        signOut(auth)
        .then(response => {
        })
        .catch(error => {
            console.log(error);
        });
        axios.post(`${BASE_API_URL_FOR_SESSION}/destroySession`)
        .then(response => {
            if(response.data === true){
                localStorage.setItem("isLogin", false);
                localStorage.removeItem("ListofAllMasjidByUserId");
                localStorage.removeItem("ListofAllMasjid");
                promise(response.data);
            }
        })
    })
}

export const CreateAccountForMasjid = (body) => {
    return new Promise(promise => {
        axios.post(`${BASE_API_URL}/account/create`, body, { withCredentials: false })
        .then(response => {
            promise({
                status : response.status,
                email : response.data.email
            });
        })
        .catch((error) => {
            promise(error)
        })
    })
}

export const GetUserById = (body) => {
    return new Promise(promise => {
        axios.post(`${BASE_API_URL}/account/get_user_by_id`, body, { withCredentials: false })
        .then(response => {
            promise({
                status : response.status,
                data : response.data
            });
        })
        .catch((error) => {
            promise(error)
        })
    })
}
