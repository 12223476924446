import React, { useState, useEffect } from "react";
import '../../scss/font.scss';
import '../../scss/animatable.scss';
import '../../scss/display.scss';
import '../../scss/margin.scss';
import '../../scss/padding.scss';
import '../../scss/size.scss';
import '../../scss/breakpoints.scss';
import '../../scss/buttons.scss';
import axios from "axios";
import { useParams } from "react-router";
import { BASE_API_URL } from '../../context.js';
import Camera from '../../assets/images/camera.png'
import Checked from '../../assets/images/checked.png'
import Resizer from "react-image-file-resizer";
import Compressor from 'compressorjs';

function EditImgeButton(props) {

  const { setUpdateProfileImage, screenWidth, masjidId, g_cookies, setGCookie  } = props;
  const param = useParams();
  const [isParamUndefined, setIsParamUndefined] = useState(false);
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState("");

  const GetElementInputById = () => {
    const fileTag = document.getElementById("uploadimageFile");
    fileTag.click();
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1024,
        720,
        "JPEG",
        80,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const CompressMasjidProfileImage = (image, width, height) => {
    return new Promise(promise => {
      new Compressor(image, {
        quality: 0.8,
        maxWidth: width,
        maxHeight: height,
        success: (compressedResult) => {
          promise(compressedResult);
        }
      });
    })
  }

  const UploadImage = async (e) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
    const date = new Date().toISOString();
    const timeStamp = Date.parse(date);
    let imageType = e.target.files[0].type;
    imageType = imageType.split('/');
    imageType = imageType[1];
    let imageName = timeStamp + '.' + imageType; 
    const image = e.target.files[0];
    const formData = new FormData();
    let width;
    let height;
    for (var x = 0; x < 3; x++) {
      if(x == 1){
        width = 1600;
        height = 1200;
      }
      if(x == 2){
        width = 100;
        height = 100;
      }
      let compressImage = await CompressMasjidProfileImage(image, width, height );
      if(x == 0){
        formData.append('masjidImage', compressImage, imageName );
      }
      if(x == 1){
        formData.append('masjidImage', compressImage, 'en_'+ imageName );
      }
      if(x == 2){
        formData.append('masjidImage', compressImage, 'tbn_'+ imageName );
      }
      if(x == 2) {
        formData.append('fileName', imageName);
        formData.append('masjidId', masjidId);
        axios.post(`${BASE_API_URL}/about/upload_image`, formData, { withCredentials: false })
        .then(res => {
          setUpdateProfileImage(res.data);
          setGCookie("isMasjidAdded", true);
        })
      }
    }
  }

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', position: 'absolute', top: screenWidth > 600 ? '300px' : '55px', right: '0px', float: 'right', color: 'white', }}>
        <div style={{ background: 'rgba(52, 52, 52, 0.7)', height: '25px', display: 'flex', alignItems: 'center', borderRadius: '11px', borderTopRightRadius: '0px', borderBottomRightRadius: '0px' }} >
          <button className="btn font-color-white" >
            Get Your Masjid Verified
          </button>
        </div>
        <div style={{ background: 'white', height: '34px', width: '34px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '24px', marginLeft: '-5%' }}>
          <img src={Checked} width="32px" height="32px" />
        </div>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', position: 'absolute', right: '0px', top: screenWidth > 600 ? '350px' : '265px', float: 'right', color: 'white', }}>
        <div style={{ background: 'rgba(52, 52, 52, 0.7)', height: '25px', display: 'flex', alignItems: 'center', borderRadius: '11px', borderTopRightRadius: '0px', borderBottomRightRadius: '0px' }} >
          <button className="btn font-color-white" onClick={GetElementInputById} >
            Edit Image
          </button>
        </div>
        <div style={{ background: 'white', height: '34px', width: '34px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '24px', marginLeft: '-5%' }}>
          <img src={Camera} width="20px" height="20px" />
        </div>
        <input type="file" id="uploadimageFile" onChange={UploadImage} hidden />
      </div>
    </>
  );
}

export default EditImgeButton;