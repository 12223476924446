import React, { useState, useEffect, useRef } from "react";
import { useParams, useLocation } from "react-router";
import 'bootstrap/dist/css/bootstrap.min.css';
import './masjid_list.scss';
import '../../scss/font.scss';
import '../../scss/animatable.scss';
import '../../scss/display.scss';
import '../../scss/margin.scss';
import '../../scss/padding.scss';
import '../../scss/size.scss';
import '../../scss/breakpoints.scss';
import '../../scss/buttons.scss';
import ExploreTab from './explore';
import MyMasjidPage from "./myMasjid";
import NearByEvent from "./nearByEvents";
import StaticHeader from "../Static_Header_Component/static_header";
import NearbyTabImage from '../../assets/images/nearby.png';
import MyMasjidTabImage from '../../assets/images/mosque.png';
import ExplorerTabImage from '../../assets/images/explorer.png';
import axios from "axios";
import { BASE_API_URL_FOR_SESSION, BASE_API_URL } from '../../context';
import BackDropComponent from "../BackDrop_Component/backdrop";
import { Row, Col } from "react-bootstrap";
import ScreenSize from "../screen_resize";

function MasjidLists(props) {
    const { windwoHeight, screenWidth, setScreenWidth, g_userId, setGUserId, g_userName, setGUserName, setCheckLogin, checkLogin, setGUserImageUrl, g_userImageUrl, setGUID,
        g_latitude, setGLatitude, g_longitude, setGLongitude, g_uid, setIsUpdate, isUpdate, g_cookies, setGCookie, setPageLoaded, pageLoaded } = props;
    const masjidListViewHeight = windwoHeight / 2;
    const bottomBarRef = useRef();
    const params = useParams();
    const location = useLocation();
    const [showMobileBottomBar, setShowMobileBottomBar] = useState(true);
    const [exploreTab, setExploreTab] = useState(true);
    const [nearByEventTab, setNearByEventTab] = useState(false);
    const [tabTranslatePositionForMobile, setTabTransateXPositionForMobile] = useState();
    const [tabTranslatePositionForWeb, setTabTransateXPositionForWeb] = useState();
    const [activeTab, setActiveTab] = useState(localStorage.getItem("TabName") ? localStorage.getItem("TabName") : 'Explorer');
    const [activeTabImage, setActiveTabImage] = useState(ExplorerTabImage);
    const [tabsHeaderFixed, setTabsHeaderFixed] = useState(false);
    const [tabsHeaderOffset, setTabsHeaderoffset] = useState();
    const [checkLatLng, setCheckLatLng] = useState(true);
    const [openImageModal, setOpenImageModal] = useState(false);
    const [showLoginButton, setShowLoginButton] = useState(false);
    const [openLoader, setOpenLoader] = useState(false);
    const [showScrollTopButton, setScrollTopButton] = useState(false);

    useEffect(() => {
        const deviceType = localStorage.getItem("device");
        if (deviceType === null || deviceType === undefined) {
            if (location !== null && location !== undefined) {
                if (location.pathname.includes('home')) {
                    localStorage.setItem("device", "mobile");
                    setShowLoginButton(false);
                }
                else {
                    localStorage.setItem("device", "web");
                    setShowLoginButton(true);
                }
            }
        }
        else {
            if (deviceType === "web") {
                setShowLoginButton(true);
            }
            if (deviceType === "mobile") {
                setShowLoginButton(false);
            }
        }
        if (location.pathname.includes('home')) {
            localStorage.setItem("isWebview", true);
            if (params.uid !== null && params.uid !== undefined && params.uid !== "null" && params.uid !== "undefined") {
                setGUID(params.uid)
            }
            if (params.user_email !== "null" && params.user_email !== "undefined" && params.user_email !== null && params.user_email !== undefined) {
                let userEmail = params.user_email;
                let userName = params.user_name;
                let userUid = params.uid;
                let userImage = params.user_image;
                userImage = userImage.replaceAll('|', '/')
                let latitude = Number(params.latitude);
                let longitude = Number(params.longitude);
                setGUserId(userEmail);
                setGUserName(userName);
                setGUID(userUid);
                setGUserImageUrl(userImage);
                setGLatitude(latitude);
                setGLongitude(longitude);
                if (latitude === null || latitude === undefined || longitude === null || longitude === undefined) {
                    setCheckLatLng(false);
                }
                else {
                    setCheckLatLng(true);
                }
                setCheckLogin(true);
                CreateLoginSessionAndContinue(userEmail, userName, userUid)
                userName = userName.split(' ');
                const body = {
                    id: userEmail,
                    firstName: (userName[0] === null || userName[0] === undefined || userName[0] !== "null" || userName[0] !== "undefined") ? "Anonymous" : userName[0],
                    lastName: (userName[1] === null || userName[1] === undefined || userName[1] === "null" || userName[1] === "undefined") ? "Anonymous" : userName[1],
                    email: userEmail,
                    uid: userUid,
                    profilePicture: (userImage === null || userImage === undefined || userImage === "null" || userImage === "undefined") ? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUh3k9PBWmPFALGyMwCNsaukw4H-y-B2LxHA&usqp=CAU" : userImage
                }
                CreateAccount(body)
            }
        }
        else {
            if ((g_latitude === null || g_latitude === undefined) && (g_longitude === null || g_longitude === undefined)) {
                navigator.geolocation.getCurrentPosition((position) => {
                    const latitude = position.coords.latitude;
                    const longitude = position.coords.longitude;
                    setGLongitude(longitude);
                    setGLatitude(latitude)
                });
            }
            CheckSession();
        }
    }, []);

    useEffect(() => {
        if (screenWidth > 600 && screenWidth !== undefined && screenWidth !== null) {
            if (activeTab === "Nearby Events") {
                setTabTransateXPositionForWeb('translateX(-90%)');
                setExploreTab(false);
                setNearByEventTab(true);
                setActiveTab('Nearby Events');
            }
            if (activeTab === "Explorer") {
                setTabTransateXPositionForWeb('translateX(0%)');
                setExploreTab(true);
                setNearByEventTab(false);
                setActiveTab('Explorer');
            }
            if (activeTab === "My Masajid") {
                if (screenWidth > 400 && screenWidth < 768) {
                    setTabTransateXPositionForWeb('translateX(87%)');
                }
                else if (screenWidth >= 768 && screenWidth < 1430) {
                    setTabTransateXPositionForWeb('translateX(85%)');
                }
                else if (screenWidth > 1430) {
                    setTabTransateXPositionForWeb('translateX(80%)');
                }
                setExploreTab(false);
                setNearByEventTab(false);
                setActiveTab('My Masajid');
            }
        }
        if (screenWidth < 600 && screenWidth !== undefined && screenWidth !== null) {
            const lastActiveTab = localStorage.getItem("TabName");
            if (lastActiveTab !== null && lastActiveTab !== undefined) {
                ChangeTab(lastActiveTab);
            }
            else {
                ChangeTab(activeTab);
            }
        }
    }, [screenWidth])

    const CreateAccount = (body) => {
        axios.post(`${BASE_API_URL}/account/create`, body, { withCredentials: false })
            .then(response => {
                if (response.status === 200) {
                    console.log('Account Created');
                }
            })
    }

    const CheckSession = () => {
        if (g_userId === null || g_userId === undefined) {
            axios.post(`${BASE_API_URL_FOR_SESSION}/checkSession`)
                .then(response => {
                    if (response.data.userId !== null && response.data.userId !== undefined) {
                        setGUserId(response.data.userId);
                        setGUserName(response.data.userName);
                        setGUID(response.data.uid)
                        setCheckLogin(true);
                        setIsUpdate(!isUpdate)
                        let name = response.data.userName;
                        name = name.split(' ');
                        const body = {
                            id: response.data.userId,
                            firstName: name[0] ? name[0] : "Anonymous",
                            lastName: name[1] ? name[1] : "Anonymous",
                            email: response.data.userId,
                            uid: response.data.uid,
                            profilePicture: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUh3k9PBWmPFALGyMwCNsaukw4H-y-B2LxHA&usqp=CAU"
                        }
                        CreateAccount(body)
                    }
                })
        }
    }

    const CreateLoginSessionAndContinue = (email, name, uid) => {
        axios.post(`${BASE_API_URL_FOR_SESSION}/storeSession/${email}/${name}/${uid}`)
            .then(response => {
                if (response.data.userId !== null && response.data.userId !== undefined) {
                    console.log('Session Created')
                }
            })
    }

    const handleScroll = () => {
        if (tabsHeaderOffset === null || tabsHeaderOffset === undefined) {
            const tabsHeader = document.getElementById("tabsHeader");
            if (tabsHeader !== null && tabsHeader !== undefined) {
                const tabsHeaderoffSetTop = tabsHeader.offsetHeight + 200;
                setTabsHeaderoffset(tabsHeaderoffSetTop);
            }
        }
        const position = window.pageYOffset;
        if (position > tabsHeaderOffset) {
            setTabsHeaderFixed(true);
        }
        else {
            setTabsHeaderFixed(false);
        }
        const scrollHeight = windwoHeight;
        if (position > scrollHeight) {
            setScrollTopButton(true);
        }
        if (position < scrollHeight) {
            setScrollTopButton(false);
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    });

    const ChangeTab = (tabName) => {
        localStorage.setItem("TabName", tabName);
        if (screenWidth < 600) {
            if (tabName === "Nearby Events") {
                const nearby = document.getElementById('nearbyTab');
                const offwidth = nearby.offsetLeft - 22;
                setTabTransateXPositionForMobile(`translateX(${offwidth}px)`);
                setExploreTab(false);
                setNearByEventTab(true);
                setTimeout(() => {
                    setActiveTab('Nearby Events');
                    setActiveTabImage(NearbyTabImage);
                }, 250);
            }
            if (tabName === "Explorer") {
                const explorert = document.getElementById('explorerTab');
                const offwidth = explorert.offsetLeft - 50;
                setTabTransateXPositionForMobile(`translateX(${offwidth}px)`);
                setExploreTab(true);
                setNearByEventTab(false);
                setTimeout(() => {
                    setActiveTab('Explorer');
                    setActiveTabImage(ExplorerTabImage);

                }, 250);
            }
            if (tabName === "My Masajid") {
                const myMasjid = document.getElementById('myMasjidTab');
                const offwidth = myMasjid.offsetLeft - 45;
                setExploreTab(false);
                setNearByEventTab(false);
                setTabTransateXPositionForMobile(`translateX(${offwidth}px)`);
                setTimeout(() => {
                    setActiveTab('My Masajid');
                    setActiveTabImage(MyMasjidTabImage);
                }, 250);
            }
        }
        else {
            if (tabName === "Nearby Events") {
                setTabTransateXPositionForWeb('translateX(-90%)');
                setExploreTab(false);
                setNearByEventTab(true);
                setTimeout(() => {
                    setActiveTab('Nearby Events');
                }, 250);
            }
            if (tabName === "Explorer") {
                setTabTransateXPositionForWeb('translateX(0%)');
                setExploreTab(true);
                setNearByEventTab(false);
                setTimeout(() => {
                    setActiveTab('Explorer');

                }, 250);
            }
            if (tabName === "My Masajid") {
                if (screenWidth > 600 && screenWidth < 768) {
                    setTabTransateXPositionForWeb('translateX(87%)');
                }
                else if (screenWidth >= 768 && screenWidth < 1430) {
                    setTabTransateXPositionForWeb('translateX(85%)');
                }
                else if (screenWidth > 1430) {
                    setTabTransateXPositionForWeb('translateX(80%)');
                }
                setExploreTab(false);
                setNearByEventTab(false);
                setTimeout(() => {
                    setActiveTab('My Masajid');
                }, 250);
            }
        }
    }

    return (
        <div style={{ minHeight: windwoHeight }}>
            <BackDropComponent open={openLoader} />
            <ScreenSize setIsUpdate={setIsUpdate} isUpdate={isUpdate} showMobileBottomBar={showMobileBottomBar} setShowMobileBottomBar={setShowMobileBottomBar} screenWidth={screenWidth} setScreenWidth={setScreenWidth} />
            {(openImageModal === true) ?
                <div className="modal" id="modal-div">
                    <div className="modal-content">
                        <div className="padding-15 horizontal-center ">
                            <p className="text-center" style={{ marginBottom: '0rem' }}> Please Allow Safari to get your current location for your nearby masjid.</p>
                        </div>
                        <div className={screenWidth > 600 ? "padding-bottom-20 horizontal-center" : "padding-bottom-20 flex"}>
                            {/* <button style={{ backgroundColor: "white", border: "1px solid #0000002b", width: "300px" }} className="m-2" >Sign up with Google</button> */}
                            <button className="btn btn-danger m-2" onClick={() => { setOpenImageModal(false); }} >Cancel</button>
                        </div>
                    </div>
                </div> : ""
            }
            <StaticHeader g_userId={g_userId} screenWidth={screenWidth} setIsUpdate={setIsUpdate} isUpdate={isUpdate} setGUserId={setGUserId} setGUID={setGUID} g_userName={g_userName} lat_lng={checkLatLng} showLoginButton={showLoginButton}
                setGUserName={setGUserName} setCheckLogin={setCheckLogin} setGUserImageUrl={setGUserImageUrl} g_userImageUrl={g_userImageUrl} />

            {(screenWidth > 600) ?
                <Row className="masjid-list-page-top-bar">
                    <Col lg="12" md={12} sm={12} xs={0}>
                        <div className={tabsHeaderFixed === true ? "fixed-tabs-header" : "absolute-tabs-header"}>
                            <div className="horizontal-center vertical-center padding-20" id="tabsHeader">
                                <button className='inactive' style={{ zIndex: '1' }} onClick={() => { ChangeTab('Nearby Events') }}>Nearby Events</button>
                                <button className='inactive' style={{ margin: '0% -2%', zIndex: '2' }} onClick={() => { ChangeTab('Explorer') }} >Explorer</button>
                                <button className='inactive' onClick={() => { ChangeTab('My Masajid') }} style={{ zIndex: '1' }}>My Masajid</button>
                                <div className='active vertical-center horizontal-center' style={{ transform: tabTranslatePositionForWeb, zIndex: '3' }}>
                                    <div style={{ display: 'grid' }}>
                                        <button className="active-tab" style={{ backgroundColor: '#E1C794', border: 'none' }} >{activeTab}</button>
                                        <span className="tab-header-span"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                :
                (showMobileBottomBar === true) ?
                    <Row>
                        <Col>
                            <div className="" ref={bottomBarRef}>
                                <div className="masjidList-tabs-header-for-mobile masjid-list-page-bottom-bar horizontal-between padding-top-10 padding-right-20 padding-left-20 padding-bottom-22">
                                    <div className="padding-top-10" id="nearbyTab" onClick={() => { ChangeTab('Nearby Events') }}>
                                        <div className="horizontal-center">
                                            <img src={NearbyTabImage} width="24" height="24" />
                                        </div>
                                        <button className='masjidList-inactive-tab'>Nearby Events</button>
                                    </div>
                                    <div className="padding-top-10" id="explorerTab" onClick={() => { ChangeTab('Explorer') }} >
                                        <div className="horizontal-center">
                                            <img src={ExplorerTabImage} width="24" height="24" />
                                        </div>
                                        <button className='masjidList-inactive-tab'>Explorer</button>
                                    </div>
                                    <div className="padding-top-10" id="myMasjidTab" onClick={() => { ChangeTab('My Masajid') }}>
                                        <div className="horizontal-center">
                                            <img src={MyMasjidTabImage} width="24" height="24" />
                                        </div>
                                        <button className='masjidList-inactive-tab'>My Masajid</button>
                                    </div>

                                    <div className='masjidList-active-tab-div vertical-center horizontal-center' style={{ transform: tabTranslatePositionForMobile }}>
                                        <div className="padding-top-5" id="myMasjidTab">
                                            <div className="horizontal-center">
                                                <img src={activeTabImage} width="24" height="24" />
                                            </div>
                                            <button className='masjidList-active-tab'>{activeTab}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    : null}

            <div>
                {
                    (exploreTab === true && nearByEventTab === false) ?
                        <ExploreTab setPageLoaded={setPageLoaded} pageLoaded={pageLoaded} isUpdate={isUpdate} setIsUpdate={setIsUpdate} setGCookie={setGCookie} g_cookies={g_cookies} showScrollTopButton={showScrollTopButton}
                            setShowMobileBottomBar={setShowMobileBottomBar} screenWidth={screenWidth} g_userId={g_userId} masjidListViewHeight={masjidListViewHeight} setGMasjidData={props.setGMasjidData} setGUserId={setGUserId} g_userName={g_userName} setOpenLoader={setOpenLoader}
                            setCheckLogin={setCheckLogin} checkLogin={checkLogin} setGUID={setGUID} setGUserName={setGUserName} setTabsHeaderFixed={setTabsHeaderFixed} latitude={g_latitude}
                            longitude={g_longitude} setGUserImageUrl={setGUserImageUrl} g_userImageUrl={g_userImageUrl} lat_lng={checkLatLng} showMobileBottomBar={showMobileBottomBar} />
                        : null
                }
                {
                    (exploreTab === false && nearByEventTab === false) ?
                        <MyMasjidPage setGCookie={setGCookie} g_cookies={g_cookies} screenWidth={screenWidth} g_userId={g_userId} setGUserId={setGUserId} setCheckLogin={setCheckLogin} g_userImageUrl={g_userImageUrl} setGMasjidData={props.setGMasjidData}
                            g_userName={g_userName} setGUserName={setGUserName} checkLogin={checkLogin} setGUserImageUrl={setGUserImageUrl} setGUID={setGUID} setOpenLoader={setOpenLoader} />
                        : null
                }
                {
                    (exploreTab === false && nearByEventTab === true) ?
                        <NearByEvent latitude={g_latitude} longitude={g_longitude} screenWidth={screenWidth} setOpenLoader={setOpenLoader} setShowMobileBottomBar={setShowMobileBottomBar} />
                        : null
                }
            </div>
        </div>
    );
}
export default MasjidLists;