import React, { useEffect, useState } from "react";
import { Container, Row, Col, Alert } from "react-bootstrap";
import Collapse from 'react-bootstrap/Collapse';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './event.css'
import './event_page.scss';
import '../../scss/font.scss';
import '../../scss/animatable.scss';
import '../../scss/display.scss';
import '../../scss/margin.scss';
import '../../scss/padding.scss';
import '../../scss/size.scss';
import '../../scss/breakpoints.scss';
import '../../scss/buttons.scss';
import axios from "axios";
import TimePicker from "../TimePicker_Component/time_picker";
import DatePicker from "../DatePicker_Component/date_picker";
import toDate from "date-fns/toDate";
import Loader from "react-loader-spinner";
import  {BASE_API_URL} from '../../context.js';

function EventDetails(props) {

    const { masjidId, onSelect, eventDetail, isUserOrAdmin, screenWidth, userData, g_userId, g_masjidData, g_userData } = props;
    const [masjidName, setMasjidName] = useState();
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [startDateFormDB, setStartDateFromDB] = useState();
    const [endDateFormDB, setEndDateFromDB] = useState();
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [startTimeFormDB, setStartTimeFromDB] = useState();
    const [endTimeFormDB, setEndTimeFromDB] = useState();
    const [isReadOnly, setIsReadOnly] = useState(false);
    const [searchEventByMasjidName, setSearchEventNameFeild] = useState(); 
    const [createEventForm, setCreateEventForm] = useState({
        id: '',
        eventName: '',
        description: '',
        category: '1',
        repetition: '1'
    });
    const [openAlert, setOpenAlert] = useState(false);
    const [responseStatus, setResponseStatus] = useState("");
    const [responseMessage, setResponseMessage] = useState("");
    const [isMasjidDetailExist, setIsMasjidDetailExist] = useState(false);
    
    useEffect(() => {
        if(isUserOrAdmin !== null && isUserOrAdmin !== undefined && isUserOrAdmin === true){
            setIsReadOnly(false);
        }
        else{
            setIsReadOnly(true);
        }
        if (g_masjidData !== null && g_masjidData !== undefined) {
            if(g_masjidData.masjidName !== null && g_masjidData.masjidName !== undefined){
                const masjid_name = g_masjidData.masjidName;
                const search_masjid_name_feild = g_masjidData.search_masjid;
                setSearchEventNameFeild(search_masjid_name_feild);
                setMasjidName(masjid_name);
                setIsMasjidDetailExist(true);
            }
        }
        if(eventDetail !== null && eventDetail !== undefined){
            let startDateTimeFromDB = Date.parse(eventDetail.startDateTime);
            let endDateTimeFromDB = Date.parse(eventDetail.endDateTime);
            startDateTimeFromDB = toDate(startDateTimeFromDB);
            endDateTimeFromDB = toDate(endDateTimeFromDB);
            setStartDate(startDateTimeFromDB);
            setStartTime(startDateTimeFromDB);
            setEndDate(endDateTimeFromDB);
            setEndTime(endDateTimeFromDB);

            setCreateEventForm({
                id: eventDetail.id,
                eventName: eventDetail.eventName,
                description: eventDetail.description,
                category: eventDetail.category,
                repetition: eventDetail.repetition
            });
        }
    }, []);

    const InputEvent = (event) => {
        const { name, value } = event.target;
        setCreateEventForm((preValue) => {
            return {
                ...preValue,
                [name]: value,
            }
        });
    }

    const onSubmit = (event) => {
        event.preventDefault();
        if (createEventForm.eventName !== "" && createEventForm.description !== "" && startDate !== "" && startTime !== "" && endDate !== "" && endTime !== "") {
            const startDateTime = startDate + ' ' + startTime;
            const endDateTime = endDate + ' ' + endTime;
            const convertStartDTToTimeStamp = Date.parse(startDateTime);
            const convertEndDTToTimeStamp = Date.parse(endDateTime);
            const body = {
                id: createEventForm.id,
                eventName: createEventForm.eventName,
                startDateTime: convertStartDTToTimeStamp,
                endDateTime: convertEndDTToTimeStamp,
                description: createEventForm.description,
                category: createEventForm.category,
                repetition: createEventForm.repetition,
                masjidName : masjidName,
                searchEventByMasjidName : searchEventByMasjidName,
                userId: g_userId,
                masjidId: masjidId
            }
            axios.post(`${BASE_API_URL}/event/create_event`, body, {withCredentials : false})
                .then(response => {
                    setResponseStatus(response.status);
                    setResponseMessage(response.data.message);
                    setOpenAlert(true);
                    setTimeout(() => {
                        setOpenAlert(false);
                        onSelect();
                    }, 2500);
                })
                .catch(err => {
                    if (err) {
                        setResponseMessage("Some Thing Went Wrong");
                        setResponseStatus(300);
                        setOpenAlert(true);
                    }
                });
        }
        else {
            setResponseMessage("Please Fill All Feilds");
            setOpenAlert(true);
            setTimeout(() => {
                setOpenAlert(false);
            }, 2500);
        }
    }

    const DissmissMobileKeyboard = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            event.target.blur();
        }
    }

    return (
        <>
            {
                (isMasjidDetailExist === true ) ?
                    <Container className="fade-in-translate" style={{ overflow: 'hidden', backgroundColor: 'white', borderRadius: "15px" }}>
                        <Row>
                            <Col lg={12} md={12} sm={12} xs={12}>
                                <h4 className={screenWidth > 576 ? "mb-4 text-shadow-grey font-size-35 font-weight-700 text-center" : "mb-4 text-shadow-grey font-size-16 font-weight-700 text-center"}>Event Details</h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={9} md={9} sm={9} xs={12} className={screenWidth > 576 ? "eventDetail-top-box vertical-center" : "eventDetail-top-box vertical-center horizontal-center"} style={{marginBottom: screenWidth > 576 ? '0%' : '-10%',}}>
                                <div className={ screenWidth > 576 ? "padding-left-10" : null}>
                                    <h6 className={ screenWidth > 576 ? "mb-0 font-size-20 font-weight-700 font-color-dark-black text-center" : "mb-0 font-size-15 font-weight-700 font-color-dark-black text-center"} >{masjidName}</h6>
                                </div>
                            </Col>
                            <Col lg={3} md={3} sm={3} xs={12} className={screenWidth < 576 ? "eventDetail-top-box vertical-end font-color-black background-color-grey  horizontal-center" : "eventDetail-top-box vertical-center font-color-white background-color-green horizontal-center"} style={{marginLeft: screenWidth > 576 ? '-3%' : '0%', paddingTop: screenWidth < 576 ? '10%' : '0%', paddingBottom: screenWidth < 576 ? '1%' : '0%',  zIndex: '-1'}}>
                                <div className="">
                                    {
                                        (g_masjidData !== null && g_masjidData !== undefined && g_masjidData !== null && g_masjidData !== undefined) ?
                                            <p className={screenWidth < 768 ? "mb-0 text-center font-size-12 padding-left-8" : "mb-0 text-center font-size-16 padding-left-8"}>Host - {g_masjidData.userName}</p> : <p>Host - You</p>
                                    }
                                </div>
                            </Col>
                        </Row>
                        <form onSubmit={onSubmit} >
                            <Row className="mt-4">
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <label>Event Name</label>
                                    <input type="text" className="eventList-input-feild mt-1" name="eventName" readOnly={isUserOrAdmin === true ? false : true}
                                     onChange={InputEvent} value={createEventForm.eventName} onKeyDown={DissmissMobileKeyboard} />
                                    <input type="text" hidden name="id" onChange={InputEvent} value={createEventForm.id} />
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <DatePicker setEndDate={setEndDate}
                                     setStartDate={setStartDate} startDate={startDate}
                                     endDate={endDate} isReadOnly={isReadOnly}
                                     setEndTime={setEndTime} endTime={endTime}
                                     setStartTime={setStartTime} startTime={startTime}  />
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <label className="mt-2">Description</label>
                                    <textarea className="eventList-input-feild mt-1" type="text" style={{ height: '110px' }} readOnly={isUserOrAdmin === true ? false : true}
                                        id="description" name="description" onKeyDown={DissmissMobileKeyboard} onChange={InputEvent} value={createEventForm.description}>
                                    </textarea>
                                    <label className="mt-2">Category</label>
                                    <select className="form-select mt-1 eventList-input-feild" id="category" name="category" disabled={isUserOrAdmin === true ? false : true}
                                        onChange={InputEvent} value={createEventForm.category}>
                                        <option className="option" value="1">Announcements</option>
                                        <option className="option" value="2">Engagement</option>
                                        <option className="option" value="3">General Event</option>
                                        <option className="option" value="4">Poll/Suggestions</option>
                                    </select>
                                    <label className="mt-2">Recurrence/Repitition</label>
                                    <select className="form-select mt-1 eventList-input-feild" id="repetition" name="repetition" disabled={isUserOrAdmin === true ? false : true}
                                        onChange={InputEvent} value={createEventForm.repetition}>
                                        <option className="option" value="1">None</option>
                                        <option className="option" value="2">Every Day</option>
                                        <option className="option" value="3">Every Week</option>
                                        <option className="option" value="4">Every Month</option>
                                        <option className="option" value="5">Every Year</option>
                                    </select>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Collapse in={openAlert}>
                                        <div id="example-collapse-text" className="mt-3">
                                            <Alert variant={responseStatus === 200 ? "success" : "danger"} onClose={() => setOpenAlert(false)} dismissible>
                                                <h6 style={{ marginBottom: '0.1rem' }}>{responseMessage}</h6>
                                            </Alert>
                                        </div>
                                    </Collapse>
                                </Col>
                            </Row>
                            <Row className="mt-3 mb-5">
                                <Col lg={2} md={3} sm={6} xs={4}>
                                    <button type="button" className="btn background-color-dark-grey font-color-white border-16" style={{ width: '100%' }} onClick={onSelect}>Back</button>
                                </Col>
                                {
                                    (isUserOrAdmin === true) ?
                                        <Col lg={4} md={4} sm={6} xs={8}>
                                            <button type="onSubmit" className="btn background-color-green font-color-white  font-weight-600 border-16" style={{ width: '100%' }} >{(createEventForm.id) ? 'Update' : 'Create'}</button>
                                        </Col> : ""
                                }
                            </Row>
                        </form>
                    </Container>
                    :
                    <Container className="fade-in-translate" style={{ overflow: 'hidden', backgroundColor: 'white', borderRadius: "15px" }}>
                        <Row>
                            <Col>
                                <div className="horizontal-center padding-top-50 padding-bottom-50">
                                    <div>
                                        <Loader type="Rings" color="#9a8356" height={80} width={80} />
                                        <p className="padding-top-30" style={{ color: "#9a8356" }}>Loading........</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
            }
        </>
    );
}
export default EventDetails;